<template>

  <!-- 로그인 화면 화면 컴포넌트 -->
  <div class='common_layout'>
    <div class="sign_in-lang-set-box" v-on:click="langSet">
      <img class="lang-set-img" alt="언어설정" src="@/assets/icon/languages_set.svg">
      <span>{{this.langText.lang_set[this.langKey]}}</span>
    </div>

    <div class="login-layout" :class="isActive ? 'common-show' : 'common-hide'">
<!--    <div class="login-layout common-show">-->

      <!--   로고   -->
      <div class="sign_in-logo"><img src="../../assets/images/sign_in_logo.png" alt="로그인 로고"></div>

      <div class="sign_in-input-section" id="sign_in-input-section">

        <!--   아이디, 비밀번호 입력 컨텐츠   -->
        <div class="sign_in-input-box">
          <div>{{this.langText.id[this.langKey]}}</div>
          <input id="id" ref="id" v-on:keydown.enter="id_keydown" type="email" v-model="CUST_ID"
                 :class="idFocus ? 'sign_in-input-box-focus' : 'sign_in-input-box-blur'"
                 class="font2-R"  v-on:focus="focusThis(1)" v-on:blur="idFocus = false" />
        </div>
        <div class="sign_in-input-box">
          <div>{{this.langText.pw[this.langKey]}}</div>
          <input id="pw" ref="pw" v-on:keydown.enter="pw_keydown" type="password" v-model="CUST_PW"
                 :class="pwFocus ? 'sign_in-input-box-focus' : 'sign_in-input-box-blur'"
                 class="font2-R" v-on:focus="focusThis(2)" v-on:blur="pwFocus = false"/>
        </div>

        <!--   자동로그인 설정, 아이디 비밀번호 찾기 링크   -->
        <div class="sign_in-input-bottom-box font1-R">
          <div class="sign_in-input-bottom-left" v-on:click="saveCheck = !saveCheck">
            <img alt="로그인 상태 유지 ON" src="../../assets/icon/check_on.svg" v-if="saveCheck" />
            <img class="sign_in-input-checkbox-off" alt="로그인 상태 유지 OFF" src="../../assets/icon/check_off.svg" v-else />
            <span>{{this.langText.login_stay[this.langKey]}}</span>
          </div>
          <div class="find-account-btn" v-on:click="findAccount">{{this.langText.id_pw_find[this.langKey]}}</div>
        </div>

        <div class="sign_in-btn font1-R" v-on:click="sign_in(1)">{{this.langText.login[this.langKey]}}</div>

        <div class="sign_in-btn-subT font1-R">{{this.langText.login_comment[this.langKey]}}</div>

      </div>

      <!--   하단 회원가입 링크   -->
      <div class="sign_in-bottom-section font1-M">

        <div class="sign_in-bottom-sub">
          <div>{{this.langText.sign_comment_0[this.langKey]}}</div>
          <div>{{this.langText.sign_comment_1[this.langKey]}}</div>
        </div>

        <div class="sign-up-btn" v-on:click="signUp">{{this.langText.sign_in[this.langKey]}}</div>

      </div>

      <!--   로그인 실패시 안내창   -->
      <div class="sign_in-modal-background" :class="modalRunning ? 'modal-open':'modal-close'">
        <div class="sign_in-modal">
          <div class="sign_in-modal-comment">
            <div>{{commentText1}}</div>
            <div>{{commentText2}}</div>
          </div>
          <div class="sign_in-modal-btn font1-M" v-on:click="modalRunning = false;">{{this.langCommonText.confirm[this.langKey]}}</div>
        </div>
      </div>

      <!-- 로그인 시도시 로딩화면 -->
      <div class="sign_in-loading-background" v-if="signInRunning">
        <img class="sign_in-loading-icon" src="@/assets/icon/loading_icon.svg" alt="로그인 로딩" />
      </div>
    </div>
    <!--  <div class='common_layout'>-->



  </div>

</template>

<script>
import languages from '../../modules/languages/sign_in'
import common from '../../modules/languages/common'

  /************************수정 시 추가할 데이터************************/
  import CryptoJS from "crypto-js";
  /***************************************************************/

  export default {
    data() {
      return {
        langText: languages,
        langCommonText: common,
        langKey: this.$store.state.lang,

        /************************수정 시 추가할 데이터************************/
        secretKey: process.env.VUE_APP_SECRET_KEY,  // AES 시크릿 키
        ivText: process.env.VUE_APP_IV,             // AES IV
        /***************************************************************/

        CUST_ID: '',                        // 입력 아이디
        CUST_PW: '',                        // 입력 비밀번호
        GSTNO: null,                        // 예약번호
        saveCheck: false,                   // 자동로그인 여부
        idFocus: false,                     // 아이디 입력창 포커스 여부
        pwFocus: false,                     // 비밀번호 입력창 포커스 여부
        modalRunning: false,                // 로그인 실패 안내창 동작 여부
        signInRunning: false,               // 로그인 시도중인지 여부
        commentText1: languages.modal_comment_1_0[this.$store.state.lang], //'아이디 혹은 비밀번호가',   // 로그인 실패 안내창 상단 텍스트
        commentText2: languages.modal_comment_2_0[this.$store.state.lang] ,//'올바르지 않습니다.',      // 로그인 실패 안내창 하단 텍스트
        isActive: false                     // 화면 정상 로드 여부
      }
    },
    created() {
      this.langSetMessage();
      this.$store.state.USER_AGENT = navigator.userAgent.toLowerCase(); // 사용자 에이전트를 전역변수에 저장

      /* 로그아웃해서 현재 페이지에 도착한거라면 화면 정상 로드 여부를 true 처리 한다. */
      if (this.$store.state.ISSIGNOUT) {
        this.$store.state.ISSIGNOUT = false;
        this.isActive = true;
      }
    },
    methods: {
      /* 언어 세팅 페이지로 이동 */
      langSet(){
        this.$router.push('/app/languages_set');
      },
      async langSetMessage() {
        try{
          await window.ReactNativeWebView.postMessage(JSON.stringify({mode: "langCheck"}));
          if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
            document.addEventListener("message", (event) => {
              const data = JSON.parse(event.data);
              if(data['mode'] === 'LANGUAGE'){
                this.$session.set('lang_set', data['result']);
                this.$store.state.lang = data['result'];
                this.langKey = this.$store.state.lang;
              }
            });
          } else {
            window.addEventListener("message", (event) => {
              const data = JSON.parse(event.data);
              if(data['mode'] === 'LANGUAGE'){
                this.$session.set('lang_set', data['result']);
                this.$store.state.lang = data['result'];
                this.langKey = this.$store.state.lang;
              }
            });
          }
          if(this.langKey === undefined){
            const tempLang = this.$session.get('lang_set');
            if(tempLang !== undefined){
              this.$store.state.lang = tempLang;
            } else {
              this.$store.state.lang = 'KO';
            }
            this.langKey = this.$store.state.lang;

          }
          // axios default 언어 세팅
          this.axios.defaults.headers.common['Accept-Language'] = this.langKey;

        }catch (e) {
          console.log(e)
          this.$store.state.lang = 'KO';
          this.langKey = this.$store.state.lang;
          this.axios.defaults.headers.common['Accept-Language'] = this.langKey;
        }

        console.log('언어 설정', this.langKey);
      },
      /* 네이티브 측으로 데이터 전달 함수 */
      sendMessage(data) {
        try{
          if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
              this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
            window.ReactNativeWebView.postMessage(data);
          }
        }catch (e) {
          console.log(e)
        }
      },

      /* 아이디, 비밀번호 찾기 클릭시 처리 함수 */
      findAccount() {

        this.sendMessage(JSON.stringify({mode: "findAccount"}));

      },

      /* 회원가입 클릭시 처리 함수 */
      signUp() {

        this.sendMessage(JSON.stringify({mode: "signUp"}))

      },


      /************************수정 시 추가할 데이터************************/
      /* AES 암호화 */
      encryptIdPw(USER_ID, USER_PWD, Authorization) {

        const cipher = CryptoJS.AES.encrypt(JSON.stringify({ USER_ID, USER_PWD, Authorization }), CryptoJS.enc.Hex.parse(this.secretKey), {
          iv: CryptoJS.enc.Hex.parse(this.ivText),
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC,
        });

        return cipher.toString();
      },

      /* AES 복호화 */
      decryptIdPw(encryptedData) {
        const parsedKey = CryptoJS.enc.Hex.parse(this.secretKey);
        const parsedIv = CryptoJS.enc.Hex.parse(this.ivText);
        const decrypted = CryptoJS.AES.decrypt(encryptedData, parsedKey, {
          iv: parsedIv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });

        return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));
      },
      /***************************************************************/





      /*
       * 로그인 함수
       * 로그인 실패시 실패 안내창으로 안내
       * 로그인 성공시 홈 화면으로 이동
       *  */
      async sign_in(n) {

        console.log('signin 시도 1');

        if (this.signInRunning) {
          this.commentText1 = this.langText.modal_comment_1_1[this.langKey]; // "잠시 후 다시 시도해주세요.";
          this.commentText2 = "";
          this.modalRunning = true;
          return;
        }

        const self = this;

        this.signInRunning = true;

        try {


          //에러코드
          let err_code_data;
          //로그인 데이터
          let login_data;

          if (this.CUST_ID === "" || this.CUST_PW === "") {


            if (n === 2) {
              this.CUST_ID = '';
              this.CUST_PW = '';

            } else {
              this.commentText1 = this.langText.modal_comment_1_2[this.langKey]; //'아이디 혹은 비밀번호를';
              this.commentText2 = this.langText.modal_comment_2_1[this.langKey]; //'입력해주세요';
            }

            this.modalRunning = true;
            this.signInRunning = false;


          } else {


            // var date = new Date();
            // var year = date.getFullYear();
            // var month = ("0" + (1 + date.getMonth())).slice(-2);
            // var day = ("0" + date.getDate()).slice(-2);
            // var hour = ("0" + date.getHours()).slice(-2);
            // var minute = ("0" + date.getMinutes()).slice(-2);
            // var NOW_TIME = year + month + day + hour + minute;


            // 현재 시간을 UTC 시간으로 가져옵니다.
            const now = new Date();
            // UTC 시간을 한국 시간으로 변환합니다. (UTC+9)
            const koreanTime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds() + 9*60*60);
            // 연도, 월, 일, 시, 분을 가져옵니다.
            const year = koreanTime.getFullYear();
            const month = String(koreanTime.getMonth() + 1).padStart(2, '0');
            const day = String(koreanTime.getDate()).padStart(2, '0');
            const hour = String(koreanTime.getHours()).padStart(2, '0');
            const minute = String(koreanTime.getMinutes()).padStart(2, '0');
            var NOW_TIME = year + month + day + hour + minute;


            /************************삭제할 코드************************/

            // //외부서버 로그인 검증
            // const login_data_params = new URLSearchParams();
            //
            // login_data_params.append('USER_ID', btoa(this.CUST_ID));
            // login_data_params.append('USER_PWD', btoa(this.CUST_PW));
            // login_data_params.append('Authorization', `PARADISE T19805CT0884Z00${NOW_TIME}`);
            //
            //
            // const res = await this.axios({
            //   method: "POST",
            //   url: 'https://www.busanparadisehotel.co.kr/front/app/loginProc',
            //   headers: {
            //     "Content-Type" : "application/x-www-form-urlencoded",
            //   },
            //   data: login_data_params
            // });
            //
            //
            // const data = await res.data;
            /***************************************************************/


            /************************수정 시 추가할 데이터************************/

            //AES 암호화
            const AES_Encrypt = this.encryptIdPw(this.CUST_ID, this.CUST_PW.replace(/&/g, '&amp;'), `PARADISE T19805CT0884Z00${NOW_TIME}`);
            console.log('비밀번호');
            console.log(this.CUST_PW.replace(/&/g, '&amp;'));
            //const AES_Decrypt = this.decryptIdPw(AES_Encrypt)


            const res = await this.axios({
              method: "POST",
              url: 'https://www.busanparadisehotel.co.kr/front/app/loginProc',
              // url: 'https://devparadisehotel.co.kr/front/app/loginProc',
              headers: {
                'Content-Type': 'text/plain' // 데이터의 형식 지정
              },
              data: AES_Encrypt
            });

            const data = await res.data;
            /***************************************************************/


            //에러코드
            err_code_data = data['data'][0].ERR_CODE;
            //에러코드가 정상일 경우 로그인 데이터 삽입
            if (err_code_data === '0000') {
              login_data = data['data'][0]['data'][0];
            }


            if (err_code_data === '0000') {

              //console.log('로그인 데이터 : '+ JSON.stringify(login_data));


              this.$store.state.CUST_NO = login_data['CUST_NO'];
              this.$store.state.CUST_NM = login_data['NAME'];
              this.$store.state.CUST_PHONE = login_data['MOBILE'];
              this.$store.state.CUST_EMAIL = login_data['EMAIL'];
              this.$store.state.CUST_ID = login_data['ID'];


              /*** 멤버쉽 데이터 ***/

              if (this.saveCheck) {
                this.sendMessage(JSON.stringify({mode: "signIn", CUST_ID: this.CUST_ID, CUST_PW: this.CUST_PW}))
              }

              const res2 = await this.axios({
                method: "GET",
                url: `${process.env.VUE_APP_API_SERVER}/api/app/sign_in`,
                params: {
                  "CUST_ID": this.CUST_ID
                }
              });

              const data2 = await res2.data;

              if (data2.status === "SUCCESS") {
                if (this.saveCheck) {
                  this.sendMessage(JSON.stringify({mode: "signIn", CUST_ID: this.CUST_ID, CUST_PW: this.CUST_PW}))
                }

                this.$store.state.CUST_TOKEN = data2['result']['CUST_TOKEN'];
                this.$store.state.GSTNO = this.GSTNO;

                /*** 멤버쉽 데이터 ***/
                const memberShipRes = await this.axios({
                  method: "GET",
                  url: `${process.env.VUE_APP_API_SERVER}/api/app/membership`,
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
                    // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDVVNUX0lEIjoibXJkZXZlbGxvIiwiaWF0IjoxNzE1MzM3MTY4LCJleHAiOjE3MTc5MjkxNjgsImlzcyI6ImZ1bmlkZWEifQ.yjBg_LC5TivrGZqP3m5fYf2czA5u7Pk2tkVYrCJxG8s`
                  },
                  params: {
                    CUST_NO: this.$store.state.CUST_NO,
                    // CUST_NO: '0000855197',
                  }
                });

                console.log('CUST_NO: ', this.$store.state.CUST_NO);
                console.log('Bearer: ', this.$store.state.CUST_TOKEN);

                const memberShipdata = await memberShipRes.data.result;


                console.log(memberShipdata.length);
                if (memberShipdata.length > 0){
                  console.log(memberShipdata[0]);
                  let tempList = [];
                  if(memberShipdata[0].MILEAGE_DESC != null){
                    tempList.push(memberShipdata[0].MILEAGE_DESC);
                  }
                  if(memberShipdata[0].PLATINUM_DESC != null){
                    tempList.push(memberShipdata[0].PLATINUM_DESC);
                  }
                  if(memberShipdata[0].LEISURE_DESC != null){
                    tempList.push(memberShipdata[0].LEISURE_DESC);
                  }
                  this.$store.state.MEMBERSHIP_DATA = [...tempList];
                  // this.memberShipList = this.$store.state.MEMBERSHIP_DATA;
                } else {
                  this.$store.state.MEMBERSHIP_DATA = [];
                }

                this.sendMessage(JSON.stringify({mode: "pageReady"}));

                setTimeout(async () => {
                  this.signInRunning = false;

                  this.$store.state.STAY_HISTORY = null;
                  this.$store.state.ROOM_SERVICE_DATA = null;
                  this.$store.state.MILEAGE_DATA = null;

                  await self.$router.replace('/app/home');
                }, 300);

              } else {
                this.commentText1 = this.langText.modal_comment_1_1[this.langKey]; // "잠시 후 다시 시도해주세요";
                this.commentText2 = "";

                this.modalRunning = true;
                this.signInRunning = false;
              }

            } else if (err_code_data === '0004') {


              if (n === 2) {
                this.CUST_ID = '';
                this.CUST_PW = '';

              } else {
                this.commentText1 = this.langText.modal_comment_1_0[this.langKey]; //'아이디 혹은 비밀번호가';
                this.commentText2 = this.langText.modal_comment_2_0[this.langKey]; //'올바르지 않습니다';
              }

              this.modalRunning = true;
              this.signInRunning = false;


            } else if (err_code_data === '0005') {

              this.commentText1 = this.langText.modal_comment_1_3[this.langKey]; //'고객님의 계정은';
              this.commentText2 = this.langText.modal_comment_2_2[this.langKey]; //'휴면 계정입니다';

              this.modalRunning = true;
              this.signInRunning = false;

            } else if (err_code_data === '0006' || err_code_data === '0007') {

              this.commentText1 = this.langText.modal_comment_1_4[this.langKey]; //'홈페이지에서 정보 갱신 후';
              this.commentText2 = this.langText.modal_comment_2_3[this.langKey]; //'다시 시도해주세요';

              this.modalRunning = true;
              this.signInRunning = false;

            } else {

              this.commentText1 =  this.langText.modal_comment_1_1[this.langKey]; //"잠시 후 다시 시도해주세요";
              this.commentText2 = "";
              this.modalRunning = true;
              this.signInRunning = false;

            }


          }





        } catch(e) {
          // console.log(e);
          this.commentText1 = this.langText.modal_comment_1_1[this.langKey] // "잠시 후 다시 시도해주세요";
          this.commentText2 = "";
          this.modalRunning = true;
          this.signInRunning = false;
        }

      },

      /*
       * 네이티브 측에서 전달받은 자동로그인 데이터 처리
       * - 전달받은 데이터가 있으면 로그인 함수 호출
       * - 전달받은 데이터가 없다면 화면을 로드
       * */
      receiveMessage(event) {

        try {
          const data = JSON.parse(event.data);

          if (data['mode'] === 'account') {

            if (data['CUST_ID'] === null || data['CUST_PW'] === null || data['CUST_ID'] === undefined || data['CUST_PW'] === undefined) {

              this.sendMessage(JSON.stringify({mode: "pageReady"}));

              return;
            }

            if (data['GSTNO'] !== null && data['GSTNO'] !== undefined) {
              this.GSTNO = data['GSTNO'];
            }

            this.CUST_ID = data['CUST_ID'];
            this.CUST_PW = data['CUST_PW'];
            this.sign_in(2);

          } else if (data['mode'] === 'pageStart') {
            const self = this;
            setTimeout(() => {
              self.isActive = true;
            }, 100);
          }
        } catch(e) {

          // console.log(e);
        }

      },

      /* 아이디 입력창 터치 함수 */
      id_keydown() {
        this.$refs.pw.focus();
      },

      /* 비밀번호 입력창 터치 함수 */
      pw_keydown() {
        this.$refs.pw.blur();
      },

      /* 아이디, 비밀번호 입력창 포커스 함수 */
      focusThis(n) {
        if (n === 1) {
          this.idFocus = true;
        } else {
          this.pwFocus = true;
        }

        setTimeout(() => {
          document.getElementById('sign_in-input-section').scrollIntoView({
            behavior: "smooth"
          });
        }, 300)

      }
    },
    mounted() {

      /* 네이티브 측과 연결 */
      if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
        document.addEventListener("message", this.receiveMessage);
      } else if (this.$store.state.USER_AGENT.indexOf("iphone") > -1||this.$store.state.USER_AGENT.indexOf("ipad") > -1||this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
        window.addEventListener("message", this.receiveMessage);
      } else {
        this.isActive = true;
      }

    },
    unmounted() {

      /* 네이티브 측과 연결 해제 */
      if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
        document.removeEventListener("message", this.receiveMessage);
      } else if (this.$store.state.USER_AGENT.indexOf("iphone") > -1||this.$store.state.USER_AGENT.indexOf("ipad") > -1||this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
        window.removeEventListener("message", this.receiveMessage);
      }

    }
  }
</script>

<style scoped>
  .sign_in-logo{ display:flex; justify-content: center; align-items: center; margin-top:16%; }
  .sign_in-logo img{ width: 37%; }

  .sign_in-input-section{ width:100%; display:flex; justify-content: center; flex-direction: column; align-items: center; margin-top:10%; margin-bottom:100px; }
  .sign_in-input-box{ width:calc(100% - 40px); margin-top:6%; }
  .sign_in-input-box div{ font-size:12px; color:#808080; }
  .sign_in-input-box input{ width:100%; outline: none; margin:4px 0 0; padding:0; height:32px; font-size:15px; color:#222222; caret-color: #9C836A; }
  .sign_in-input-box-focus{ border:0; border-bottom:1px #9C836A solid; }
  .sign_in-input-box-blur{ border:0; border-bottom:1px #EDEDED solid; }

  .sign_in-input-bottom-box{ width:calc(100% - 40px); margin:5% 0 4% 0; height:40px; display:flex; justify-content: space-between; }
  .sign_in-input-bottom-left{ display:flex; align-items: center; height:100%; }
  .sign_in-input-bottom-left img{ width:20px; height:20px; }
  .sign_in-input-checkbox-off{ border:1px #D8D8D8 solid; border-radius: 4px; }
  .sign_in-input-bottom-left span{ font-size:12px; color:#666666; margin-left:5px; }
  .find-account-btn{ height:100%; display:flex; align-items: center; text-underline-position: under; font-size:12px; color:#9C836A;
    text-decoration: underline; }

  .sign_in-btn{ width:calc(100% - 40px); height:52px; background: #9C836A; display:flex; align-items: center; justify-content: center; font-size:14px; color:#FFF;
    box-shadow: 5px 5px 12px rgba(156, 131, 106, 0.5); border-radius: 6px; }

  .sign_in-btn-subT{ font-size:12px; color:#808080; width:100%; margin-top:22px; text-align: center; }

  .sign_in-bottom-section{ position:fixed; display: flex; justify-content: space-between; align-items: center; width:100%; max-width: 1920px; height:60px; background-color:#E6E6E6; bottom:0; padding:0 20px; }
  .sign_in-bottom-sub{ min-height:36px; font-size:12px; display:flex; justify-items: center; align-items: center; flex-wrap: wrap; width:calc(100% - 70px); }
  .sign_in-bottom-sub div{ font-size:12px; width:100%; min-height:15px; color:#808080; }
  .sign-up-btn{ display:flex; font-size:12px; align-items: center; height:50px; text-decoration: underline; text-underline-position: under; color:#9C836A; width:65px; justify-content:flex-end; }

  .sign_in-modal-background{ top:0; width:100%; height:100vh; height: -webkit-fill-available; position:fixed; padding: 0 35px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.6); z-index: 10; }
  .modal-open{ display:flex; }
  .modal-close{ display:none; }
  .sign_in-modal{ width:100%; background-color: #FFF; border-radius: 6px; overflow: hidden; margin-bottom:30px; }
  .sign_in-modal-comment{ width:100%; margin:35px 0 30px 0; }
  .sign_in-modal-comment div{ width:100%; text-align: center; font-size:14px; color:#333333; }
  .sign_in-modal-btn{ width:100%; height:50px; background-color: #9C836A; display:flex; justify-content: center; align-items: center; color:#FFF; }

  .sign_in-loading-background{ background: rgba(0, 0, 0, 0.8); position:fixed; top:0; left:0; right:0; bottom:0; display:flex; justify-content: center; align-items: center; z-index:10; }
  .sign_in-loading-icon{ width:45px; }
  .sign_in-lang-set-box{ padding: 6px 8px; position: absolute; right: 25px; top: 25px; display: flex; align-items: center; gap: 6px; border-radius: 72px; border: 0.5px solid #C6C6C6;}
  .sign_in-lang-set-box > span{ font-size: 13px; margin-bottom: 2px }
  .login-layout{ width:100%;  }
  .common-hide{ opacity: 0; }
  .common-show{ transition: opacity .7s; opacity: 1; }
</style>
