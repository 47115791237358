<template>
    <div class="admin_layout">

        <!--좌측 탭 메뉴-->
        <div class="admin_layout_menu">
            <div class="admin_layout_menu_img_box">
                <img class="admin_layout_menu_img" src="@/assets/images/admin_logo_white.png">
            </div>

            <div @click="doorlock_list" class="admin_layout_menu_item_box" style="cursor: pointer" >
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/doorlock_list_icon.svg">
                    <span class="admin_layout_menu_item_span" > 도어락 리스트</span>
                </div>
            </div>

            <div @click="register" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/register_icon.svg">
                    <span class="admin_layout_menu_item_span" > 도어락 등록</span>
                </div>
            </div>

<!--            <div @click="key_issue_list" class="admin_layout_menu_item_box" style="cursor: pointer" >-->
<!--                <div class="admin_layout_menu_item">-->
<!--                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_issue_icon.svg">-->
<!--                    <span class="admin_layout_menu_item_span" > 모바일 키 발급이력</span>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="admin_layout_menu_item_box" style="background-color: #9C836A;">-->
<!--                <div class="admin_layout_menu_item">-->
<!--                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_usage_icon.svg">-->
<!--                    <span class="admin_layout_menu_item_span" > 이용현황 리스트</span>-->
<!--                </div>-->
<!--            </div>-->
        </div>



        <!--컨텐츠 박스-->
        <div class="admin_layout_content_box">
            <div class="admin_layout_content">

                <div class="admin_layout_logout_box">
                    <div @click="logout" class="admin_layout_logout_button">로그아웃</div>
                </div>

                <div class="admin_layout_title_box">
                    이용현황 리스트
                </div>

                <div class="admin_layout_key_usage_filter_box">
                    <div class="admin_layout_key_usage_info_title">
                        객실 번호
                    </div>
                    <div class="admin_layout_key_usage_info_input">
                        <input class="admin_layout_key_usage_input_number" v-model="rmno" placeholder="ex. 501">
                    </div>

                    <div class="admin_layout_key_usage_info_title" style="margin-left: 20px;">
                        예약 번호
                    </div>
                    <div class="admin_layout_key_usage_info_input">
                        <input class="admin_layout_key_usage_input_number" type="number" v-model="gstno" placeholder="ex. 0001234567">
                    </div>

                    <div class="admin_layout_key_usage_info_title" style="margin-left: 20px;">
                        출입 상태
                    </div>

                    <div class="admin_layout_key_usage_info_select">
                        <div class="admin-select" style="width:180px;">
                            <select id="usage_status">
                                <option value="all">전체</option>
                                <option value="success">출입 성공</option>
                                <option value="fail">출입 실패</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="admin_layout_key_usage_filter_box" style="margin-top: 10px; margin-bottom: 20px;">
                    <div class="admin_layout_key_usage_info_title">
                        이용 시간
                    </div>
                    <div class="admin_layout_key_usage_info_input" style="width: 550px;">
                        <input class="admin_layout_key_usage_input_date" type="datetime-local" v-model="start_time">
                        <span style="margin-left: 30px; margin-right: 30px; font-weight: bold"> - </span>
                        <input class="admin_layout_key_usage_input_date" type="datetime-local" v-model="end_time">

                    </div>


                    <vue-excel-xlsx  class="admin_layout_key_usage_excel_button"
                                     :data="excel_data_usage"
                                     :columns="excel_columns_usage"
                                     :file-name="excel_filename_usage"
                                     :file-type="'xlsx'"
                                     :sheet-name="excel_sheetname_usage">
                        엑셀 다운로드
                    </vue-excel-xlsx>


                    <div @click="get_usage_list(1)" class="admin_layout_key_usage_search_button" >
                        <img style="padding: 12px 10px;" src="@/assets/icon/admin_search.svg">
                    </div>
                </div>

                <table class="admin_key_usage_table">
                    <tr class="admin_key_usage_head_tr">
                        <th class="admin_key_usage_th" style="width: 10%">객실 번호</th>
                        <th class="admin_key_usage_th" style="width: 10%">예약 번호</th>
                        <th class="admin_key_usage_th" style="width: 50%">고객 이름 / ID</th>
                        <th class="admin_key_usage_th" style="width: 20%">이용 시간</th>
                        <th class="admin_key_usage_th" style="width: 10%">출입 상태</th>
                    </tr>
                    <tr class="admin_key_usage_content_tr" v-for="item in usage_list" :key="item" >
                        <td v-if="item.rmno==='P-LOUNGE'" class="admin_key_usage_td">P-LOUNGE </td>
                        <td v-else-if="item.rmno==='EXE-LOUNGE'" class="admin_key_usage_td">EXE-LOUNGE </td>
                        <td v-else class="admin_key_usage_td">{{item.rmno}}호</td>
                        <td class="admin_key_usage_td">{{item.gstno}}</td>
                        <td class="admin_key_usage_td">{{item.guest_name}} / {{item.guest_id}}</td>
                        <td class="admin_key_usage_td">{{item.usage_time}}</td>
                        <td v-if="item.usage_status==='출입 성공'" class="admin_key_usage_td" style="color: #9C836A">{{item.usage_status}}</td>
                        <td v-else class="admin_key_usage_td"  style="color: red">{{item.usage_status}}</td>
                    </tr>
                </table>


                <div v-if="this.total_page!=0" class="admin_layout_key_usage_pagination_box">
                    <div style="display:flex; height: 40px;">
                        <img @click="page_before(this.page_no-10)" class="admin_layout_key_usage_pagination_before" src="@/assets/icon/admin_before.svg">

                        <div v-for="(item,index) in pagination_list" :key="index"  class="admin_layout_key_usage_pagination_number">
                            <div v-if="item==this.page_no" style="color: #9C836A; font-family: AppleB;">{{item}}</div>
                            <div @click="page_click(item)" v-else>{{item}}</div>
                        </div>

                        <img @click="page_next(this.page_no+10)" class="admin_layout_key_usage_pagination_next" src="@/assets/icon/admin_next.svg">
                    </div>
                </div>

                <div v-else class="admin_layout_key_usage_item_none" style="display:flex; justify-content:center; width: 100%; height: 500px; line-height: 500px;font-family: AppleM; font-size: 16px; color: grey">
                    * 이용 현황이 존재하지 않습니다
                </div>

            </div>

        </div>

    </div>
</template>

<script>

    export default {
        components:{

        },
        data () {
            return {
                //모바일키 발급이력 필터
                rmno : '',
                gstno : '',
                usage_status: '',
                start_time :'',
                end_time : '',
                //모바일 키 발급 리스트
                usage_list :[],
                //페이지네이션 리스트 (ex. 12345 / 5678910 ..)
                pagination_list : [],
                pagination_first : '',
                pagination_last : '',
                //전체 페이지
                total_page :'',
                //현재 페이지
                page_no : '',
                //자동 새로고침
                auto_reload : false,
                auto_reload_func : null,
                //엑셀 데이터 셋
                excel_columns_usage : [
                    {label: "객실번호", field: "RMNO"},
                    {label: "예약번호", field: "GSTNO"},
                    {label: "고객 이름 / ID", field: "CUST_INFO"},
                    {label: "이용 시간", field: "USAGE_TIME"},
                    {label: "출입 상태", field: "STATUS"}
                ],
                excel_data_usage : [],
                excel_filename_usage : '' ,
                excel_sheetname_usage : '',
            }
        },
        async created(){

            // //로그인 세션 검증
            // if(this.$session.get('admin_session') === '' || !this.$session.get('admin_session') ){
            //     alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요');
            //     this.$router.replace('/admin/login');
            // }


          // 로그인 세션 검증
          const adminSession = sessionStorage.getItem('admin_session');
          const expirationTime = sessionStorage.getItem('expiration_time');
          const now = new Date().getTime();

          // 세션이 없거나, 만료 시간이 지난 경우
          if (!adminSession || now > expirationTime) {
            alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요.');
            sessionStorage.removeItem('admin_session'); // 세션 제거
            sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
            this.$router.replace('/admin/login'); // 로그인 페이지로 리다이렉트
          }

            //초기 모바일키 사용이력 필터 세팅
            this.usage_status='all';
            this.page_no=1;


        },
        async mounted(){

            //모바일 키 사용현황 불러오기 (자동 갱신)
            this.start_auto_reload();
            //selectjs 함수 호출
            this.select_box_js();

        },
        async unmounted(){
            //모바일 키 사용현황 불러오기 중단
            this.stop_auto_reload()
        },

        methods: {


            //모바일 키 발급 리스트 가져오기
            async get_usage_list(get_page_no) {

                var usage_status_select = document.getElementById("usage_status");

                this.page_no = get_page_no;
                this.usage_status= usage_status_select.options[document.getElementById("usage_status").selectedIndex].value;

                var send_start_time;
                var send_end_time;

                if(this.start_time !== ''){
                    send_start_time = this.start_time.replace( /-/gi, '');
                    send_start_time = send_start_time.replace( /T/gi, '');
                    send_start_time = send_start_time.replace( /:/gi, '');
                    send_start_time = send_start_time + '00';
                }else{
                    send_start_time = this.start_time;
                }

                if(this.end_time !== ''){
                    send_end_time = this.end_time.replace( /-/gi, '');
                    send_end_time = send_end_time.replace( /T/gi, '');
                    send_end_time = send_end_time.replace( /:/gi, '');
                    send_end_time = send_end_time + '00';
                }else{
                    send_end_time = this.end_time;
                }



                //console.log("검색한 객실번호 : " + this.rmno);
                //console.log("검색한 예약번호 : " + this.gstno);
                //console.log("검색한 출입상태 : " + this.usage_status);
                //console.log("검색한 날짜(시작) : " + send_start_time);
                //console.log("검색한 날짜(마지막) : " + send_end_time);

                try{

                    const res = await this.axios({
                        method: "POST",
                        url: `${process.env.VUE_APP_API_SERVER}/api/admin/usage_list`,
                        headers: {
                            "Content-Type": "application/json",
                            // 'Authorization': `Bearer ${this.$session.get('admin_session')}`
                          'Authorization': `Bearer ${sessionStorage.getItem('admin_session')}`
                        },
                        data: {
                            rmno: this.rmno,
                            gstno: this.gstno,
                            usage_status : this.usage_status,
                            start_time: send_start_time,
                            end_time: send_end_time,
                            page_no: this.page_no
                        }
                    });


                    const data = await res.data;

                    if(data.status === "SUCCESS"){


                        /**************** 시간 설정 **********/
                        var date = new Date();
                        var year = date.getFullYear();
                        var month = ("0" + (1 + date.getMonth())).slice(-2);
                        var day = ("0" + date.getDate()).slice(-2);
                        var hour = ("0" + date.getHours()).slice(-2);
                        var minute = ("0" + date.getMinutes()).slice(-2);
                        var second = ("0" + date.getSeconds()).slice(-2);
                        var NOW_TIME = year + month + day + hour + minute + second;


                        /**************** 페이징 데이터 설정 **********/

                        //전체 페이지 개수
                        this.total_page = data.result_count;
                        //console.log('전체 페이지 개수 ' + this.total_page);
                        //모바일 키 초기화
                        this.usage_list = [];
                        //페이지 네이션 초기화
                        this.pagination_list = [];
                        //페이지네이션 위치
                        var pagination_location =  parseInt( (this.page_no - 1) / 10) * 10;
                        //페이지네이션 세팅
                        if(pagination_location+10<this.total_page){
                            this.pagination_first = pagination_location + 1;
                            this.pagination_last = pagination_location + 10;
                        }else{
                            this.pagination_first = pagination_location + 1;
                            this.pagination_last = this.total_page;
                        }


                        //모바일키 발급이력 추가
                        for (var i = 0; i < data.result.length; i++) {


                            var get_guest_name;
                            var get_guest_name_count = data.result[i].GUEST_NAME.length;

                            if(get_guest_name_count<=4){
                                get_guest_name = data.result[i].GUEST_NAME.substr(0, 1) + '*' + data.result[i].GUEST_NAME.substr(2, get_guest_name_count-2);
                            }else if(get_guest_name_count <= 8){
                                get_guest_name = data.result[i].GUEST_NAME.substr(0, 2) + '**' + data.result[i].GUEST_NAME.substr(4, get_guest_name_count-4);
                            }else if(8 < get_guest_name_count){
                                get_guest_name = data.result[i].GUEST_NAME.substr(0, 3) + '***' + data.result[i].GUEST_NAME.substr(6, get_guest_name_count-6);
                            }

                            var get_guest_id;
                            var get_guest_id_count = data.result[i].GUEST_ID.length;

                            if(get_guest_id_count <= 4){
                                get_guest_id = data.result[i].GUEST_ID.substr(0, 1) + '*' + data.result[i].GUEST_ID.substr(2, get_guest_id_count-2);
                            }else if(get_guest_id_count <= 8){
                                get_guest_id = data.result[i].GUEST_ID.substr(0, 2) + '**' + data.result[i].GUEST_ID.substr(4, get_guest_id_count-4);
                            }else if(8 < get_guest_id_count){
                                get_guest_id = data.result[i].GUEST_ID.substr(0, 3) + '***' + data.result[i].GUEST_ID.substr(6, get_guest_id_count-6);
                            }

                            var get_usage_time = data.result[i].USAGE_TIME.substr(0, 4) + '-' + data.result[i].USAGE_TIME.substr(4, 2) + '-' + data.result[i].USAGE_TIME.substr(6, 2)+ ' ' + data.result[i].USAGE_TIME.substr(8, 2)+ ':' + data.result[i].USAGE_TIME.substr(10, 2)+ ':' + data.result[i].USAGE_TIME.substr(12, 2);

                            var get_usage_status;
                            if(data.result[i].USAGE_STATUS === '1'){
                                get_usage_status = '출입 성공'
                            }else{
                                get_usage_status = '출입 실패'
                            }


                            this.usage_list.push(
                                {
                                    gstno: data.result[i].BM_GSTNO,
                                    rmno : data.result[i].BM_RMNO,
                                    guest_name: get_guest_name,
                                    guest_id: get_guest_id,
                                    usage_time: get_usage_time,
                                    usage_status: get_usage_status,
                                }
                            );
                            //console.log(data.result[i])
                        }
                        //페이지네이션 추가
                        for (var j = this.pagination_first; j <= this.pagination_last; j++) {
                            this.pagination_list.push(j);
                        }


                        /**************** EXCEL 데이터 설정 **********/

                        this.excel_data_usage = [];
                        this.excel_filename_usage = '모바일 키 출입 이력 '+ NOW_TIME;
                        this.excel_sheetname_usage = '모바일 키 출입 이력 '+ NOW_TIME;

                        //모바일키 발급이력 추가
                        for (var k = 0; k < data.result_excel.length; k++) {


                            var excel_guest_name;
                            var excel_guest_name_count = data.result_excel[k].GUEST_NAME.length;

                            if(excel_guest_name_count<=4){
                                excel_guest_name = data.result_excel[k].GUEST_NAME.substr(0, 1) + '*' + data.result_excel[k].GUEST_NAME.substr(2, excel_guest_name_count-2);
                            }else if(excel_guest_name_count <= 8){
                                excel_guest_name = data.result_excel[k].GUEST_NAME.substr(0, 2) + '**' + data.result_excel[k].GUEST_NAME.substr(4, excel_guest_name_count-4);
                            }else if(8 < excel_guest_name_count){
                                excel_guest_name = data.result_excel[k].GUEST_NAME.substr(0, 3) + '***' + data.result_excel[k].GUEST_NAME.substr(6, excel_guest_name_count-6);
                            }

                            var excel_guest_id;
                            var excel_guest_id_count = data.result_excel[k].GUEST_ID.length;

                            if(excel_guest_id_count <= 4){
                                excel_guest_id = data.result_excel[k].GUEST_ID.substr(0, 1) + '*' + data.result_excel[k].GUEST_ID.substr(2, excel_guest_id_count-2);
                            }else if(excel_guest_id_count <= 8){
                                excel_guest_id = data.result_excel[k].GUEST_ID.substr(0, 2) + '**' + data.result_excel[k].GUEST_ID.substr(4, excel_guest_id_count-4);
                            }else if(8 < excel_guest_id_count){
                                excel_guest_id = data.result_excel[k].GUEST_ID.substr(0, 3) + '***' + data.result_excel[k].GUEST_ID.substr(6, excel_guest_id_count-6);
                            }

                            var excel_usage_time = data.result_excel[k].USAGE_TIME.substr(0, 4) + '-' + data.result_excel[k].USAGE_TIME.substr(4, 2) + '-' + data.result_excel[k].USAGE_TIME.substr(6, 2)+ ' ' + data.result_excel[k].USAGE_TIME.substr(8, 2)+ ':' + data.result_excel[k].USAGE_TIME.substr(10, 2)+ ':' + data.result_excel[k].USAGE_TIME.substr(12, 2);

                            var excel_usage_status;
                            if(data.result_excel[k].USAGE_STATUS === '1'){
                                excel_usage_status = '출입 성공'
                            }else{
                                excel_usage_status = '출입 실패'
                            }


                            this.excel_data_usage.push(
                                {
                                    RMNO : data.result_excel[k].BM_RMNO,
                                    GSTNO : data.result_excel[k].BM_GSTNO,
                                    CUST_INFO : excel_guest_name + "/" + excel_guest_id,
                                    USAGE_TIME : excel_usage_time,
                                    STATUS : excel_usage_status,
                                }
                            );
                        }


                    }else{
                        alert('모바일키 이용현황 조회에 실패했습니다.\n잠시 후에 다시 시도해주세요');


                    }


                }catch (e) {
                    console.log(e);

                }

            },

            //페이징 번호 클릭
            async page_click(get_page_no) {
                this.get_usage_list(get_page_no);
            },
            //페이징 이전 버튼 클릭
            async page_before(get_page_no) {
                var page_before = parseInt( (get_page_no - 1) / 10) * 10 + 1;
                if(page_before<1){
                    console.log('이전 페이지 없음')
                }else{
                    this.get_usage_list(page_before);
                }
            },
            //페이징 다음 버튼 클릭
            async page_next(get_page_no) {
                var page_next = parseInt( (get_page_no - 1) / 10) * 10 + 1;
                if(page_next>this.total_page){
                    console.log('다음 페이지 없음')
                }else{
                    this.get_usage_list(page_next);
                }
            },

            //모바일 키 사용이력 호출하기 (자동 갱신)
            async start_auto_reload() {
                this.get_usage_list(this.page_no);
                this.auto_reload = true;
                this.auto_reload_func = setInterval(() => {
                    this.get_usage_list(this.page_no);
                }, 5000)
            },
            //모바일 키 사용이력 호출 중단
            stop_auto_reload() {
                this.auto_reload = false;
                clearInterval(this.auto_reload_func);
            },

            //로그아웃하기
            logout() {
                var logout_confirm = confirm("정말로 로그아웃 하시겠습니까?");
                if(logout_confirm == true){
                    //토큰, 세션 삭제 후 로그인 페이지로 이동
                    // this.$session.set('admin_session', '');
                    sessionStorage.removeItem('admin_session'); // 세션 제거
                    sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
                    this.$router.replace('/admin/login');
                }
            },

            //페이지 이동함수
            doorlock_list(){
                this.$router.push('/admin/doorlock_list');
            },
            register(){
                this.$router.push('/admin/register');
            },
            key_issue_list(){
                this.$router.push('/admin/key_issue_list');
            },

            //selectjs 함수
            select_box_js() {
                var x, i, j, l, ll, selElmnt, a, b, c;
                /* Look for any elements with the class "admin-select": */
                x = document.getElementsByClassName("admin-select");
                l = x.length;
                for (i = 0; i < l; i++) {
                    selElmnt = x[i].getElementsByTagName("select")[0];
                    ll = selElmnt.length;
                    /* For each element, create a new DIV that will act as the selected item: */
                    if(document.getElementsByClassName("admin-select-selected")[i]){
                        document.getElementsByClassName("admin-select-selected")[i].remove();
                    }
                    if(document.getElementsByClassName("admin-select-items admin-select-hide")[i]){
                        document.getElementsByClassName("admin-select-items admin-select-hide")[i].remove();
                    }
                    a = document.createElement("DIV");
                    a.setAttribute("class", "admin-select-selected");
                    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                    x[i].appendChild(a);
                    /* For each element, create a new DIV that will contain the option list: */
                    b = document.createElement("DIV");
                    b.setAttribute("class", "admin-select-items admin-select-hide");

                    for (j = 0; j < ll; j++) {
                        /* For each option in the original select element,
                        create a new DIV that will act as an option item: */
                        c = document.createElement("DIV");
                        c.innerHTML = selElmnt.options[j].innerHTML;
                        c.addEventListener("click", function (e) {
                            /* When an item is clicked, update the original select box,
                            and the selected item: */
                            e.stopPropagation();
                            var y, i, k, s, h, sl, yl;
                            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                            sl = s.length;
                            h = this.parentNode.previousSibling;
                            for (i = 0; i < sl; i++) {
                                if (s.options[i].innerHTML == this.innerHTML) {
                                    s.selectedIndex = i;
                                    h.innerHTML = this.innerHTML;
                                    y = this.parentNode.getElementsByClassName("same-as-selected");
                                    yl = y.length;
                                    for (k = 0; k < yl; k++) {
                                        y[k].removeAttribute("class");
                                    }
                                    this.setAttribute("class", "same-as-selected");
                                    break;
                                }
                            }
                            h.click();
                        });
                        b.appendChild(c);
                    }

                    x[i].appendChild(b);

                    a.addEventListener("click", function (e) {
                        /* When the select box is clicked, close any other select boxes,
                        and open/close the current select box: */
                        e.stopPropagation();
                        closeAllSelect(this);
                        this.nextSibling.classList.toggle("admin-select-hide");
                        this.classList.toggle("select-arrow-active");
                    });
                }

                function closeAllSelect(elmnt) {
                    /* A function that will close all select boxes in the document,
                    except the current select box: */
                    var x, y, i, xl, yl, arrNo = [];
                    x = document.getElementsByClassName("admin-select-items");
                    y = document.getElementsByClassName("admin-select-selected");
                    xl = x.length;
                    yl = y.length;
                    for (i = 0; i < yl; i++) {
                        if (elmnt == y[i]) {
                            arrNo.push(i)
                        } else {
                            y[i].classList.remove("select-arrow-active");
                        }
                    }
                    for (i = 0; i < xl; i++) {
                        if (arrNo.indexOf(i)) {
                            x[i].classList.add("admin-select-hide");
                        }
                    }
                }
                /* If the user clicks anywhere outside the select box,
                then close all select boxes: */
                document.addEventListener("click", closeAllSelect);
            }
        }

    }
</script>


<style>
    .admin_key_usage_table { width: 100%;border-collapse:collapse; font-family: AppleR; border: 1px solid #f2f2f2}
    .admin_key_usage_head_tr{background-color: #E3DAD1; height: 70px}
    .admin_key_usage_content_tr{background-color: white; height: 70px;border-bottom: 1px solid #f2f2f2}
    .admin_key_usage_th { text-align: center;border: none;font-family: AppleR;font-size: 16px;}
    .admin_key_usage_td { text-align: center;border: none; font-family: AppleR; font-size: 16px; }
    .admin_key_usage_content_tr:hover{ background-color: #F9F9F9; }
    .admin_layout_key_usage_filter_box {display:flex;margin-top:40px; width: 100%; height: 60px;}
    .admin-select-selected {background-color: white;border: none;}
    .admin_layout_key_usage_input_number{width: 100%; height: 50px; line-height:54px; padding: 5px 15px; font-size: 16px; font-family: AppleM; border: none;outline:none;border-radius: 0px 6px 6px 0px;}
    .admin_layout_key_usage_input_date{width: 100%; height: 50px; line-height:54px; padding: 5px 15px; font-size: 16px; font-family: AppleM; border: none;outline:none;border-radius: 0px 6px 6px 0px;}
    .admin_layout_key_usage_info_title{display:flex; justify-content:center;width: 130px; height: 52px; line-height: 52px;background-color: #EDEDED; border: 1px solid #D8D8D8;  color: #9C836A; font-family: AppleM; font-size: 16px; border-radius: 6px 0 0 6px;}
    .admin_layout_key_usage_info_input{display:flex; justify-content:center;width: 180px; height: 52px; line-height: 52px;background-color: white; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8;  font-family: AppleM; font-size: 16px;border-radius: 0 6px 6px 0;}
    .admin_layout_key_usage_info_select{display:flex; justify-content:center;width: 180px; height: 52px; background-color: white; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8;  font-family: AppleM; font-size: 16px;}
    .admin_layout_key_usage_excel_button{display:flex; justify-content:center; margin-left:calc(100% - 930px); width:140px; height:52px; line-height:52px; background-color: #E3DAD1;border: none; border-radius: 6px; font-family: AppleM; font-size: 16px;cursor: pointer}
    .admin_layout_key_usage_search_button{display:flex; justify-content:center; margin-left:20px; width:90px; height:52px; line-height:52px; background-color: #9C836A; border-radius: 6px; font-family: AppleM; font-size: 16px;cursor: pointer}
    .admin_layout_key_usage_pagination_box{display:flex; flex-direction:row-reverse;margin-top:20px; width: 100%; height: 40px; }
    .admin_layout_key_usage_pagination_before{margin-right:5px;padding: 4px; cursor: pointer;}
    .admin_layout_key_usage_pagination_number{margin-left:10px;margin-right:10px; height: 40px; line-height:42px; font-size: 20px; font-family: AppleM; text-align: center;cursor: pointer;}
    .admin_layout_key_usage_pagination_next{margin-left:5px;padding: 4px; cursor: pointer;}
</style>
