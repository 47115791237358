<template>

  <!-- 마일리지 적립내역 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="../../assets/icon/back_btn_white.svg" v-on:click="$router.go(-1);">
      <div class="header-f-white font1-B">{{this.langText.title[this.langKey]}}</div>
    </div>

    <!--   마일리지 적립내역 화면 상단 현재 적립 포인트   -->
    <div class="point-history-head-box">
      <div class="head-point-title">
        <div class="head-df-txt font1-R">{{this.langCommonText.paradian_point[this.langKey]}}</div>
      </div>
    <div class="head-point-txt font2-B">{{String(AVAIL_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}<span class="font2-R">p</span></div>
    <div class="head-all-point-box">
      <div class="head-df-txt font1-R">{{this.langCommonText.total_collect_point[this.langKey]}}</div>
      <div class="head-all-point-txt font2-R">{{String(TOT_SV_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
     </div>
    </div>

    <!--  마일리지 적립내역 화면 적립내역 목록 상단 소멸 예정 포인트, 사용 포인트  -->
    <div class="point-history-list-box" @scroll="infiniteScroll">
      <div class="point-history-info-box">
        <div class="point-history-s-box point-history-info-line">
          <div class="point-l-lightGrey font1-L point-history-info-topL">{{this.langCommonText.will_disappear_point[this.langKey]}}</div>
          <div class="point-history-info-bottomR-O font2-M">{{String(LAPSE_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}<span class="font2-R">p</span></div>
        </div>
        <div class="point-history-s-box">
          <div class="point-l-lightGrey font1-L point-history-info-topL">{{this.langCommonText.can_use_point[this.langKey]}}</div>
          <div class="point-history-info-bottomR-G font2-M">{{String(TOT_USE_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}<span class="font2-R">p</span></div>
        </div>
      </div>

      <!--   마일리지 적립내역 화면 적립 내역, 사용 내역 목록   -->
      <div class="point-history-list" v-for="(item, index) in History" :key="index">
        <div class="point-history-l-box">
          <div class="point-l-lightGrey font1-L">{{dateFilter(item.OPT_DATE)}}</div>
        </div>
        <div class="point-history-l-box">
          <div class="point-h-default font1-R">{{item.DEPT_DESC}}</div>
          <div class="point-h-boldOrange font2-M" v-if="item.SV_AMT*1 === 0">-{{String(item.USE_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
          <div class="point-h-boldGrey font2-M" v-else>+{{String(item.SV_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import languages from '../../modules/languages/point_history'
import common from '../../modules/languages/common'

export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      History: [],                                            // 마일리지 적립, 사용 내역 목록 데이터
      TOT_SV_ML: this.$store.state.MILEAGE_DATA.TOT_SV_ML,    // 총 적립 포인트
      // TOT_SV_ML: 10000,    // 총 적립 포인트
      TOT_USE_ML: this.$store.state.MILEAGE_DATA.TOT_USE_ML,  // 사용 포인트
      // TOT_USE_ML: 10000,  // 사용 포인트
      AVAIL_ML: this.$store.state.MILEAGE_DATA.AVAIL_ML,      // 내 보유 포인트(파라디안 포인트)
      // AVAIL_ML: 10000,      // 내 보유 포인트(파라디안 포인트)
      LAPSE_ML: this.$store.state.MILEAGE_DATA.LAPSE_ML,      // 소멸 예정 포인트
      // LAPSE_ML: 10000,      // 소멸 예정 포인트
      PAGE: 1,                                                // 마일리지 적립, 사용 내역 목록 데이터 페이지
      loadPointHistoryRun: false,                             // 페이지 요청중인지 여부
      LastPage: false                                         // 마지막 페이지까지 요청이 끝났는지 여부
    }
  },
  async created() {

    /* 목록 데이터 최초 페이지 요청 */
    await this.loadPointHistory();
  },
  methods: {

    /* 날짜 문자열 필터 */
    dateFilter(v) {
      return `${v.substr(0, 4)}.${v.substr(4, 2)}.${v.substr(6, 2)}`;
    },

    /* 무한스크롤로 일정부분 스크롤을 했다면 다음 페이지 요청 */
    infiniteScroll(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      // console.log.log(scrollHeight);
      // console.log.log(scrollTop);
      // console.log.log(clientHeight);
      if ((scrollHeight / 2 < scrollTop + clientHeight)) {
        this.loadPointHistory();
      }
    },

    /*
     * 특정 페이지 요청 함수
     * 1. 이미 함수가 동작중이거나 이미 마지막 페이지까지 요청이 끝났다면 종료
     * 2. 최초 페이지 요청시 로딩 화면 동작
     * 3. 페이지 요청이 끝났다면 목록에 추가하고 로딩 종료
     * */
    async loadPointHistory() {

      if(this.loadPointHistoryRun || this.LastPage) return;

      this.loadPointHistoryRun = true;

      if (this.PAGE === 1) {
        this.$emit('c-loadingStart');
      }

      try {
        const res = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_SERVER}/api/app/point_history`,
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
          },
          params: {
            CUST_ID: this.$store.state.CUST_ID,
            CUST_NO: this.$store.state.CUST_NO,
            PAGE: this.PAGE
          }
        });

        const data = await res.data;

        if (data.status === "SUCCESS") {

          if (data.result.length < 1) {
            this.LastPage = true;
          } else {
            for (let x=0 ; x<data.result.length ; x++) {
              this.History.push(data.result[x]);
            }
          }

          this.PAGE++;

        }
        //console.log(this.History);

      } catch(e) {
        // console.log.log(e);
        if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
          await this.$router.replace('/app/sign_in');
        } else if (e.response.status === 401) {
          // console.log.log("None data");
        }
      }

      setTimeout(() => {
        if (this.PAGE === 2) {
          this.$emit('c-loadingFinish');
        }
        this.loadPointHistoryRun = false;
      }, 300);
    }
  }
}
</script>

<style scoped>
.header{ background-color:#4D4843; }
.header-f-white{ color:#FFFFFF; }

.point-history-head-box{ width:100%; padding: 20px 0; height:140px; background-color:#4D4843; display:flex; align-items: center; flex-direction: column; }
.head-df-txt{ color:#FFFFFF; font-size:12px;  }
.head-point-title{ width:100%; display:flex; align-items: center; justify-content: center; }
.head-point-txt{ color:#9C836A; font-size:26px; padding:10px 0 20px 0; }
.head-all-point-box{ display:flex; justify-content: center; align-items: center; }
.head-all-point-txt{ color:#FFFFFF; font-size:12px; margin-left: 5px; }

.point-history-list-box{ width:100%; height:calc(100vh - 190px); overflow:auto; }
.point-history-info-box{ width:100%; display:flex; background-color: #F9F9F9; height:83px; border-bottom: 1px solid #EDEDED; }
.point-history-info-line{ border-right:1px solid #EDEDED; }
.point-history-list{ width:100%; display:flex; flex-direction: column; background-color: #FFFFFF; padding: 10px 16px; border-bottom: 1px solid #EDEDED; }
.point-history-s-box{ width:100%; display:flex; flex-direction: column; justify-content: space-between; align-items: center; padding: 16px; }
.point-history-l-box{ width:100%; display:flex; justify-content: space-between; align-items: center; padding: 4px 0; }
.point-history-info-topL{ width:100%; display:flex; justify-content: flex-start; }
.point-history-info-bottomR-O{ width:100%; display:flex; justify-content: flex-end; font-size:16px; color:#F16B4F; }
.point-history-info-bottomR-G{ width:100%; display:flex; justify-content: flex-end; font-size:16px; color:#262422; }
.point-l-lightGrey{ font-size:12px; color:#808080; }
.point-h-boldGrey{ font-size:14px; color:#9C836A; }
.point-h-boldOrange{ font-size:14px; color:#F16B4F; }
.point-h-default{ font-size:12px; color:#222222; }
</style>

