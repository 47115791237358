<template>

  <!-- 룸서비스 화면 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="../../assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
      <div class="font1-B">{{this.langText.title[this.langKey]}}</div>
    </div>

    <!--  화면안내 텍스트  -->
    <div class="room-service-title font1-R">{{ this.$store.state.CUST_NM}} {{this.langText.description[this.langKey] }}</div>

    <!--  제공 룸서비스 컨텐츠  -->
    <div class="room-service-content-box">
      <div class="room-service-box">

        <div class="swiper-content-box">
          <div class="swiper-content-box-padding">
            <div class="res-number-box font1-R">
              {{ this.langCommonText.reservation_number[this.langKey] }}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{GSTNO}}
            </div>

            <div class="room-service-table-head font1-R">
              <div class="t-td1">{{this.langCommonText.service_name[this.langKey]}}</div>
              <div class="t-td2">{{this.langCommonText.provide[this.langKey]}}</div>
              <div class="t-td2">{{this.langCommonText.use[this.langKey]}}</div>
              <div class="t-td2">{{this.langCommonText.not_use[this.langKey]}}</div>
            </div>
            <div class="room-service-table-body font2-M" :key="index" v-for="(item, index) in roomService">
<!--              <div class="t-td1 tb-td1">{{item.KOR_DESC}}</div>-->
              <div class="t-td1 tb-td1">{{item.DESC}}</div>
              <div class="t-td2 tb-td1">{{item.TOTAL_SVC}}</div>
              <div class="t-td2 tb-td2">{{item.USED_SVC}}</div>
              <div class="t-td2 tb-td3">{{item.TOTAL_SVC*1 - item.USED_SVC*1}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>

import languages from '../../modules/languages/room_service'
import common from '../../modules/languages/common'

  export default {
    data() {
      return {
        langText: languages,
        langCommonText: common,
        langKey: this.$store.state.lang,
        roomService: this.$store.state.ROOM_SERVICE_DATA, // 제공 룸서비스 목록 데이터
        GSTNO: this.$store.state.GSTNO                    // 예약번호
      }
    },
    created() {

      /*
      * 비동기로 현재 페이지에서 사용될 데이터를(제공 룸서비스) 받아와서 적용한다.
      * 초기값은 이미 홈에서 받아온 기초데이터가 적용해져 있다.
      * 만일 제대로 받아오지 못했다면 이전 홈화면으로 이동한다.
      *  */
      this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_SERVER}/api/app/room_service`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
        },
        params: {
          CUST_ID: this.$store.state.CUST_ID,
          GSTNO: this.$store.state.GSTNO
        }
      }).then(res => {
        const data = res.data;
        if (data.status === "SUCCESS") {

          if(data.result.length < 1){
            this.$store.state.RS_R = true;
            this.$router.go(-1);
          }
          this.roomService = data.result;
          this.$store.state.ROOM_SERVICE_DATA = data.result;
        }
      }).catch(e => {
        // console.log.log(e);
        if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
          this.$router.replace('/app/sign_in');
        } else if (e.response.status === 401) {
          // console.log.log("None data");
        }
      });
    }
  }
</script>

<style scoped>
  .common_layout{ background-color:#F9F9F9; }

  .room-service-title{ margin:6% 0; width:100%; display:flex; justify-content: center; align-items: center; height:24px; color:#9C836A; font-size:14px; }

  .room-service-content-box{ width:100%; display:flex; justify-content: center; align-items: center; margin-top:10px; margin-bottom: 55px; }
  .room-service-box{ width:100%; }
  .swiper-content-box{ width:calc(100% - 30px); background-color:#FFF; margin-left:15px; overflow:hidden; padding-bottom: 10px; }
  .swiper-content-box-padding{ height:100%; overflow-y:auto; }

  .res-number-box{ width:100%; color:#FFFFFF; display:flex; justify-content: center; align-items: center; background-color: #9C836A; font-size: 12px; height:40px; }

  .room-service-table-head{ width:100%; padding:0 10px; height:45px; border-bottom: 1px solid #EDEDED; display:flex; align-items: center; color:#808080; font-size:12px; }
  .t-td1{ width:calc(100% - 102px); text-align: left; }
  .t-td2{ width:34px; text-align: center; }
  .room-service-table-body{ width:100%; padding:8px 10px; height:auto; display:flex; align-items: center; color:#808080; font-size:14px; }
  .tb-td1{ color: #222222; }
  .tb-td2{ color: #808080; }
  .tb-td3{ color: #F16B4F; }
</style>

