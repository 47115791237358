<template>
  <!-- 앱 공통 레이아웃 -->
  <!-- 홈 새로고침 로딩화면 -->
  <div class="app-layout font1-R">
    <div class="app-loading-background" :class="fadeOutRun ? 'app-loading-show':'app-loading-hide'" v-if="!fadeOutFinish">
      <img class="app-loading-icon" src="@/assets/icon/loading_icon.svg" alt="새로고침 로딩" />
    </div>

    <!--  URL 별 호출 컴포넌트  -->
    <main>
      <router-view v-slot="{ Component }" @c-loadingFinish="loadingFinish" @c-loadingStart="loadingStart">
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
  </div>
</template>

<script>

  export default {
    name: 'm_app',
    data() {
      return {
        transitionName: "",   //화면 전환 효과
        fadeOutRun: false,    // 새로고침 로등 화면 시작 여부
        fadeOutFinish: true   // 새로고침 로딩 화면 종료 여부
      }
    },
    created() {
      // if(navigator.userAgent.indexOf('android') === -1 && navigator.userAgent.indexOf('iPhone') === -1 && navigator.userAgent.indexOf('iPad') === -1) {
      //   alert("잘못된 접근입니다.");
      //   location.href='https://busanparadisehotel.co.kr/'
      // }
    },
    watch: {
      $route(to, from) {

        /* 모든 화면 전환시 fade 효과 */
        this.transitionName = "fade";
        if(from.name === "m_sign_in" && (to.name === "m_home1" || to.name === "m_home2")){
          this.headerOn = false;
        }else if((from.name === "m_home1" || from.name === "m_home2") && to.name === "m_sign_in"){
          this.headerOn = false;
        }else if((from.name === "m_home1" || from.name === "m_home2") &&
                (to.name === "m_breakfast_service" || to.name === "m_mileage_history" || to.name === "m_room_service" ||
                        to.name === "m_stay_history" || to.name === "m_mypage" || to.name === "m_control_key")){
          this.headerOn = true;
        }else if((to.name === "m_home1" || to.name === "m_home2") &&
                (from.name === "m_breakfast_service" || from.name === "m_mileage_history" || from.name === "m_room_service" ||
                        from.name === "m_stay_history" || from.name === "m_mypage" || from.name === "m_control_key")){
          this.headerOn = false;
        }



      }
    },
    methods: {

      /* 로딩 시작 메서드 */
      loadingStart() {
        this.fadeOutFinish = false;
        this.fadeOutRun = true;
      },

      /* 로딩 종료 메서드 */
      loadingFinish() {
        this.fadeOutRun = false;
        setTimeout(() => {
          this.fadeOutFinish = true;
        },500);
      }
    }
  }
</script>

<style>

  body{ background-color: #FFF;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    user-select: none;
  }

  .header{ height:50px; display:flex; top:0; width:100%; z-index:1; align-items: center; justify-content: center; }
  .header img{ width:24px; height:24px; position:absolute; left:24px; top:13px; }
  .header div{ font-size:16px; color:#222222; }

  .app-layout{ margin:0 auto; width:1920px; }

  .common_layout{ height:100vh; position:relative; width:100%; max-width: 100vw; overflow-y:auto; background-color:#FFF; }

  .fade-enter-active, .fade-leave-active { transition: opacity .5s; }
  .fade-enter, .fade-leave-to { opacity: 0; }

  .app-loading-background{ background: rgba(0, 0, 0, 0.8); position:fixed; top:0; left:0; right:0; bottom:0; z-index:10; display:flex; justify-content: center; align-items: center; }
  .app-loading-show{ opacity: 1; }
  .app-loading-hide{ opacity: 0; transition-duration: .4s; }
  .app-loading-icon{ width:45px; }

  @media(max-width:1920px){
    .app-layout{ width:100%; }
  }

</style>

