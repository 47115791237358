<template>

  <!-- 쿠폰 내역 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="@/assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
      <div class="font1-B">{{ this.langText.check_out_title[this.langKey] }}</div>
    </div>

    <div class="step-contents">
      <div class="top-process-bar-box">
        <div class="top-process-circle-big">
          <div class="top-process-circle-small">
            <span class="top-process-text-special">{{ this.langText.minibar[this.langKey] }}</span>
          </div>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text">{{ this.langCommonText.account_check[this.langKey] }}</span>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text">{{ this.langCommonText.payment[this.langKey]  }}</span>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text-r">{{ this.langCommonText.complete[this.langKey] }}</span>
        </div>
      </div>

      <div class="step-summary">{{ this.langText.minibar_comment[this.langKey] }}</div>

      <div class="item-box" v-for="(item, index) in list" :key="index">
        <div class="item-info">
          <div class="item-image">
            <img :src="'data:image/jpeg;base64, '+item.IMAGE" style="height: 66px; box-sizing: border-box; padding: 10px 0;" />
          </div>
          <div class="item-info-detail">
            <div class="item-info-name">{{item.PLUKOR}}</div>
            <!--  한국은 금액 뒤에 원, 나머지는 앞에           -->
            <div class="item-info-price" v-if="this.langKey === 'KO'">{{String(item.SELPRICE).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.langCommonText.currency_unit[this.langKey]}}</div>
            <div class="item-info-price" v-else>{{this.langCommonText.currency_unit[this.langKey] + String(item.SELPRICE).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>

          </div>
        </div>

        <div class="item-count-box">
          <div class="item-count-btn" v-on:click="valueChange(item, -1)">-</div>
          <div class="item-count-value">{{item.COUNT}}</div>
          <div class="item-count-btn" v-on:click="valueChange(item, 1)">+</div>
        </div>

      </div>



    </div>

    <div class="footer-box">
      <div class="total-price">
        <div class="total-price-text">{{ this.langText.total_price_text[this.langKey] }}</div>
        <div class="total-price-value" v-if="this.langKey === 'KO'">{{String(allAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.langCommonText.currency_unit[this.langKey] }}</div>
        <div class="total-price-value" v-else>{{this.langCommonText.currency_unit[this.langKey] + String(allAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
      </div>
      <div class="btn-box">
        <div class="none-use-btn" v-on:click="nextPage(0)">{{ this.langCommonText.not_use[this.langKey] }}</div>
        <div class="use-btn" v-on:click="nextPage(1)">{{ this.langCommonText.select_complete[this.langKey] }}</div>
      </div>
    </div>




    <div class="co-step1-modal-background" :class="modalRunning ? 'modal-open':'modal-close'">
      <div class="co-step1-modal">
        <div class="co-step1-modal-comment">
          <div>{{ this.langText.co_step1_modal_comment[this.langKey].split('/')[0] }}</div>
          <div>{{ this.langText.co_step1_modal_comment[this.langKey].split('/')[1] }}</div>
        </div>
        <div class="co-step1-modal-btn font1-M" v-on:click="modalRunning = false;">{{ this.langCommonText.confirm[this.langKey] }}</div>
      </div>
    </div>

<!--    <div class=""-->

  </div>

</template>

<script>
import languages from '../../modules/languages/check_out_step1'
import common from '../../modules/languages/common'
export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      list: [],
      allAmount: 0,
      modalRunning: false
    }
  },
  created() {

    localStorage.clear();

    if (!this.$store.state.GSTNO) {
      this.$router.replace('/app/home');
    }

    this.$emit('c-loadingStart');

    this.axios({
      method: "GET",
      url: `${process.env.VUE_APP_API_SERVER}/api/app/minibar_list`,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
      },
      params: {
        CUST_NO: this.$store.state.CUST_NO
      }
    }).then(async res => {
      const data = await res.data;

      if (data.status === "SUCCESS") {

        for(let p of data.result) {
          p.COUNT = 0;
          this.list.push(p)
        }

      }

      setTimeout(() => {
        this.$emit('c-loadingFinish');
      }, 300);

    }).catch(error => {
      console.error(error);

      setTimeout(() => {
        this.$emit('c-loadingFinish');
      }, 300);
    });





  },
  methods:{
    valueChange(item, su) {
      if (item.COUNT + su >= 0) {
        this.allAmount += su * item.SELPRICE;
        item.COUNT += su;
      }
    },
    nextPage(idx) {
      if (idx === 0) {
        this.$router.push('/app/check_out_step2');
      } else {
        /*
         * API Call
         *  - 선택 상품 전송
         * */

        const buyPrd = [];

        for(let p of this.list) {
          if (p.COUNT > 0) {
            buyPrd.push({
              PLUCOD: p.PLUCOD,
              COUNT: p.COUNT
            });
          }
        }

        if (buyPrd.length < 1) {
          this.modalRunning = true;
        } else {

          /* 미니바 포스팅 */
          this.$emit('c-loadingStart');
          this.axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_SERVER}/api/app/minibar_post_proc`,
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
            },
            data: {
              PLULIST: buyPrd,
              GSTNO: this.$store.state.GSTNO,
              TOTAL_AMOUNT: this.allAmount
            }
          }).then(async res => {

            const data = await res.data;

            this.$emit('c-loadingFinish');

            if (data.status === "SUCCESS") {
              // console.log(data);
              if (data.result.RETURN_ERR) {
                alert(data.result.RETURN_MSG);
                this.$router.replace('/app/home');
              } else if (data.result.BILLNO) {
                this.$router.replace('/app/check_out_step2');
              }
            } else {
              // alert('미니바 사용처리에 실패했습니다.');
              alert(this.langCommonText.alert_comment_27[this.langKey]);
              this.$router.replace('/app/home');
            }


          }).catch(error => {
            console.error(error);

            setTimeout(() => {
              this.$emit('c-loadingFinish');
            }, 300);
          });

        }
      }
    }
  }
}
</script>

<style scoped>

  .step-contents { width: 100%; padding: 0 15px 130px 15px; box-sizing: border-box; }

  .top-process-bar-box { width:100%; display: flex; align-items: center; box-sizing: border-box; padding: 10px 15px 30px 15px; }
  .top-process-circle-big { background-color: #CDC1B4; width: 15px; height: 15px; border-radius: 8px; display: flex; justify-content: center; align-items: center; }
  .top-process-circle-small { background-color: #9C836A; width: 7px; height: 7px; border-radius: 4px; position:relative; }
  .top-process-circle-op {  opacity: .5; }
  .top-process-text { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 100px; color: #808080; }
  .top-process-text-r { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 30px; color: #9C836A; }
  .top-process-text-special { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 100px; color: #9C836A; }
  .top-process-center-line { border-bottom: 1px #EDEDED dotted; width: calc(33% - 16px); margin: 0 2px; }
  .step-summary { width: 100%; border-radius: 8px; background-color: #F9F9F9; display: flex; justify-content: center; align-items: center; padding: 13px 0; font-size: 12px; color: #9C836A; margin: 10px 0; }

  .item-box { width: 100%; margin: 6px 0; display: flex; justify-content: space-between; align-items: center; }
  .item-info { display: flex; }
  .item-image { display: flex; justify-content: center; align-items: center; width: 66px; height: 66px; border-radius: 45px; border: 1px #F5F2F0 solid; background-color: #FFFFFF; margin-right: 5px; }
  .item-info-detail { display: flex; flex-direction: column; justify-content: center; }
  .item-info-name { font-size: 12px; color: #222222; }
  .item-info-price { font-size: 16px; margin-top: 6px; color: #9C836A; }
  .item-count-box { display: flex; justify-content: center; align-items: center; }
  .item-count-btn { display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; border: 1px #EDEDED solid; color: #808080; border-radius: 10px; }
  .item-count-value { display: flex; justify-content: center; align-items: center; width: 40px; font-size: 16px; color: #9C836A; }

  .footer-box { display: flex; position: fixed; bottom:0; left:0; width: 100%; flex-direction: column; }
  .total-price { width: 100%; display: flex; justify-content: space-between; align-items: center; padding: 0 20px; background-color: #4D4843; height: 60px; }
  .total-price-text { color: #A6A6A6; font-size: 12px; }
  .total-price-value { color: #FFFFFF; font-size: 16px; }
  .btn-box { width: 100%; display: flex; font-size: 14px; }
  .none-use-btn { width: 50%; display: flex; justify-content: center; align-items: center; height: 60px; color: #9C836A; background-color: #FFFFFF; }
  .use-btn { width: 50%; display: flex; justify-content: center; align-items: center; height: 60px; color: #FFFFFF; background-color: #9C836A; }

  .co-step1-modal-background{ top:0; width:100%; height:100vh; position:fixed; padding: 0 35px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.6); z-index: 10; }
  .modal-open{ display:flex; }
  .modal-close{ display:none; }
  .co-step1-modal{ width:100%; background-color: #FFF; border-radius: 14px; overflow: hidden; margin-bottom:30px; }
  .co-step1-modal-comment{ width:100%; margin:35px 0 30px 0; }
  .co-step1-modal-comment div{ width:100%; text-align: center; font-size:14px; color:#333333; }
  .co-step1-modal-btn{ width:100%; height:50px; background-color: #9C836A; display:flex; justify-content: center; align-items: center; color:#FFF; }

</style>

