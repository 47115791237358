<template>

    <main>
      <router-view v-slot="{ Component }">
        <transition :name="transitionName">
            <component :is="Component" />
        </transition>
      </router-view>
    </main>

</template>

<script>

export default {
  name: 'w_app',
  data() {
    return {
      transitionName: "",  // //화면 전환 효과
    }
  },
  watch: {
    // $route(to, from) {
    //   //console.log(to.name + " / " + from.name);
    // }
  }
}
</script>

<style>

.admin_layout{ height:100vh; position:relative; width:100%; overflow-y:auto; background-color:#F9F9F9; }

.admin_layout_menu{display:flex; flex-direction: column; width: 360px; min-height: 100vh; background-color:#4D4843; position: absolute;}
.admin_layout_menu_img_box{display:flex; justify-content: center; width: 100%;}
.admin_layout_menu_img{margin:43px 0px;width: 128px; height: 83px;}
.admin_layout_menu_item_box{display:flex; width: 100%; height: 90px;}
.admin_layout_menu_item{display:flex;margin: 27px 65px;}
.admin_layout_menu_item_img{width:36px; height: 36px;}
.admin_layout_menu_item_span{margin-left:17px; height: 36px; line-height:36px; font-size: 20px; color: white; font-family: AppleR}
.admin_layout_content_box{ margin-left:430px; width: calc(100% - 430px); height: 100vh; overflow:auto;}
.admin_layout_content{width: 1420px; margin-bottom: 50px; }
.admin_layout_logout_box{display:flex; flex-direction:row-reverse;margin-top: 32px; width:100%; height: 42px;}
.admin_layout_logout_button{display:flex; justify-content:center; width: 110px; height: 42px; line-height: 42px; color: white; font-size: 16px; font-family: AppleM; background-color: #9C836A; border-radius: 6px; cursor: pointer;}
.admin_layout_title_box{display:flex; width:300px;font-size: 32px; font-family: AppleB; color: #9C836A;}


.admin-select {position: relative;font-family: AppleM;}
.admin-select select {display: none; /*hide original SELECT element: */top: 20px;}

.admin-select-selected:after {position: absolute;content: "";top: 20px;right: 10px;width: 0;height: 0;border: 6px solid transparent;border-color: #9C836A transparent transparent transparent;}
.admin-select-selected.select-arrow-active:after {border-color: transparent transparent #9C836A transparent;top: 13px;}
.admin-select-items div,.admin-select-selected {color: black;padding: 13px 16px 14px 16px;border: 1px solid rgba(0, 0, 0, 0.1); cursor: pointer;}
.admin-select-items {position: absolute;background-color: white;max-height: 300px;overflow: auto;top: 100%;left: 0;right: 0;z-index: 99;}
.admin-select-hide {display: none;}
.admin-select-items div:hover, .same-as-selected {background-color: #f2f2f2;}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@font-face { font-family: 'AppleB'; src: url("../../assets/fonts/AppleGothicNeo/AppleSDGothicNeoB.ttf"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'AppleL'; src: url("../../assets/fonts/AppleGothicNeo/AppleSDGothicNeoL.ttf"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'AppleM'; src: url("../../assets/fonts/AppleGothicNeo/AppleSDGothicNeoM.ttf"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'AppleR'; src: url("../../assets/fonts/AppleGothicNeo/AppleSDGothicNeoR.ttf"); font-weight: normal; font-style: normal; }

@font-face { font-family: 'SFB'; src: url("../../assets/fonts/SFproFonts/SF-Pro-Text-Bold.otf"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'SFL'; src: url("../../assets/fonts/SFproFonts/SF-Pro-Text-Light.otf"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'SFM'; src: url("../../assets/fonts/SFproFonts/SF-Pro-Text-Medium.otf"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'SFR'; src: url("../../assets/fonts/SFproFonts/SF-Pro-Text-Regular.otf"); font-weight: normal; font-style: normal; }


</style>

