import {createRouter, createWebHistory} from 'vue-router'

/* 앱용 컴포넌트 */
import m_app from './components/app/app'
import m_home from './components/app/home'
import m_sign_in from './components/app/sign_in'
import m_breakfast_service from './components/app/breakfast_service';
import m_mileage_history from './components/app/mileage_history';
import m_mypage from './components/app/mypage';
import m_room_service from './components/app/room_service';
import m_stay_history from './components/app/stay_history';
import m_control_key from './components/app/control_key';
import m_point_history from './components/app/point_history';
import m_reservation from './components/app/reservation';
import m_happytalk from './components/app/happytalk';
import m_coupon_history from './components/app/coupon_history';
import m_stay_history_detail from './components/app/stay_history_detail';
import m_check_out_step1 from './components/app/check_out_step1';
import m_check_out_step2 from './components/app/check_out_step2';
import m_check_out_step3 from './components/app/check_out_step3';
import m_languages_set from './components/app/languages_set';

/* 웹용 컴포넌트 */
import w_app from './components/admin/app'
import w_login from './components/admin/login'
import w_doorlock_list from './components/admin/doorlock_list'
import w_doorlock_info from './components/admin/doorlock_info'
import w_register from './components/admin/register'
import w_modify from './components/admin/modify'
import w_key_issue_list from './components/admin/key_issue_list'
import w_key_usage_list from './components/admin/key_usage_list'

/************************수정 시 추가할 데이터************************/
/* Not Found */
import not_found from './components/admin/notfound'
/***************************************************************/

const routes = [
    {
        path: '/app',                                                                                   // DOMAIN/app 경로
        component: m_app,
        children:[
            { path: 'home', name:'m_home2', component: m_home },                                            // 홈 화면
            { path: 'sign_in', name:'m_sign_in', component: m_sign_in },                                    // 로그인 화면
            { path: 'breakfast_service', name:'m_breakfast_service', component: m_breakfast_service },      // 조식 서비스 화면
            { path: 'mileage_history', name:'m_mileage_history', component: m_mileage_history },            // 마일리지 내역 화면
            { path: 'mypage', name:'m_mypage', component: m_mypage },                                       // 마이페이지 화면
            { path: 'room_service', name:'m_room_service', component: m_room_service },                     // 룸 서비스 화면
            { path: 'control_key', name:'m_control_key', component: m_control_key },                        // 문 오픈 화면
            { path: 'point_history', name:'m_point_history', component: m_point_history },                  // 포인트 적립-사용 내역 화면
            { path: 'reservation', name:'m_reservation', component: m_reservation },                        // 예약 등록-수정 화면
            { path: 'happytalk', name:'m_happytalk', component: m_happytalk },                              // 해피톡 연결 웹
            { path: 'coupon_history', name: 'm_coupon_history', component: m_coupon_history },              // 쿠폰 내역 화면
            { path: 'stay_history', name:'m_stay_history', component: m_stay_history },                     // 예약 내역 화면
            { path: 'stay_history_detail', name:'m_stay_history_detail', component: m_stay_history_detail, props: true },// 예약 상세 화면
            { path: 'check_out_step1', name: 'm_check_out_step1', component: m_check_out_step1 },
            { path: 'check_out_step2', name: 'm_check_out_step2', component: m_check_out_step2 },
            { path: 'check_out_step3', name: 'm_check_out_step3', component: m_check_out_step3 },
            { path: 'languages_set', name: 'm_languages_set', component: m_languages_set },

            /************************수정 시 추가할 데이터************************/
            { path: '/:pathMatch(.*)*', name:'not_found_app', component: not_found }
            /***************************************************************/
        ]
    },
    {
        path: '/admin',
        component: w_app,
        children:[
            { path: 'login', name:'w_login2', component: w_login },
            { path: 'doorlock_list', name:'w_doorlock_list', component: w_doorlock_list },
            { path: 'doorlock_info', name:'w_doorlock_info', component: w_doorlock_info },
            { path: 'register', name:'w_register', component: w_register },
            { path: 'modify', name:'w_modify', component: w_modify },
            { path: 'key_issue_list', name:'w_key_issue_list', component: w_key_issue_list },
            { path: 'key_usage_list', name:'w_key_usage_list', component: w_key_usage_list },
            /************************수정 시 추가할 데이터************************/
            { path: '/:pathMatch(.*)*', name:'not_found_web', component: not_found }
            /***************************************************************/
        ]
    },
    /************************삭제할 데이터************************/
    //{ path: '/', name:'w_login', component: w_login }
    /***************************************************************/

    /************************수정 시 추가할 데이터************************/
    { path: '/:pathMatch(.*)*', name:'not_found', component: not_found }
    /***************************************************************/

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})




export default router
