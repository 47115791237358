const languages = {
    title:{
        KO: '예약 상세보기',
        EN: 'Reservation details',
        JA: '予約履歴を見る',
        ZNTW: '查看预订详情',
    },
    reservation_date:{
        KO: '예약일시',
        EN: 'Date of reservation',
        JA: '予約日付',
        ZNTW: '预订日期',
    },
    reservation_info:{
        KO: '예약자 정보',
        EN: 'Guest info',
        JA: '予約者情報',
        ZNTW: '预订人信息',
    },
    reservation_detail_info:{
        KO: '예약 상세 정보',
        EN: 'Reservation detail',
        JA: '予約詳細情報',
        ZNTW: '预订详情',
    },
    offer_name: {
        KO: '상품명',
        EN: 'Name of Product',
        JA: '宿泊プラン',
        ZNTW: '商品名称',
    },
    offer_room_type:{
        KO: '타입', //'객실타입',
        EN: 'types',
        JA: 'ベッドタイプ',
        ZNTW: '床型',
    },
    offer_date:{
        KO: '이용기간',
        EN: 'Stay period',
        JA: '利用期間',
        ZNTW: '使用时间',
    },
    offer_room_count:{
        KO: '객실수',
        EN: 'Number of rooms',
        JA: '部屋数',
        ZNTW: '客房数量',
    },
    people_count:{
        KO: '인원',
        EN: 'Number of persons',
        JA: '人数',
        ZNTW: '人数',
    },
    get_time:{
        KO: '수령시간',
        EN: 'Reservation received at',
        JA: '受領時間',
        ZNTW: '领取时间',
    },
    reservation_status:{
        KO: '예약 상태',
        EN: 'Reservation status',
        JA: '予約状況',
        ZNTW: '预订状态',
    },
    hope_visit_day:{
        KO: '방문 희망 날짜',
        EN: 'Preferred date ',
        JA: '宿泊希望日',
        ZNTW: '计划入住日期',
    },
    mill:{
        KO: '중식/석식',
        EN: 'Lunch/Dinner',
        JA: '昼食/夕食',
        ZNTW: '午餐/晚餐',
    },
    reservation_time:{
        KO: '예약시간',
        EN: 'Time reserved',
        JA: '予約時間',
        ZNTW: '预订时间',
    },
    extra_offer:{
        KO: '요청사항',// '추가 요청사항',
        EN: 'Requests',
        JA: 'リクエスト',
        ZNTW: '其它要求',
    },
    product_benefits:{
        KO: '상품 혜택',
        EN: 'Benefit',
        JA: '宿泊プランの特典',
        ZNTW: '商品优惠',
    },
    cancel_policy:{
        KO: '취소 규정',
        EN: 'Cancellation policy',
        JA: 'キャンセル規定',
        ZNTW: '取消规定',
    },
    payment_info:{
        KO: '결제 금액 정보',
        EN: 'Payment amount detail',
        JA: '決済料金情報',
        ZNTW: '付款金额信息',
    },
    payment_type:{
        KO: '결제 방법',// '결제수단',
        EN: 'Payment method',
        JA: '支払方法',
        ZNTW: '付款方式',
    },
    total_account:{
        KO: '최종 금액',// '최종 결제금액',
        EN: 'Final Price',
        JA: '最終決済料金',
        ZNTW: '最终支付金额',
    },
    notice_comment:{
        KO: '취소에 따른 환불 정책 및 수수료는 상품마다 상이합니다.<br/>자세한 내용은 예약 상세 내역에서 확인해주세요',
        EN: 'Cancellation policy and fees vary based on the product type.<br/> For further information, please refer to the reservation detail. ',
        JA: 'キャンセルによる返金ポリシーと手数料はプランごとに異なります。<br/>詳細は予約履歴でご確認ください。',
        ZNTW: '不同产品的取消退款政策和手续费各不相同。<br/>详细内容请参考预订明细。',
    }
}


module.exports = languages;
