<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      userAgent: navigator.userAgent.toLowerCase()
    }
  },
  created(){
    if ( this.userAgent.indexOf('android') > -1 ) {
      let style = document.createElement('link');
      style.type = "text/css";
      style.rel = "stylesheet";
      style.href = '../assets/css/AOSFont.css';
      document.head.appendChild(style);
    }else{
      let style = document.createElement('link');
      style.type = "text/css";
      style.rel = "stylesheet";
      style.href = '../assets/css/IOSFont.css';
      document.head.appendChild(style);
    }
  }
}
</script>

<style>
a{ text-decoration: none; color:#000; }
/* 컴포넌트 공통 */
* { box-sizing: border-box; font-family: inherit; }
html, body { height: 100vh; margin: 0; padding: 0; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); background-color:#ececec; }
main { height: 100vh; width:100%; display: grid; grid-template: "main"; flex: 1; position: relative; z-index: 0; overflow: hidden; }
main > * { grid-area: main; position: relative; }
main > :first-child { z-index: 1; }
</style>

