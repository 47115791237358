const languages = {
    title:{
        KO: '나의 포인트',
        EN: 'My points',
        JA: '保有ポイント',
        ZNTW: '我持有的积分',
    },
    point_history:{
        KO: '적립내역 보기',
        EN: 'View your balance',
        JA: '積立履歴を見る',
        ZNTW: '查看累积明细',
    },
    exchange_standard:{
        KO: '교환 기준',
        EN: 'Redemption Guidelines',
        JA: '交換基準',
        ZNTW: '兑换标准',
    },
    mileage_notice:{
        KO: '고객센터',
        EN: 'Customer Center',
        JA: 'カスタマーセンタ',
        ZNTW: '客户服务中心',
    },
    mileage_notice_1:{
        KO: '(월~금 / 9:00 ~ 18:00 / 단, 공휴일 제외)',
        EN: '(Mon.~Fri. / 9:00 ~ 18:00 / except for Bank Holidays)',
        JA: '(月～金/9:00～18:00/但し、祝日を除く)',
        ZNTW: '（周一~周五/ 9:00~18:00/公休日除外）',
    },
    mileage_point_exchange:{
        KO: '포인트 교환',
        EN: 'Redeeming Points',
        JA: 'ポイント交換',
        ZNTW: '兑换积分',
    },
    mileage_modal_comment:{
        KO: '포인트 내역이 없습니다.', //'적립 내역이 없습니다.',
        EN: 'You have no history of point accrual/expiration.',
        JA: 'ポイント履歴がありません。',
        ZNTW: '暂无积分记录。',
    },
    mileage_guide_0:{
        KO: '포인트 사용 가능 시점',
        EN: 'Redeeming points available from',
        JA: 'ポイント利用可能時点',
        ZNTW: '积分可用时间',
    },
    mileage_guide_1:{
        KO: 'Ocean Terrace View Upgrade (Deluxe 객실 한정)',
        EN: 'Ocean Terrace View Upgrade (limited to a Deluxe room)',
        JA: 'Ocean Terrace View Upgrade(デラックスルーム限定)',
        ZNTW: 'Ocean Terrace View Upgrade（仅限Deluxe客房）',
    },
    mileage_guide_2:{
        KO: '생크림 케이크 교환권',
        EN: 'A Fresh Cream Cake Voucher',
        JA: '生クリームケーキ交換券',
        ZNTW: '奶油蛋糕兑换券',
    },
    mileage_guide_3:{
        KO: '로비라운지 애프터눈티 2인 Set ( 토, 일, 굥휴일 및 특별 행사 기간 등 사용 제외 )',
        EN: 'Lobby Lounge Afternoon Tea Set for 2 persons (Blackout dates: Saturday, Sunday, Bank Holidays, and special event period)',
        JA: 'ロビーラウンジ・アフタヌーンティー2人分(土・日・祝日・特別イベント期間などは除く)',
        ZNTW: '大堂休息区下午茶双人套餐（周六、周日、公休日及特别活动期间等无法使用）',
    },
    mileage_guide_4:{
        KO: 'Lounge Paradise Benefits 2인 제공',
        EN: 'Lounge Paradise Benefits for 2 persons',
        JA: 'Lounge Paradise Benefits 2人分提供',
        ZNTW: '提供双人Lounge Paradise Benefits',
    },
    mileage_guide_5:{
        KO: '주중(일-목) 1박 숙박권 (Deluxe City View)',
        EN: 'One(1) Night Stay Voucher during Weekday(Sunday to Thursday) (Deluxe City View)',
        JA: '平日(日～木)1泊宿泊券(Deluxe City View)',
        ZNTW: '平日（周日~周四）一晚住宿券（Deluxe City View）',
    },
    mileage_guide_6:{
        KO: '주중(일-목) 1박 숙박권 (Deluxe Ocean Terrace View)',
        EN: 'One(1) Night Stay Voucher during Weekday(Sunday to Thursday) (Deluxe Ocean Terrace View)',
        JA: '平日(日～木)1泊宿泊券(Deluxe Ocean Terrace View)',
        ZNTW: '平日（周日~周四）一晚住宿券（Deluxe Ocean Terrace View）',
    },
    mileage_guide_7:{
        KO: '1박 숙박권(Deluxe city view /주중, 주말 모두 사용가능)',
        EN: 'One(1) Night Stay Voucher (Deluxe city view /throughout the week)',
        JA: '1泊宿泊券(Deluxe City View/平日・週末ともに使用可)',
        ZNTW: '一晚住宿券（Deluxecity view/平日和周末均可使用）',
    },
    mileage_guide_8:{
        KO: '1박 숙박권(Deluxe Ocean Terrace view /주중, 주말 모두 사용가능)',
        EN: 'One(1) Night Stay Voucher (Deluxe Ocean Terrace view /throughout the week)',
        JA: '1泊宿泊券(Deluxe Ocean Terrace view/平日・週末ともに使用可)',
        ZNTW: '一晚住宿券（Deluxe Ocean Terrace view/平日和周末均可使用）',
    },
    mileage_guide_9:{
        KO: '순다리 시그니처 바디 or 페이셜 (1h 30min) 이용권',
        EN: 'A Voucher for Sundari Signature Body or Facial (1h 30 min.)',
        JA: 'SUNDARIシグネチャーボディまたはフェイシャル(1時間30分)利用券',
        ZNTW: 'SUNDARI SIGNATURE身体或面部（1h 30min）使用券',
    },
    mileage_guide_10:{
        KO: '1박 숙박권(Junior Suite City view Room only /주중, 주말 모두 사용가능)',
        EN: 'One(1) Night Stay Voucher(Junior Suite City view Room only / all week)',
        JA: '1泊宿泊券(Junior Suite City view Room only/平日・週末ともに使用可)',
        ZNTW: '一晚住宿券（Junior Suite City view Room only/平日和周末均可使用）',
    },
    mileage_guide_11:{
        KO: '1박 숙박권(Ruby Suite Room only /주중, 주말 모두 사용가능)',
        EN: 'One(1) Night Stay Voucher (Ruby Suite Room only / throughout the week)',
        JA: '1泊宿泊券(Ruby Suite Room only/平日・週末ともに使用可)',
        ZNTW: '一晚住宿券（Ruby Suite Room only/平日和周末均可使用）',
    },
}


module.exports = languages;
