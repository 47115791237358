const languages = {
    intro_name_text: {
        KO: '님, 안녕하세요',
        EN: 'Hello',
        JA: '様、こんにちは。',
        ZNTW: '顾客，您好！',
    },
    talkTalk:{
        KO: '1:1 톡톡',
        EN: '1:1 Talk talk',
        JA: '1:1トーク',
        ZNTW: '1:1 Talk Talk',
    },
    refresh_gstno_title:{
        KO: '예약번호',
        EN: 'Reservation Number',
        JA: '予約番号',
        ZNTW: '预订号码',
    },
    key_select_btn:{
        KO: '키 선택하기',
        EN: 'Choose a key',
        JA: 'キーを選択',
        ZNTW: '选择房卡',
    },
    check_gstno_guide_1:{
        KO: '모바일 키 발급 전 입니다',
        EN: 'Mobile key is not yet activated. ',
        JA: 'モバイルキーの発行前です。',
        ZNTW: '尚未发放移动房卡',
    },
    check_gstno_guide_2:{
        KO: '예약번호 등록 전 입니다',
        EN: 'The reservation number is not yet registered.',
        JA: '予約番号の登録前です。',
        ZNTW: '尚未登记预订号码',
    },
    check_gstno_guide_3:{
        KO: '모바일 키는 체크인 시 자동 발급됩니다',
        EN: 'Mobile key is activated automatically upon check-in.',
        JA: 'モバイルキーはチェックインの時に自動的に発行されます。',
        ZNTW: '移动房卡将在办理入住时自动发放 ',
    },
    reservation_registration:{
        KO: '예약번호 등록',
        EN: 'Register your reservation number.',
        JA: '予約番号の登録',
        ZNTW: '登记预订号码',
    },
    home_coupon_modal_text_1:{
        KO: '곧 만료되는 쿠폰이 존재합니다',
        EN: 'You have coupons expiring soon.',
        JA: 'もうすぐ期限切れになるクーポンがあります。',
        ZNTW: '您有即将到期的优惠券',
    },
    home_coupon_modal_text_2:{
        KO: '해당 쿠폰 내역으로 이동할까요?',
        EN: 'Would you like to continue?',
        JA: '当クーポン履歴に移動しますか？',
        ZNTW: '是否查看该优惠券明细？',
    },
    today_not_show:{
        KO: '오늘 하루 보지 않기',
        EN: 'Do not show again today ',
        JA: '今日は一日見ない。',
        ZNTW: '今天不再显示',
    },
    home_modal_comment_1:{
        KO: '비대면 체크아웃에 실패하였습니다.',
        EN: 'Contactless checkout failed.',
        JA: '非対面チェックアウトに失敗しました。',
        ZNTW: '自助退房失败',
    },
    home_modal_comment_2:{
        KO: '프론트 데스크로 방문 부탁드립니다.',
        EN: 'Please contact front desk.',
        JA: 'フロントデスクまでお越しください。',
        ZNTW: '请到前台办理',
    },
    home_modal_comment_3:{
        KO: '프론트 데스크로 방문 부탁드립니다.',
        EN: 'Please contact front desk.',
        JA: 'フロントデスクまでお越しください。',
        ZNTW: '请到前台办理',
    },
    home_modal_comment_4:{
        KO: '조식 서비스 신청 내역이/존재하지 않습니다.',
        EN: 'Your application for complimentary/breakfast does not exist. ',
        JA: '朝食サービスの申請履歴が/存在しません。',
        ZNTW: '无早餐服务申请明细/',
    },
    home_modal_comment_5:{
        KO: '객실 서비스 내역이/존재하지 않습니다.',
        EN: 'Room service item/does not exist. ',
        JA: '客室サービス履歴が/存在しません。',
        ZNTW: '无客房服务明细/',
    },
    home_modal_comment_6:{
        KO: '예약 내역이/존재하지 않습니다.',
        EN: 'The reservation/does not exist. ',
        JA: '予約履歴が/存在しません。',
        ZNTW: '无预约明细/',
    },
    home_modal_comment_7:{
        KO: '도어락 해제 권한이/없습니다.',
        EN: 'You are not authorized/to open the door lock.',
        JA: 'ドアロック解除の権限が/ありません。',
        ZNTW: '您没有解除门锁的权限',
    },
    // menu_list_1:{
    //     KO: '예약 내역',//'호텔 예약 현황',
    //     EN: 'RESERVATIONS',
    //     JA: '予約内容',
    //     ZNTW: '预订记录',
    // },
    menu_list_1:{
        KO: '스마트 컨시어지',//'호텔 예약 현황',
        EN: 'Smart Concierge',
        JA: 'スマートコンシェルジュ', // 수정 필요
        ZNTW: '智能禮賓服務',// 수정 필요
    },
    menu_list_2:{
        KO: '객실 서비스',
        EN: 'Room benefits',
        JA: '客室サービス',
        ZNTW: '客房服务',
    },
    menu_list_3:{
        KO: '조식 서비스',
        EN: 'Complimentary Breakfast',
        JA: '朝食サービス',
        ZNTW: '早餐服务',
    },
    menu_list_4:{
        KO: '쿠폰', //'쿠폰 내역',
        EN: 'coupons',
        JA: 'クーポン',
        ZNTW: '优惠券',
    },
    menu_list_5:{
        KO: '나의 포인트',
        EN: 'My points',
        JA: '保有ポイント',
        ZNTW: '我持有的积分',
    },
    menu_list_6:{
        KO: '마이페이지',
        EN: 'My Page',
        JA: 'My Page',
        ZNTW: 'My Page',
    },


};

module.exports = languages;
