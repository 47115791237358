<template>

  <!-- 쿠폰 내역 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="@/assets/icon/back_btn.svg" v-on:click="$router.replace('/app/home');">
      <div class="font1-B">{{ this.langText.check_out_title[this.langKey] }}</div>
    </div>

    <div class="step-contents">
      <div class="top-process-bar-box">
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text">{{ this.langText.minibar[this.langKey] }}</span>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text">{{ this.langCommonText.account_check[this.langKey] }}</span>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text">{{ this.langCommonText.payment[this.langKey] }}</span>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-big">
          <div class="top-process-circle-small">
            <span class="top-process-text-r text-special">{{ this.langCommonText.complete[this.langKey] }}</span>
          </div>
        </div>
      </div>

      <div class="step-summary font1-R">
        {{this.langText.thank_comment[this.langKey]}}
        <span class="font1-M">{{this.langText.thank_comment_1[this.langKey]}}</span>
      </div>

      <div class="success-contents">

        <div class="success-box">
          <img src="@/assets/images/check_out_success.png" alt="체크아웃 성공" />
          <div class="ont1-M" v-html="this.langText.success_comment[this.langKey]"/>
          <span class="font1-R">{{this.langText.success_comment_1[this.langKey]}}</span>
        </div>

      </div>

    </div>

    <div class="footer-box">
      <div class="btn-box" v-on:click="$router.replace('/app/home');">{{this.langText.go_home[this.langKey]}}</div>
    </div>


  </div>

</template>

<script>
import languages from '../../modules/languages/check_out_step3'
import common from '../../modules/languages/common'
export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      value: 1
    }
  },
  created() {

    localStorage.clear();

    if (!this.$store.state.GSTNO) {
      this.$router.replace('/app/home');
    }

    this.$store.state.GSTNO = null;
    this.$store.state.MILEAGE_DATA = null;

    const data = {
      'mode':'deleteReservation'
    }

    try {
      if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
          this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
    } catch(e) {
      // console.log(e);
      // alert(e);
    }

  },
  methods:{

  }
}
</script>

<style scoped>

  .step-contents { width: 100%; padding: 0 15px 60px 15px; box-sizing: border-box; }

  .top-process-bar-box { width:100%; display: flex; align-items: center; box-sizing: border-box; padding: 10px 15px 30px 15px; }
  .top-process-circle-big { background-color: #CDC1B4; width: 15px; height: 15px; border-radius: 8px; display: flex; justify-content: center; align-items: center; }
  .top-process-circle-small { background-color: #9C836A; width: 7px; height: 7px; border-radius: 4px; position:relative; }
  .top-process-circle-op {  opacity: .5; }
  .top-process-text { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 100px; color: #808080; }
  .top-process-text-r { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 30px; color: #808080; }
  .text-special { color: #9C836A; }
  .top-process-center-line { border-bottom: 1px #EDEDED dotted; width: calc(33% - 16px); margin: 0 2px; }

  .step-summary { width: 100%; display: flex; justify-content: center; flex-direction: column; align-items: center; font-size: 14px; color: #808080; margin: 20px 0 10px 0; }
  .step-summary span { font-size: 16px; color: #9C836A; margin-top: 5px; }

  .success-contents { box-sizing: border-box; margin: 12px 0; }
  .success-box { width:100%; border-radius: 20px; background-color: #F9F9F9; display: flex; justify-content: center; align-items: center; flex-direction: column; }
  .success-box img { width: 50%; margin-top: 64px; }
  .success-box div { color: #9C836A; margin-top: 18px; text-align: center; }
  .success-box span { color: #808080; font-size: 12px; margin: 5px 0 15px 0; }

  .footer-box { display: flex; position: fixed; bottom:0; left:0; width: 100%; flex-direction: column; background-color: #9C836A; padding: 17px 0; }
  .btn-box { width: 100%; display: flex; justify-content: center; align-items: center; font-size: 14px; color: #FFFFFF; }

</style>

