<template>

  <!-- 마일리지 내역 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="@/assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
      <div class="font1-B">{{this.langText.title[this.langKey]}}</div>
    </div>

    <!--   마일리지 내역 컨텐츠( 내 보유 포인트, 총 적립 포인트, 사용 포인트, 소멸 예정 포인트 )   -->
    <div class="mileage-number-box">
      <div class="user-name-box">
        <div class="font2-R">Name</div>
        <span class="font1-R">{{Filter(this.$store.state.CUST_NM)}}</span>
      </div>

      <div class="mileages-box">
        <div class="mileage-box">
          <div class="m-p-t font1-R">{{this.langCommonText.paradian_point[this.langKey]}}</div>
          <div class="p1 font2-R">{{String(this.AVAIL_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
        </div>
        <div class="mileage-box">
          <div class="m-p-t font1-R">{{this.langCommonText.total_collect_point[this.langKey]}}</div>
          <div class="p1 font2-R">{{String(this.TOT_SV_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
        </div>
        <div class="mileage-box">
          <div class="m-p-t font1-R">{{this.langCommonText.can_use_point[this.langKey]}}</div>
          <div class="p2 font2-R">{{String(this.TOT_USE_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
        </div>
        <div class="mileage-box">
          <div class="m-p-t font1-R">{{this.langCommonText.will_disappear_point[this.langKey]}}</div>
          <div class="p2 font2-R">{{String(this.LAPSE_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
        </div>
      </div>

      <div class="mileage-history-btn font1-R" v-on:click="pointHistoryPage">{{this.langText.point_history[this.langKey]}}</div>

    </div>

    <!--  마일리 교환 기준 목록  -->
    <div class="mileage-guide-title"><span class="font2-R">Mileage point</span><div class="font1-R">{{this.langText.exchange_standard[this.langKey]}}</div></div>

    <div class="mileage-guide-table">
      <div class="mileage-guide-table-tr" v-for="(item) in mileage_guide" :key="item.t">
        <div class="mileage-guide-table-h font2-R">{{String(item.p).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}p</div>
        <div class="mileage-guide-table-v font1-R">{{item.t}}</div>
      </div>
    </div>

    <!--  하단 고객센터 정보  -->
    <div class="mileage-footer">
      <div class="mileage-footer-service font1-R">{{this.langText.mileage_notice[this.langKey]}} 051-749-2200<br>{{this.langText.mileage_notice_1[this.langKey]}}</div>
      <div class="mileage-footer-right">
        <img class="mileage-footer-qrcode" alt="qrcode" src="@/assets/images/qrcode.svg" >
        <div class="mileage-footer-qrcode-label font1-M" v-html="this.langText.mileage_point_exchange[this.langKey]"/>
      </div>
    </div>

    <!--  적립내역 보기 안내 모달(적립 내역이 없을 때)  -->
    <div class="mileage-modal-background" :class="modalRunning ? 'modal-open':'modal-close'">
      <div class="mileage-modal">
        <div class="mileage-modal-comment">
          <div>{{this.langText.mileage_modal_comment[this.langKey]}}</div>
        </div>
        <div class="mileage-modal-btn font1-M" v-on:click="modalRunning = false;">{{this.langCommonText.confirm[this.langKey]}}</div>
      </div>
    </div>


  </div>

</template>

<script>
import languages from '../../modules/languages/mileage_history'
import common from '../../modules/languages/common'

export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      mileage_guide: [                                                                          // 마일리지 교환 기준 목록
        { 'p': 5000, 't': languages.mileage_guide_0[this.$store.state.lang] },
        { 'p': 25000, 't': languages.mileage_guide_1[this.$store.state.lang] },
        { 'p': 30000, 't': languages.mileage_guide_2[this.$store.state.lang] },
        { 'p': 60000, 't': languages.mileage_guide_3[this.$store.state.lang] },
        { 'p': 80000, 't': languages.mileage_guide_4[this.$store.state.lang]},
        { 'p': 90000, 't': languages.mileage_guide_5[this.$store.state.lang] },
        { 'p': 100000, 't': languages.mileage_guide_6[this.$store.state.lang] },
        { 'p': 120000, 't': languages.mileage_guide_7[this.$store.state.lang] },
        { 'p': 130000, 't': languages.mileage_guide_8[this.$store.state.lang] },
        { 'p': 130000, 't': languages.mileage_guide_9[this.$store.state.lang] },
        { 'p': 250000, 't': languages.mileage_guide_10[this.$store.state.lang] },
        { 'p': 400000, 't': languages.mileage_guide_11[this.$store.state.lang] }
      ],
      TOT_SV_ML: this.$store.state.MILEAGE_DATA.TOT_SV_ML,                                      // 총 적립 포인트
      // TOT_SV_ML: 1000,
      TOT_USE_ML: this.$store.state.MILEAGE_DATA.TOT_USE_ML,                                    // 사용 포인트
      // TOT_USE_ML: 1000,
      AVAIL_ML: this.$store.state.MILEAGE_DATA.AVAIL_ML,                                        // 내 보유 포인트(파라디안 포인트)
      // AVAIL_ML:10000,
      LAPSE_ML: this.$store.state.MILEAGE_DATA.LAPSE_ML,                                        // 소멸 예정 포인트
      // LAPSE_ML: 10000,
      modalRunning: false                                                                       // 모달 작동 여부
    }
  },
  created() {

    /*
     * 비동기로 현재 페이지에서 사용될 데이터를 받아와서 적용한다.
     * 초기값은 이미 홈에서 받아온 기초데이터가 적용해져 있다.
     *  */
    this.axios({
      method: "GET",
      url: `${process.env.VUE_APP_API_SERVER}/api/app/mileage_history`,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
      },
      params: {
        CUST_ID: this.$store.state.CUST_ID,
        CUST_NO: this.$store.state.CUST_NO
      }
    }).then(res => {
      const data = res.data;

      if (data.status === "SUCCESS") {

        this.TOT_SV_ML = data.result[0].TOT_SV_ML;
        this.TOT_USE_ML = data.result[0].TOT_USE_ML;
        this.AVAIL_ML = data.result[0].AVAIL_ML;
        this.LAPSE_ML = data.result[0].LAPSE_ML;

        this.$store.state.MILEAGE_DATA.TOT_SV_ML = data.result[0].TOT_SV_ML;
        this.$store.state.MILEAGE_DATA.TOT_USE_ML = data.result[0].TOT_USE_ML;
        this.$store.state.MILEAGE_DATA.AVAIL_ML = data.result[0].AVAIL_ML;
        this.$store.state.MILEAGE_DATA.LAPSE_ML = data.result[0].LAPSE_ML;

      }
    }).catch(e => {
      // console.log.log(e);
      if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
        this.$router.replace('/app/sign_in');
      } else if(e.response.status === 401) {
        // console.log.log("None data");
      }
    });

  },
  methods:{

    /* 사용자 이름 중앙 숨김 처리 필터 */
    Filter(strName){
      if(strName){
        if (strName.length > 2) {
          let originName = strName.split('');
          originName.forEach(function(name, i) {
            if (i === 0 || i === originName.length - 1) return;
            originName[i] = '*';
          });
          let joinName = originName.join();
          return joinName.replace(/,/g, '');
        } else {
          let pattern = /.$/; // 정규식
          return strName.replace(pattern, '*');
        }
      }

    },

    /* 적립 내역 페이지 이동, 하지만 총 적립 포인트가 0 이라면 안내 모달 동작 */
    pointHistoryPage() {

      if(this.TOT_SV_ML * 1 < 1) {
        this.modalRunning =  true;
        return;
      }

      this.$router.push('/app/point_history');
    }
  }
}
</script>

<style scoped>
  .mileage-number-box{ width:100%; background-color:#262422; height:auto; }
  .user-name-box{ width:100%; border-bottom: 1px solid #4D4843; height:45px; display:flex; align-items: center; padding:0 16px; font-size:12px; }
  .user-name-box div{ color: #808080; }
  .user-name-box span{ color: #FFFFFF; margin-left: 12px; margin-bottom:1px; }
  .mileages-box{ width:100%; padding:14px 16px 16px 16px; display:flex; flex-wrap: wrap; justify-content: space-between; }
  .mileages-box .mileage-box{ width:calc(50% - 0.5px); background-color: #413D3C; padding: 16px 16px 12px 14px; margin-bottom:1px; }
  .mileage-box .m-p-t{ width:100%; text-align: left; font-size:12px; color:#FFFFFF; }
  .mileage-box .p1, .mileage-box .p2{ width:100%; text-align: right; margin-top: 25px; font-size:16px; }
  .mileage-box .p1{ color: #9C836A; }
  .mileage-box .p2{ color: #D8D8D8; }
  .mileage-history-btn{ margin-left:19px; color:#9C836A; font-size:12px; text-decoration-line: underline; padding-bottom:16px; }

  .mileage-guide-title{ width:100%; height:50px; display:flex; align-items: center; color:#9C836A; font-size:12px; padding:0 18px; }
  .mileage-guide-title span{ margin-right: 5px; }
  .mileage-guide-title div{ margin-bottom:1px; }
  .mileage-guide-table{ border-top:1px solid #EDEDED; width:100%; padding-bottom:80px; }
  .mileage-guide-table-tr{ width:100%; border-bottom: 1px solid #EDEDED; display:flex; align-items: center; flex-direction: row; font-size:12px; background-color:#F9F9F9; }
  .mileage-guide-table-h{ width:31%; height:100%; color:#9C836A; min-height:40px; display:flex; justify-content: center; align-items: center; }
  .mileage-guide-table-v{ width:69%; display:flex; align-items: center;  justify-content: flex-start; color: #666666;
    background-color:#FFF; min-height:40px; padding: 12px 16px; word-break: normal; }
  .mileage-footer{ position:fixed; display:flex; bottom:0; width:100%; align-items: center; background-color:#EDEDED; padding: 24px 16px; justify-content: space-between; height:80px; }
  .mileage-footer-service{ color:#666666; font-size:10px; }
  .mileage-footer-right{ display:flex; align-items: center; }
  .mileage-footer-qrcode{ width:30px; height:30px; }
  .mileage-footer-qrcode-label{ color:#666666; font-size:10px; margin-left:8px; }

  .mileage-modal-background{ top:0; width:100%; height:100vh; position:fixed; padding: 0 35px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.6); z-index: 10; }
  .modal-open{ display:flex; }
  .modal-close{ display:none; }
  .mileage-modal{ width:100%; background-color: #FFF; border-radius: 6px; overflow: hidden; margin-bottom:30px; }
  .mileage-modal-comment{ width:100%; margin:35px 0 30px 0; }
  .mileage-modal-comment div{ width:100%; text-align: center; font-size:14px; color:#333333; }
  .mileage-modal-btn{ width:100%; height:50px; background-color: #9C836A; display:flex; justify-content: center; align-items: center; color:#FFF; }

</style>

