<template>
  <!-- 문 오픈 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="controlKey-layout" :class="isActive ? 'common-show' : 'common-hide'">
      <div class="header">
        <img alt="뒤로가기" src="../../assets/icon/back_btn_white.svg" v-on:click="$router.go(-1);">
      </div>

      <!--   문 오픈 컨텐츠   -->
      <div class="control-key-content">

        <!--    컨텐츠 상단 문 오픈 실패시 안내 텍스트, 성공시 성공 이미지 노출    -->
        <div class="control-key-top-box font1-M">
          <span v-if="keyLifeCycle === 4">{{this.langText.control_key_comment_1[this.langKey]}}</span>
          <span v-if="keyLifeCycle === 4 && thisKey.BUILDING === 'ORG'">{{this.langText.control_key_comment_2[this.langKey]}}</span>
          <span v-else-if="keyLifeCycle === 4 && thisKey.BUILDING === 'NEW'">{{this.langText.control_key_comment_3[this.langKey]}}</span>
          <div v-html="CG"></div>
        </div>

        <!--    컨텐츠 상단 문 오픈 단계별 안내 텍스트    -->
        <div class="control-key-title font1-B">
          <div class="control-key-title1" v-show="keyLifeCycle === 1">{{this.langText.control_key_title_1[this.langKey]}}</div>
          <div class="control-key-title2" v-if="keyLifeCycle === 1">{{this.langText.control_key_title_2[this.langKey]}}</div>
          <div class="control-key-title2" v-else-if="keyLifeCycle === 2">{{this.langText.control_key_title_3[this.langKey]}}</div>
          <div class="control-key-title2" v-else-if="keyLifeCycle === 3">{{this.langText.control_key_title_4[this.langKey]}}</div>
          <div class="control-key-title2" v-else-if="keyLifeCycle === 4">{{this.langText.control_key_title_5[this.langKey]}}</div>
        </div>

        <!--    컨텐츠 중앙 카드키 이미지, 뒷 배경 효과    -->
        <div class="control-key-box">
          <img class="keyImage" src="../../assets/images/cardKeyLounge_KO.svg" v-if="thisKey.RMNO === 'P-LOUNGE'">
          <img class="keyImage" src="../../assets/images/cardKeyEntrance_KO.svg" v-else-if="thisKey.RMNO === 'ENTRANCE'">
          <img class="keyImage" src="@/assets/images/cardKeyRoom.svg" v-else>

          <div class="font1-R" v-if="thisKey.RMNO !== 'P-LOUNGE' && thisKey.RMNO !== 'EXE-LOUNGE' && thisKey.RMNO !== 'ENTRANCE' && thisKey.BUILDING === 'ORG'">{{this.langCommonText.main_building[this.langKey]}}</div>
          <div class="font1-R" v-else-if="thisKey.RMNO !== 'P-LOUNGE' && thisKey.RMNO !== 'EXE-LOUNGE' && thisKey.RMNO !== 'ENTRANCE' && thisKey.BUILDING === 'NEW'">{{ this.langCommonText.new_building[this.langKey] }}</div>
          <span class="font2-M" v-if="thisKey.RMNO !== 'P-LOUNGE' && thisKey.RMNO !== 'EXE-LOUNGE' && thisKey.RMNO !== 'ENTRANCE'">{{thisKey.RMNO}}</span>
          <img class="backgroundImage" v-if="keyLifeCycle !== 1 && keyLifeCycle !== 4" alt="카드 효과" src="@/assets/images/controlKeyBackground.gif">
        </div>

        <!--    문 오픈 버튼    -->
        <div class="useKey-btn-box">
          <div class="useKey-btn font1-M" v-if="keyLifeCycle === 1" v-on:click="openLock">{{btn_text_f}}</div>
          <div class="useKey-btn font1-M" v-else-if="keyLifeCycle === 4" v-on:click="openLock">{{this.langCommonText.re_open[this.langKey]}}</div>
        </div>

        <!--    컨텐츠 하단 체크아웃 날짜 텍스트    -->
        <div class="bottom-check-out-box">
          <span class="font1-M">{{this.langCommonText.check_out[this.langKey]}}</span>
          <span class="font1-R">{{checkOutText}} 11:00</span>
        </div>

      </div>

      <!--   하단 문 오픈 화면 가이드 팝업   -->
      <div class="guide-background" v-show="guideShow">
        <div class="guide-box" :class="guideBoxShow ? 'guide-box-open':'guide-box-close'">
          <div class="guide-title font1-M">{{ this.langText.guide_title[this.langKey] }}</div>
          <div class="guide-img"><img src="@/assets/images/guide_img.png" alt="가이드 사진"></div>
          <div class="guide-des font1-R"><span class="first">1.&nbsp;</span><span class="second">{{this.langText.guide_des_1[this.langKey]}}</span></div>
          <div class="guide-des font1-R"><span class="first">2.&nbsp;</span><span class="second">{{this.langText.guide_des_2[this.langKey]}}</span></div>
          <div class="guide-close-btn font1-R" v-on:click="guidBoxClose">확인</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment';
  import languages from '../../modules/languages/coupon_key'
  import common from '../../modules/languages/common'

  export default {
    data() {
      return {
        langText: languages,
        langCommonText: common,
        langKey: this.$store.state.lang,
        openLockRunning: false,       // 도어락 오픈 로직 작동 여부
        guideShow: false,             // 가이드 팝업 전체 노출 여부
        guideBoxShow: false,          // 가이드 팝업 노출 여부
        thisKey: null,                // 현재 열어야 하는 문 정보
        CG: '',                       // 문 오픈 성공시 추가할 html tag
        btn_text_f: languages.new_build_lounge_open[this.$store.state.lang],   // 문 오픈 버튼 텍스트
        checkOutText: '',             // 컨텐츠 하단 체크아웃 날짜 텍스트
        isActive: false,              // 현재 화면 노출 여부
        keyLifeCycle: 1,              // 문 오픈 단계 ( 1: 최초, 2: 키 작동중, 3: 오픈완료, 4: 오픈실패 후 재오픈 대기 )
      }
    },
    async created() {
      /*
       * 전역 선택키를 현재 페이지에서 사용할 선택키로 지정
       * 만약 전역 선택키가 없거나, 정보가 없을 때 이전 페이지로 이동
       * 만약 열어야 하는 문이 라운지가 아니라면 문 오픈 버튼 텍스트 변경
       * */
      this.thisKey = this.$store.state.SELECT_ROOM;
      if (!this.thisKey || this.thisKey.INFORMATION.length < 1) {
        await this.$router.go(-1);
        return;
      }
      if (this.thisKey && this.thisKey.RMNO !== "P-LOUNGE" && this.thisKey.RMNO !== 'EXE-LOUNGE') {
        this.btn_text_f = this.langText.open_room[this.langKey];
      } else if(this.thisKey && this.thisKey.RMNO === 'EXE-LOUNGE') {
        this.btn_text_f = this.langText.ex_round_open[this.langKey];
      }

      /*
       * 컨텐츠 하단 체크아웃 날짜 텍스트 지정
       * */
      const checkOutDayTime = this.thisKey.DEPARTDATE;
      let Month = '';
      const MCode = moment(checkOutDayTime, "YYYYMMDD").format("e");
      //TODO 에러 발견시 다시 주석 해제
      // if (MCode === "0") {
      //   Month = "일";
      // } else if (MCode === "1") {
      //   Month = "월";
      // } else if (MCode === "2") {
      //   Month = "화";
      // } else if (MCode === "3") {
      //   Month = "수";
      // } else if (MCode === "4") {
      //   Month = "목";
      // } else if (MCode === "5") {
      //   Month = "금";
      // } else if (MCode === "6") {
      //   Month = "토";
      // }
      Month = this.langCommonText.weekdays[this.langKey][parseInt(MCode)];
      this.checkOutText = moment(checkOutDayTime, "YYYYMMDD").format("YYYY.MM.DD("+Month+")");

      /* 가이드 팝업이 한번도 노출이 안됬다면 ( 앱 실행 후 최초 접근 ) 가이드 팝업 노출 */
      const self = this;
      setTimeout(() => {
        self.isActive = true;
        if (!this.$store.state.GUIDESTATE) {
          this.guideShow= true;
          setTimeout(() => {
            this.guideBoxShow = true;
          }, 100);
        }
      }, 500);

    },
    methods: {

      /* 가이드 팝업 닫기 */
      guidBoxClose() {
        this.$store.state.GUIDESTATE = '1';
        this.guideBoxShow = false;
        setTimeout(() => {
          this.guideShow = false;
        },300);
      },

      /*
       * 문 오픈 버튼 클릭 함수
       * 1. 현재 도어락 오픈 로직이 작동중이 아니라면, 도어락 로직 작동여부 변수 true 처리, 문 오픈 단계를 2단계로 변경
       * 2. 서버측에 해당 문을 열어도 되는지 검증
       *  - 열면 안되는 문 : 전역 선택한 문 오픈 데이터 유효 여부를 false 처리 후 이전 화면으로 이동
       * 3. 열어도 되는 문이라면 네이티브 측으로 도어락 오픈 명령 하달 후 문 오픈 단계를 3단계로 변경
       *  - 5초 후에도 문이 열리지 않았다면 네이티브 측에 중지처리, 서버측에 실패 비동기 전송 후 문 오픈 단계를 4단계로 변경
       * */
      async openLock () {
        if (!this.openLockRunning) {
          this.openLockRunning = true;
          this.keyLifeCycle = 2;
          try {
            const res = await this.axios({
              method: "GET",
              url: `${process.env.VUE_APP_API_SERVER}/api/app/open_lock`,
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
              },
              params: {
                CUST_ID: this.$store.state.CUST_ID,
                CUST_NO: this.$store.state.CUST_NO,
                GSTNO: this.$store.state.GSTNO,
                RMNO: this.thisKey.RMNO
              }
            });
            const data = await res.data;
            if (data.status === "SUCCESS" && data.result[0]['SYSTUS'] === 'UNLOCK') {
              window.ReactNativeWebView.postMessage(JSON.stringify({'mode':'openLock'}));
              setTimeout(() => {
                if (this.keyLifeCycle === 2) {
                  window.ReactNativeWebView.postMessage(JSON.stringify({'mode': 'openLockStop'}));
                  this.openLockResult(null, "FAIL");
                }
              }, 5000);
            } else {
              this.$store.state.CK_R = true;
              setTimeout(() => {
                this.$router.go(-1);
              }, 100);
            }
          } catch(e) {
            // console.log(e.toString());
            this.keyLifeCycle = 4;
            this.openLockRunning = false;
          }
        } else {
          // console.log("Running");
        }
      },

      /*
       * 문 오픈 결과값 처리 함수
       * 처리 결과를 서버측에 전송한다.
       * */
      openLockResult (CODE, STATE) {

        try {
          this.axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_SERVER}/api/app/open_lock_result`,
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
            },
            data: {
              CUST_ID: this.$store.state.CUST_ID,
              CUST_NO: this.$store.state.CUST_NO,
              CUST_NAME: this.$store.state.CUST_NM,
              GSTNO: this.$store.state.GSTNO,
              RMNO: this.thisKey.RMNO,
              CODE: CODE,
              LOCK: STATE,
            }
          });

        } catch(e) {
          // console.log(e.toString());
        }
      },

      /*
       * 네이티브 측에서 전달받은 데이터 처리 함수
       *  - 만약 ERROR, FAIL 을 전달 받았다면 문 오픈 단계를 4단계로 변경
       *  - 만약 SUCCESS 를 전달 받았다면
       *    1. 문 오픈 성공 html tag 추가(gif)
       *    2. 문 오픈 단계를 3단계로 변경
       *    3. 서버측에 성공 비동기 전송
       *    4. 1.5초 후 이전 페이지로 이동
       *  - 마지막으로 도어락 로직 작동여부 변수 false 처리
       * */
      receiveMessage(event){

        try {

          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////
          try {
            this.axios({
              method: "POST",
              url: `${process.env.VUE_APP_API_SERVER}/api/app/ble_event_data`,
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
              },
              data: {
                EVENT_DATA: event.data
              }
            });

          } catch(e) {
            // console.log('BLE_EVENT_CATCH' + e.toString());
          }

          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////


          const data = JSON.parse(event.data);

          if (data['mode'] === 'OPEN') {
            if (data['type'] === "ERROR") {
              this.keyLifeCycle = 4;
            } else if (data['type'] === "FAIL") {
              if (data['error'] && data['error'] === "PERMISSION") {
                this.$router.go(-1);
              } else {
                this.keyLifeCycle = 4;
                this.CG = '';
              }
            } else if (data['type'] === "SUCCESS") {
              this.CG = '<img alt="도어락 오픈" src="'+'../../assets/images/open_check.gif?dummy='+Math.floor(Math.random() * 111111111111111111)+'">';
              this.keyLifeCycle = 3;

              this.openLockResult(data['code'], "SUCCESS");

              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);

            } else {
              this.keyLifeCycle = 4;
              this.CG = '';
            }
          } else if (data['mode'] === 'OPENLOCKSTOP') {
            this.keyLifeCycle = 4;
          }
        } catch(e) {
          if(event.data && event.data.length > 0){
            // console.log(event.data);
            // console.log(e.toString());
          }
        }
        this.openLockRunning = false;
      },

      /* 네이티브 데이터 전달 함수 */
      sendMessage(data) {
        if (this.$store.state.USER_AGENT) {
          if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
                  this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
            window.ReactNativeWebView.postMessage(data);
          }
        }

      }
    },
    mounted() {

      /* 네이티브 측에서 전달받은 데이터 처리 함수 리스너 추가 */
      if (this.$store.state.USER_AGENT) {
        if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
          document.addEventListener("message", this.receiveMessage);
        } else if (this.$store.state.USER_AGENT.indexOf("iphone") > -1||this.$store.state.USER_AGENT.indexOf("ipad") > -1||this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
          window.addEventListener("message", this.receiveMessage);
        }
      }
    },
    unmounted() {

      /* 네이티브 측에서 전달받은 데이터 처리 함수 리스너 삭제 */
      if (this.$store.state.USER_AGENT) {
        if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
          document.removeEventListener("message", this.receiveMessage);
        } else if (this.$store.state.USER_AGENT.indexOf("iphone") > -1||this.$store.state.USER_AGENT.indexOf("ipad") > -1||this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
          window.removeEventListener("message", this.receiveMessage);
        }
      }

      /* 네이티브 측에 스캔종료 명령 하달 */
      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({'mode':'stopScan'}));
      } catch(e) {
        // console.log(e.toString());
      }
    }
  }
</script>

<style scoped>
  .common_layout{ background-color: #4D4843; overflow-x:hidden; }
  .guide-background{ position:fixed; background: rgba(0, 0, 0, 0.6); max-width:1920px; width:100%; height:100vh; top:0; z-index:3; }
  .guide-box{ position:absolute; transition-duration: .3s; left:0; width:100%; display:flex; flex-direction: column; background-color: #FFFFFF; border-top-right-radius: 8px; border-top-left-radius: 8px; padding: 24px; }
  .guide-title{ display:flex; justify-content: flex-start; width:100%; margin-top:20px; color:#222222; font-size:18px; }
  .guide-img{ display:flex; width:100%; justify-content: center; align-items: center; margin:30px 0 3px 0; }
  .guide-img img{ width:100%; }
  .guide-des{ width:100%; display:flex; color:#666666; font-size:12px; line-height:17px; margin-top:10px; }
  .guide-des .first{ width:14px; }
  .guide-des .second{ max-width:calc(100% - 14px); }
  .guide-close-btn{ width:100%; height:50px; background-color: #9C836A; margin-top:20px; border-radius: 6px; display:flex; justify-content: center; align-items: center; color:#FFFFFF; font-size:14px; }
  .guide-box-open{ bottom:0; }
  .guide-box-close{ bottom:-500px; }


  .control-key-content{ width:100%; min-height:calc(90vh - 50px); display:flex; flex-direction: column; padding:0 27px; justify-content: center; }

  .control-key-top-box{ position:relative; z-index:2; width:100%; display:flex; justify-content: center; align-items: center; height: 7vh; flex-direction: column; color:#D8D8D8; font-size:12px; }
  .control-key-title{ position:relative; z-index: 2; width:100%; color:#FFF; font-size:20px; line-height: 20px; text-align: center; display:flex; flex-direction: column; height:50px; justify-content: flex-end; margin-bottom:20%; }
  .control-key-title1{ margin-bottom:10px; }

  .control-key-box{ width:100%; position:relative; }
  .control-key-box .keyImage{ width:100%; position:relative; z-index:2; }
  .backgroundImage{ position:absolute; z-index:1; width:calc(100% + 98px); left:-49px; top:50%; transform:translateY(-50%); }

  /* 기존 파라다이스 객실키 css */
  .control-key-box div{ position:absolute; top:20%; left:50%; transform:translate(-50%, -50%); font-size:14px; color:#FFFFFF; letter-spacing: 0.29em; z-index:3; }

  /* 10.8 - 10.16 BTS 파라다이스 객실키 css */
  /*.control-key-box div{ position:absolute; top:23vw; left:50%; transform:translate(-50%, -50%); font-size:3.3vw; color:#FFFFFF; letter-spacing: 0.29em; z-index:3; }*/

  /* 기존 파라다이스 객실키 css */
  .control-key-box span{ position:absolute; top:43%; left:50%; z-index:3; transform:translate(-50%, -50%); font-size:20px; color:#EEEBDD; letter-spacing: 0.29em; }

  /* 10.8 - 10.16 BTS 파라다이스 객실키 css */
  /*.control-key-box span{ position:absolute; top:31vw; left:50%; z-index:3; transform:translate(-50%, -50%); color:#EEEBDD; letter-spacing: 0.29em; font-size:4.8vw; }*/

  .useKey-btn-box{ position:relative; z-index: 2; width:100%; display:flex; justify-content: center; margin-top:20%; height:54px; }
  .useKey-btn{ width:48%; height:40px; border: 1px solid #9C836A; border-radius: 6px; background-color: #262422; font-size:12px; color:#FFFFFF; display:flex; justify-content: center; align-items: center; }

  .bottom-check-out-box{ position:relative; z-index: 2; width:100%; display:flex; justify-content: center; align-items: center; flex-direction: column; font-size:12px; color:#FFF; margin-top:15%; }
  .bottom-check-out-box span{ margin-bottom:10px; }

  .common-hide{ opacity: 0; }
  .common-show{ transition: opacity .5s; opacity: 1; }
</style>

