const languages = {
    control_key_comment_1: {
        KO: '지속적인 문제 발생시',
        EN: 'When persistent problems occur,',
        JA: '継続的な問題が発生した場合は,',
        ZNTW: '当持续出现问题时',
    },
    control_key_comment_2: {
        KO: '프론트 데스크로(051-749-2114) 문의해주세요',
        EN: 'Please contact front desk on 051-749-2114.',
        JA: 'フロントデスク(電話051-749-2114)までお問い合わせください。',
        ZNTW: '请咨询前台（051-749-2114）',
    },
    control_key_comment_3:{
        KO: '프론트 데스크로(051-749-2140) 문의해주세요',
        EN: 'Please contact front desk on 051-749-2140.',
        JA: 'フロントデスク(電話051-749-2140)までお問い合わせください。',
        ZNTW: '请咨询前台（051-749-2140）',
    },
    control_key_title_1:{
        KO: '버튼 클릭 후, 휴대폰을',
        EN: 'Place your mobile phone close to',
        JA: 'ボタンをタップした後、携帯電話を',
        ZNTW: '点击按钮后，',
    },
    control_key_title_2:{
        KO: '잠금 장치에 가까이 대주세요',
        EN: ' the door lock after tapping the button.',
        JA: 'ドアロックに近づけてください。',
        ZNTW: '请将手机靠近锁定装置。',
    },
    control_key_title_3:{
        KO: '모바일 키를 확인 중입니다',
        EN: 'We’re activating your mobile key.',
        JA: 'モバイルキー確認に失敗しました。',
        ZNTW: '移动房卡确认失败',
    },
    control_key_title_4:{
        KO: '문이 열렸습니다',
        EN: 'Door is open.',
        JA: 'ドアが開きました。',
        ZNTW: '门已打开',
    },
    control_key_title_5:{
        KO: '모바일키 확인에 실패했습니다',
        EN: 'Mobile key activation failed.',
        JA: 'モバイルキーの確認に失敗しました。。',
        ZNTW: '移动房卡确认失败',
    },
    guide_title:{
        KO: '모바일 키 작동 방법',
        EN: 'How to use the mobile key',
        JA: 'モバイルキー操作方法',
        ZNTW: '移动房卡操作方法',
    },
    guide_des_1:{
        KO: '핸드폰에서 블루투스 위치정보 권한을 허용해주세요.',
        EN: 'Please allow Bluetooth and location info access on your mobile phone. ',
        JA: '携帯電話でBluetoothと位置情報の権限を許可してください。',
        ZNTW: '请在手机上开启蓝牙和定位服务',
    },
    guide_des_2:{
        KO: '이용하시는 객실의 도어락 가깝게 핸드폰을 대신 후 오픈 버튼을 클릭해주세요.',
        EN: 'Please place your mobile phone close to the door lock of your guest room and tap open button.',
        JA: 'ご利用するお部屋のドアロックに携帯電話をかざし、 オープンボタンをタップしてください。',
        ZNTW: '请将手机靠近客房门锁后，点击打开按钮。',
    },
    new_build_lounge_open:{
        KO: '라운지 파라다이스 오픈',
        EN: 'Open Lounge Paradise',
        JA: '新館ラウンジオープン',
        ZNTW: '打开附楼休息区 ',
    },
    open_room:{
        KO: '객실오픈',
        EN: 'Open your room ',
        JA: '客室オープン',
        ZNTW: '打开客房',
    },
    ex_round_open:{
        KO: 'EXE 라운지 오픈',
        EN: 'Open EXE Lounge ',
        JA: 'EXEラウンジオープン',
        ZNTW: '打开EXE休息区',
    }

}


module.exports = languages;
