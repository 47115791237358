import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueExcelXlsx from "vue-excel-xlsx";
import VueSession from './modules/vue_session'
import VueCookies from 'vue-cookies';




import { createStore } from 'vuex'

const store = createStore({
    state:{

        ADMIN_TOKEN : null,

        // CUST_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDVVNUX0lEIjoibXJkZXZlbGxvIiwiaWF0IjoxNzA2MDc4OTU2LCJleHAiOjE3MDg2NzA5NTYsImlzcyI6ImZ1bmlkZWEifQ.YVUFs3AhAiStwJuvs4mvKqsGNES-AEU0VCZUpU0d34o',
        // CUST_NO: '0003001880',                  // 고객번호 ( KEY )
        // CUST_NM: '홍성욱',                        // 고객명
        // CUST_PHONE: '',                         // 고객 핸드폰 번호
        // CUST_EMAIL: 'wpdus@mrdevello.com',      // 고객 이메일
        // CUST_ID: 'mrdevello',                   // 고객 아이디
        // GSTNO: null,                            // 예약번호

        CUST_TOKEN: null,
        CUST_NO: null,              // 고객번호 ( KEY )
        CUST_NM: null,              // 고객명
        CUST_PHONE: null,           // 고객 핸드폰 번호
        CUST_EMAIL: null,           // 고객 이메일
        CUST_ID: null,              // 고객 아이디
        GSTNO: null,                // 예약번호

        STAY_HISTORY_DATA : null,   // 예약 내역 데이터
        ROOM_SERVICE_DATA : null,   // 룸 서비스 데이터
        MILEAGE_DATA : null,        // 마일리지 데이터
        MEMBERSHIP_DATA: [],        // 멤버쉽 데이터
        BANNER_IMAGE_DATA: {BANNER_IMAGE_URL: null, BANNER_IMAGE_LINK: null}, // 하단 배너 값

        USER_AGENT: null,           // 앱 사용자 에이전트
        SELECT_ROOM: null,          // 홈에서 선택한 방

        BF_R: false,                // 조식 화면 데이터 유효 여부
        SH_R: false,                // 예약 내역 데이터 유효 여부
        RS_R: false,                // 룸 서비스 데이터 유효 여부
        CK_R: false,                // 선택한 문 오픈 데이터 유효 여부

        ISSIGNOUT: false,           // 로그아웃 여부 ( 로그인 페이지에서 사용 )
        GUIDESTATE: null            // 가이드 화면 노출 여부 ( 문 오픈 화면에서 사용 )
    }
});




// createApp(App).use(router).use(store).mount('#app')
const app = createApp(App);
app.config.globalProperties.axios = axios;
app.config.productionTip = false;
app.use(router).use(store).use(VueSession).use(VueCookies).use(VueExcelXlsx).mount('#app')
