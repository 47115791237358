<template>
  <div id="chat-layout">
    <img id="chat-loading" src="@/assets/icon/loading_icon.svg" alt="채팅 로딩" />
  </div>
</template>

<script>
  import languages from '../../modules/languages/happytalk'
  import common from '../../modules/languages/common'

  export default {
    name: 'm_happytalk',
    data() {
      return {
        langText: languages,
        langCommonText: common,
        langKey: this.$store.state.lang,
      }
    },
    mounted() {

      let ht = null;
      let CUST_NM = this.$route.query.CUST_NM;
      let CUST_PHONE = this.$route.query.CUST_PHONE;
      let CUST_EMAIL = this.$route.query.CUST_EMAIL;
      let CUST_NO = this.$route.query.CUST_NO;
      let CUST_ID = this.$route.query.CUST_ID;
      let runningHappyTalk = false;
      let timerVar = null;

      if (CUST_NM && CUST_PHONE && CUST_EMAIL && CUST_NO && CUST_ID) {

        ht = new window.Happytalk({
          siteId: '4000002042',
          siteName: '(주)파라다이스호텔부산',
          categoryId: '141187',
          divisionId: '141188',
        });

        ht.event.on('happytalk:open', function () {
          runningHappyTalk = true;

          const iframe = document.getElementById('HappytalkIframe');

          if (iframe) {

            iframe.style.opacity = "0";

            setTimeout(() => {
              $("#HappytalkIframe")
                  .css({
                    "transition-duration": ".5s",
                    "opacity":"1"
                  });
            }, 200);
          }

        });

        ht.event.on('happytalk:close', function () {
          window.close();
        });

        if(ht) {
          openHappyTalk(CUST_NM, CUST_PHONE, CUST_EMAIL, CUST_NO, CUST_ID);
        }

      }





      function openHappyTalk(CUST_NM, CUST_PHONE, CUST_EMAIL, CUST_NO, CUST_ID) {

        console.log();

        let chat_parameter = 'cus_extra_username='+CUST_NM;
        chat_parameter += ',cus_extra_phone='+CUST_PHONE;
        chat_parameter += ',cus_extra_email='+CUST_EMAIL;
        chat_parameter += ',site_uid='+CUST_NO;
        chat_parameter += ',uuid='+CUST_ID;
        chat_parameter += ',parameter1=회사타입';
        chat_parameter += ',parameter2='+CUST_PHONE;
        chat_parameter += ',parameter3='+CUST_NM;
        chat_parameter += ',parameter4='+CUST_PHONE;
        chat_parameter += ',parameter5='+CUST_EMAIL;
        ht.setParams(chat_parameter);

        ht.open();

        console.log("open HappyTalk");

        clearTimeout(timerVar);

        timerVar = setTimeout(() => {
          if (!runningHappyTalk) {
            openHappyTalk(CUST_NM, CUST_PHONE, CUST_EMAIL, CUST_NO, CUST_ID);
          }
        }, 1000);

      }

    }

  }
</script>

<style scoped>
  #chat-layout{ width:100%; height:100vh; overflow:hidden; display:flex; justify-content: center; align-items: center;
    background: rgba(0, 0, 0, 0.8); position:fixed; z-index: 1000000000; }
  #chat-loading{ width:45px; }
  #HappytalkIframe{ opacity: 0; }
  .closeChat{ opacity: 0; }
  .openChat{ opacity: 1; }
</style>
