<template>
  <!-- 홈 화면 컴포넌트 -->
  <div class="common_layout">

    <!--  홈 화면 컨텐츠  -->
    <div class="home-layout">

      <!--   홈 상단 좌측 로고, 우측 채팅(개발예정), 스마트컨시어지  -->
      <div class="app-title-box">
        <img class="app-logo" src="@/assets/images/logo.png" alt="앱 로고">
        <div class="app-title-btn">
          <div class="happy-talk-box" v-on:click="happyTalk">
            <img alt="채팅" src="@/assets/icon/chat_new.svg" >
            <span>{{ this.langText.talkTalk[this.langKey] }}</span>
          </div>
          <img class="lang-set-img" alt="언어설정" src="@/assets/icon/languages_set.svg" v-on:click="langSet">
        </div>
      </div>

      <div class="home-card-box">
        <div class="home-card-box-content">
          <div class="home-intro">
            <div class="intro-name-text" v-if="this.langKey === 'KO' || this.langKey === 'JA'">
              {{this.$store.state.CUST_NM}}
              <div class="intro-name-text-1">{{ this.langText.intro_name_text[this.langKey] }}</div>
            </div>
            <div class="intro-name-text" v-else-if="this.langKey === 'EN'">
              <div class="intro-name-text-1">{{ this.langText.intro_name_text[this.langKey] }}&nbsp;</div>
              {{this.$store.state.CUST_NM}}
            </div>
            <div class="intro-name-text" v-else>
              亲爱的 {{this.$store.state.CUST_NM}}
              <div class="intro-name-text-1">{{ this.langText.intro_name_text[this.langKey] }}</div>
            </div>
            <!-- 서버로부터 언어 받아와서 리스트에 넣기  -->
            <div class="user-class-box" v-if="this.$store.state.MEMBERSHIP_DATA.length > 0">
              <span :class="`${value.toLowerCase()}-style`" :key="index" v-for="(value, index) in this.$store.state.MEMBERSHIP_DATA">{{value}}</span>
            </div>
            <div v-else class="user-class-box" />


          </div>

          <!--
                홈 중앙 문 오픈 카드 키 박스
                - 문 오픈 카드키가 2개 이상인 경우에 swiper slide 적용
                - 문 오픈 카드키가 1개인 경우에 단일 카드키 이미지 적용
          -->
          <div class="home-key-box" v-if="roomInformation.length > 0 ">

            <div class="home-keys-swiper">
              <div class="swiper-wrapper">

                <swiper v-if="roomInformation.length > 1" :pagination="{ clickable:true }"
                        :centeredSlides="true" :slidesPerView="1.15" :spaceBetween="10" @swiper="onSwiper"
                        :loopedSlides="1" :autoHeight="false" :initialSlide="initialSlide" :rotate="0" @slideChange="slideChanged" :runCallbacksOnInit="true">
                  <swiper-slide v-for="item in roomInformation" :key="item.RMNO">
                    <img :src="cardImageSet(0, this.langKey)" v-if="item.RMNO === 'P-LOUNGE'">
                    <img :src="cardImageSet(1, this.langKey)" v-else-if="item.RMNO === 'ENTRANCE'">
                    <img src="@/assets/images/cardKeyRoom.svg" v-else>
                    <div class="font1-R" v-if="item.RMNO !== 'P-LOUNGE' && item.RMNO !== 'ENTRANCE' && item.BUILDING === 'ORG'">{{ this.langCommonText.main_building[this.langKey] }}</div>
                    <div style="text-align: center" class="font1-R" v-else-if="item.RMNO !== 'P-LOUNGE' && item.RMNO !== 'ENTRANCE' && item.BUILDING === 'NEW'">{{ this.langCommonText.new_building[this.langKey] }}</div>
                    <span class="font2-M" v-if="item.RMNO !== 'P-LOUNGE' && item.RMNO !== 'ENTRANCE'">{{item.RMNO}}</span>
                  </swiper-slide>
                </swiper>

                <div class="cardKeyOne" v-else>
                  <img :src="cardImageSet(0, this.langKey)" v-if="roomInformation[0].RMNO === 'P-LOUNGE'">
                  <img :src="cardImageSet(1, this.langKey)" v-else-if="roomInformation[0].RMNO === 'ENTRANCE'">
                  <img src="@/assets/images/cardKeyRoom.svg" v-else>
                  <div class="font1-R" v-if="roomInformation[0].BUILDING === 'ORG' && roomInformation[0].RMNO !== 'P-LOUNGE' && roomInformation[0].RMNO !== 'ENTRANCE'">{{ this.langCommonText.main_building[this.langKey] }}</div>
                  <div class="font1-R" v-else-if="roomInformation[0].BUILDING === 'NEW' && roomInformation[0].RMNO !== 'P-LOUNGE' && roomInformation[0].RMNO !== 'ENTRANCE'">{{ this.langCommonText.new_building[this.langKey] }}</div>
                  <span class="font2-M" v-if="roomInformation[0].RMNO !== 'P-LOUNGE' && roomInformation[0].RMNO !== 'ENTRANCE'">{{roomInformation[0].RMNO}}</span>
                </div>

              </div>
            </div>

            <div class="refresh-box">
              <div class="refresh-gstno">
                <div  class="refresh-gstno-title">{{ this.langText.refresh_gstno_title[this.langKey] }}</div>
                <div class="refresh-gstno-value">{{this.GSTNO}}</div>
                <img src="@/assets/icon/pen.svg" v-on:click="reservation" />
              </div>
              <div class="refresh-btn" v-on:click="refresh">
                <img src="@/assets/icon/refresh.svg" />
                <span>{{ this.langCommonText.refresh[this.langKey] }}</span>
              </div>
            </div>

            <div class="home-key-select-btn">
              <div class="key-select-btn-content">
                <div class="checkout-btn" v-if="!checkoutActive">&nbsp;&nbsp;&nbsp;{{ this.langCommonText.check_out[this.langKey] }}&nbsp;</div>
                <div class="checkout-btn checkout-active" v-on:click="checkOut" v-else>&nbsp;&nbsp;&nbsp;{{ this.langCommonText.check_out[this.langKey] }}&nbsp;</div>
                <div class="center-line">
                  <div></div>
                </div>
                <div class="key-select-btn" v-on:click="checkPermission">{{ this.langText.key_select_btn[this.langKey] }}</div>
              </div>
            </div>

          </div>

          <!--   예약번호가 없을 때  -->
          <div :class="GSTNO ? 'home-key-box-e' : 'home-key-box'" v-else>
            <div class="home-none-key-img">
              <div class="home-none-key-img-border">
                <img src="@/assets/icon/noneKeyIcon.svg" alt="키 없음">
                <span class="font1-M check-gstno-guide" v-if="GSTNO">{{ this.langText.check_gstno_guide_1[this.langKey] }}</span>
                <span class="font1-M check-gstno-guide" v-else>{{ this.langText.check_gstno_guide_2[this.langKey] }}</span>
                <span class="font1-M non-gstno-guide" v-if="GSTNO">{{ this.langText.check_gstno_guide_3[this.langKey] }}</span>
              </div>
            </div>
            <div class="refresh-box" v-if="GSTNO">
              <div class="refresh-gstno">
                <div class="refresh-gstno-title">{{ this.langCommonText.reservation_number[this.langKey] }}</div>
                <div class="refresh-gstno-value">{{ this.GSTNO }}</div>
                <img src="@/assets/icon/pen.svg" />
              </div>
              <div class="refresh-btn" v-on:click="refresh">
                <img src="@/assets/icon/refresh.svg" />
                <span>{{ this.langCommonText.refresh[this.langKey] }}</span>
              </div>
            </div>

            <div class="home-key-select-btn">
              <div class="key-add-btn" v-on:click="reservation">{{ this.langText.reservation_registration[this.langKey] }}</div>
            </div>

          </div>

        </div>
      </div>



      <!--   홈 하단 메뉴 리스트   -->
      <div :class="GSTNO ? 'home-menu-box-e' : 'home-menu-box'">
        <div class="home-menu-part font1-R" :key="item.menu" v-for="(item) in menuList" v-on:click="nextPage(item.link)">
          <img :src="require(`@/assets/icon/${item.img}`)" :alt="item.menu" />
          <span style="text-align: center">{{item.menu}}</span>
        </div>
      </div>

      <div class="home-banner-box" v-if="this.bannerImageObj.BANNER_IMAGE_LINK != null" v-on:click="bannerLink(this.bannerImageObj.BANNER_IMAGE_LINK)" >
        <img :src="this.bannerImageObj.BANNER_IMAGE_URL" alt="">
      </div>

    </div>

    <!--  홈 화면 모달  -->
    <div class="home-modal-background" :class="modalRunning ? 'modal-open':'modal-close'">
      <div class="home-modal">
        <div class="home-modal-comment">
          <div>{{modalText1}}</div>
          <div>{{modalText2}}</div>
        </div>
        <div class="home-modal-btn font1-M" v-on:click="modalRunning = false;">{{ this.langCommonText.confirm[this.langKey] }}</div>
      </div>
    </div>

    <div class="home-coupon-modal-background" :class="couponModalRunning ? 'modal-open':'modal-close'">
      <div class="home-coupon-modal">
        <div class="home-coupon-modal-comment">
          <div class="home-coupon-modal-text">{{ this.langText.home_coupon_modal_text_1[this.langKey] }}</div>
          <div class="home-coupon-modal-text">{{ this.langText.home_coupon_modal_text_2[this.langKey] }}</div>
          <div class="home-coupon-modal-checkbox">
            <img alt="쿠폰모달 하루 보지않기 ON" src="@/assets/icon/check_on.svg" v-if="couponCheckbox" v-on:click="couponCheckbox = false" />
            <img alt="쿠폰모달 하루 보지않기 OFF" src="@/assets/icon/check_off.svg" v-else v-on:click="couponCheckbox = true" />
            <span >{{ this.langText.today_not_show[this.langKey] }}</span>
          </div>
        </div>
        <div class="home-coupon-modal-btn-box">
          <div class="home-coupon-modal-btn1 font1-M" v-on:click="couponModalClick(false)">{{ this.langCommonText.cancel[this.langKey] }}</div>
          <div class="home-coupon-modal-btn2 font1-M" v-on:click="couponModalClick(true)">{{ this.langCommonText.confirm[this.langKey] }}</div>
        </div>
      </div>
    </div>

    <div class="home-modal-background" :class="redirectFailModal ? 'modal-open':'modal-close'">
      <div class="home-modal">
        <div class="home-modal-comment">
          <div>{{ this.langText.home_modal_comment_1[this.langKey] }}</div>
          <div>{{ this.langText.home_modal_comment_2[this.langKey] }}</div>
        </div>
        <div class="home-modal-btn font1-M" v-on:click="redirectFailModal = false;">{{ this.langCommonText.confirm[this.langKey] }}</div>
      </div>
    </div>


  </div>
</template>

<script>

import languages from '../../modules/languages/home'
import common from '../../modules/languages/common'

/* 스와이퍼 관련 */
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {EffectCoverflow, Pagination} from 'swiper';
SwiperCore.use([EffectCoverflow, Pagination]);
import cardKeyEntrance_KO from '@/assets/images/cardKeyEntrance_KO.svg';
import cardKeyEntrance_EN from '@/assets/images/cardKeyEntrance_EN.svg';
import cardKeyEntrance_JA from '@/assets/images/cardKeyEntrance_JA.svg';
import cardKeyEntrance_ZNTW from '@/assets/images/cardKeyEntrance_ZNTW.svg';

import cardKeyLounge_KO from '@/assets/images/cardKeyLounge_KO.svg';
import cardKeyLounge_EN from '@/assets/images/cardKeyLounge_EN.svg';
import cardKeyLounge_JA from '@/assets/images/cardKeyLounge_JA.svg';
import cardKeyLounge_ZNTW from '@/assets/images/cardKeyLounge_ZNTW.svg';


export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      startScanRunning: false,                                // 스캔이 동작중인지 여부
      initialSlide: 0,                                        // 스와이퍼 슬라이드 인덱스 지정
      swiper: null,                                           // 스와이퍼 객체
      roomInformation: [],                                    // 열수 있는 문 목록
      modalRunning: false,                                    // 홈 화면 모달 동작 여부
      couponModalRunning: false,                              // 쿠폰 모달 동작 여부
      couponCheckbox: false,                                  // 쿠폰 모달 체크박스 선택 여부
      modalText1: '',                                         // 홈 화면 모달 상단 텍스트
      modalText2: '',                                         // 홈 화면 모달 하단 텍스트
      refreshRun: true,                                       // 새로고침 동작 여부
      GSTNO: null,                                            // 예약 번호
      RMNO: null,                                             // 방 번호
      memberShipList: this.$store.state.MEMBERSHIP_DATA,      // 멤버십 리스트
      bannerImageObj: this.$store.state.BANNER_IMAGE_DATA,    // 배너 이미지 url, link
      menuList: [                                             // 홈 화면 하단 메뉴 목록
        // {'menu': languages.menu_list_1[this.$store.state.lang], link: '/app/stay_history', img: 'reshistory.svg'},
        {'menu': languages.menu_list_1[this.$store.state.lang], link: '/app/smart_concierge', img: 'smart_concierge.svg'},
        {'menu': languages.menu_list_2[this.$store.state.lang], link: '/app/room_service', img: 'rmservice.svg'},
        {'menu': languages.menu_list_3[this.$store.state.lang], link: '/app/breakfast_service', img: 'bfservice.svg'},
        {'menu': languages.menu_list_4[this.$store.state.lang], link: '/app/coupon_history', img: 'cpnhistory.svg'},
        {'menu': languages.menu_list_5[this.$store.state.lang], link: '/app/mileage_history', img: 'milhistory.svg'},
        {'menu': languages.menu_list_6[this.$store.state.lang], link: '/app/mypage', img: 'my.svg'}
      ],
      checkoutActive: false,
      redirectFailModal: false
    }
  },
  methods: {
    getBannerInfo(){
      this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_SERVER}/api/app/banner`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
        },
      }).then(async res => {
        if (res.data.status === 'SUCCESS') {
          const getData = res.data.result;
          this.$store.state.BANNER_IMAGE_DATA.BANNER_IMAGE_URL = getData.BANNER_IMAGE_URL
          this.$store.state.BANNER_IMAGE_DATA.BANNER_IMAGE_LINK = getData.BANNER_IMAGE_LINK
          this.bannerImageObj = this.$store.state.BANNER_IMAGE_DATA;

        }

      }).catch(error => {console.log(error)});
    },
    cardImageSet(type, langkey) {
      if(type === 0){
        if(langkey === 'KO'){
          return cardKeyLounge_KO;
        }
        if(langkey === 'EN'){
          return cardKeyLounge_EN;
        }
        if(langkey === 'JA'){
          return cardKeyLounge_JA;
        }
        if(langkey === 'ZNTW'){
          return cardKeyLounge_ZNTW;
        }
      }

      if(type === 1){
        if(langkey === 'KO'){
          return cardKeyEntrance_KO;
        }
        if(langkey === 'EN'){
          return cardKeyEntrance_EN;
        }
        if(langkey === 'JA'){
          return cardKeyEntrance_JA;
        }
        if(langkey === 'ZNTW'){
          return cardKeyEntrance_ZNTW;
        }

      }



    },
    async langSetMessage() {
      try{
        await window.ReactNativeWebView.postMessage(JSON.stringify({mode: "langCheck"}));
        if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
          document.addEventListener("message", (event) => {
            const data = JSON.parse(event.data);
            if(data['mode'] === 'LANGUAGE'){
              this.$session.set('lang_set', data['result']);
              this.$store.state.lang = data['result'];
              this.langKey = this.$store.state.lang;
            }
          });
        } else {
          window.addEventListener("message", (event) => {
            const data = JSON.parse(event.data);
            if(data['mode'] === 'LANGUAGE'){
              this.$session.set('lang_set', data['result']);
              this.$store.state.lang = data['result'];
              this.langKey = this.$store.state.lang;
            }
          });
        }
        if(this.langKey === undefined){
          const tempLang = this.$session.get('lang_set');
          if(tempLang !== undefined){
            this.$store.state.lang = tempLang;
          } else {
            this.$store.state.lang = 'KO';
          }
          this.langKey = this.$store.state.lang;

        }
        // axios default 언어 세팅
        this.axios.defaults.headers.common['Accept-Language'] = this.langKey;
      }catch (e) {
        console.log(e)
        this.$store.state.lang = 'KO';
        this.langKey = this.$store.state.lang;
        this.axios.defaults.headers.common['Accept-Language'] = this.langKey;
      }

      console.log('언어 설정', this.langKey);
    },
    async checkMyRoom() {
      // console.log.log(this.$store.state.CUST_TOKEN);
      try {
        const res = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_SERVER}/api/app/checkout_status`,
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
          },
          params: {
            GSTNO: this.$store.state.GSTNO
          }
        });

        const data = await res.data;

        // console.log.log(">> CheckOutDATA");
        // console.log.log(data);
        // console.log.log("<< CheckOutDATA");

        if (data.status === "SUCCESS") {
          if (data.result === 'Y') {
            this.checkoutActive = true;
          } else {
            this.checkoutActive = false;
          }
        }
      } catch (e) {
        // console.log.log(e);
        if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
          await this.$router.replace('/app/sign_in');
        } else if (e.response.status === 401) {
          // console.log.log("None data");
        }
      }

    },

    /*
     * 체크아웃 프로세스 이동 함수
     * 폴리오를 건너뛰고 체크아웃 하는 방법도 있나?
     */
    checkOut() {
      if (this.roomInformation.length > 0 && this.checkoutActive) {
        this.$router.push('/app/check_out_step1');
      }
    },

    couponModalClick(ck) {
      if (this.couponCheckbox) {
        /* 쿠키 담기 */
        this.$cookies.set('couponModal', 'hide', '1d');
        this.couponCheckbox = false;
        this.couponModalRunning = false;
      }
      if (ck) {
        this.$router.push('/app/coupon_history');
      } else {
        this.couponCheckbox = false;
        this.couponModalRunning = false;
      }
    },

    /* 언어 세팅 페이지로 이동 */
    langSet(){
      this.$router.push('/app/languages_set');
    },

    /* 스마트컨시어지 링크 클릭시 이벤트 네이티브 측에 클릭했다고 전달한다. */
    qrLink() {
      const data = {
        'mode':'qrLink',
        'arParam':this.RMNO,
        'gParam':this.$store.state.GSTNO
      };

      try {
        if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
            this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
          window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
      } catch(e) {
        // console.log.log(e);
        //alert(e);
      }
    },
    /* 배너 클릭했을시링크를 네이티브 측에 전달한다. */
    bannerLink(link) {
      const data = {
        'mode':'banner',
        'link': link == null ? '' : link
      };

      try {
        if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
            this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
          window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
      } catch(e) {
        // console.log.log(e);
        //alert(e);
      }
    },

    /*
     * 카드키 새로고침 함수
     * 1. 예약번호가 있는 경우 + 현재 새로고침이 동작중이 아니라면 비동기 기초 데이터 호출 함수를 호출하며, 새로고침 로딩화면을 보여주며 서버에서 해당 예약번호를 통해 카드키를 조회한다.
     * 2. 조회된 카드키 중 이전에 선택된 카드키가 있다면 해당 카드키로 스와이퍼 인덱스를 수정한다.
     *    ex) 두번째 카드키 선택 -> 타 페이지 -> 되돌아 왔을 때 여전히 두번째 카드키가 선택된 상태로 보여 지게끔
     * 3. 예약번호가 체크 아웃된 상태, 예약 중이지만 체크인 되지 않았고 라운지 이용 안할때, 유효한 예약번호가 아닐 때 저장한 예약번호를 삭제한다.
     * 4. 새로고침 로딩화면을 숨김처리 한다.
     * */
    async refresh() {

      if (!this.refreshRun && this.GSTNO) {

        this.getAppData();

        this.$emit('c-loadingStart');

        this.roomInformation = [];
        this.refreshRun = true;

        try {

          const res = await this.axios({
            method: "GET",
            url: `${process.env.VUE_APP_API_SERVER}/api/app/home`,
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
            },
            params: {
              CUST_ID: this.$store.state.CUST_ID,
              CUST_NO: this.$store.state.CUST_NO,
              CUST_NM: this.$store.state.CUST_NM,
              GSTNO: this.$store.state.GSTNO
            }
          });

          const data = await res.data;

          //// console.log.log(data);

          if (data.status === "SUCCESS") {

            this.RMNO = null;
            let newResult = [];

            if (data['result'].length > 0) {
              if (data['result'][0]['SYSTUS'] === 'REG') {


                for (let x=0;x<data['result'].length;x++) {

                  /* 객실번호 */
                  if (data['result'][x]['RMNO'] !== 'P-LOUNGE' && data['result'][x]['RMNO'] !== 'EXE-LOUNGE' && data['result'][x]['RMNO'] !== 'ENTRANCE') {
                    this.RMNO = data['result'][x]['RMNO'];
                  }

                  /* 객실 */
                  if (data['result'][x]['RMNO'] !== 'P-LOUNGE' && data['result'][x]['RMNO'] !== 'EXE-LOUNGE' && data['result'][x]['RMNO'] !== 'ENTRANCE') {
                    newResult.push(data['result'][x]);
                  }

                  /* P-LOUNGE */
                  if (data['result'][x]['RMNO'] === 'P-LOUNGE' && data['result'][x]['LOUNGE'] === 'YES') {
                    newResult.push(data['result'][x]);
                  }

                  /* ENTRANCE */
                  if (data['result'][x]['RMNO'] === 'ENTRANCE' && data['result'][x]['LOUNGE'] === 'YES') {
                    newResult.push(data['result'][x]);
                  }

                }

              } else if (data['result'][0]['SYSTUS'] === 'RES') {


                for (let x=0;x<data['result'].length;x++) {

                  /* P-LOUNGE */
                  if (data['result'][x]['RMNO'] === 'P-LOUNGE' && data['result'][x]['LOUNGE'] === 'YES') {
                    newResult.push(data['result'][x]);
                  }

                  /* ENTRANCE */
                  if (data['result'][x]['RMNO'] === 'ENTRANCE' && data['result'][x]['LOUNGE'] === 'YES') {
                    newResult.push(data['result'][x]);
                  }

                }


              }
            }

            if (newResult.length > 0) {

              this.roomInformation = newResult;
              this.initialSlide = 0;

              this.$store.state.SELECT_ROOM = newResult[0];

            } else {

              if (data['result'].length < 1) {

                //// console.log.log("유효하지 않은 예약번호");

                this.GSTNO = null;
                this.$store.state.GSTNO = null;
                this.roomInformation = [];
                try{
                  window.ReactNativeWebView.postMessage(JSON.stringify({'mode':'deleteReservation'}));
                }catch(e){
                  // console.log.log(e);
                }

              } else {
                // console.log.log("예약 대기, 라운지 출입 불가");
              }

            }

            await this.checkMyRoom();

          }

        } catch (e) {
          // console.log.log(e);
          if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
            await this.$router.replace('/app/sign_in');
          } else if (e.response.status === 401) {
            // console.log.log("None data");
          }
        } finally {

          this.refreshRun = false;
          this.$emit('c-loadingFinish');

        }

      }
    },

    /*
     * 스캔 시작 함수
     * 네이티브 측에 열어야할 도어락 목록 데이터를 전달하고, 스캔 시작을 명령한다.
     * */
    startScan() {

      const data = this.$store.state.SELECT_ROOM;

      const sendData = {'mode':'openScan', 'keyInformation': data.INFORMATION};

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify(sendData));
      } catch(e) {
        // console.log.log(e.toString());
      }


      if (this.roomInformation.length > 1) {
        document.getElementsByClassName('swiper-pagination')[0].remove();
      }

      setTimeout(() => {
        this.$router.push('/app/control_key');
      }, 10);

    },

    /* 스와이퍼 객체 지정 */
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    /* 스와이퍼 슬라이드가 바뀔때마다 선택된 카드키를 전역변수에 지정한다. */
    slideChanged(index) {
      this.$store.state.SELECT_ROOM = this.roomInformation[index.activeIndex];
      // console.log.log(this.$store.state.SELECT_ROOM.RMNO);
    },

    /* 예약 등록페이지 이동 함수 */
    reservation() {

      if (this.roomInformation.length > 1) {
        document.getElementsByClassName('swiper-pagination')[0].remove();
      }

      setTimeout(() => {
        this.$router.push('/app/reservation');
      }, 10);
    },

    /* 홈 화면 하단 페이지 목록 클릭시 해당 페이지 이동 함수 */
    nextPage(link) {

      if (link === "/app/breakfast_service" && this.roomInformation.length < 1) {
        const textList = this.langText.home_modal_comment_4[this.langKey].split('/');
        this.modalText1 = textList[0];
        this.modalText2 = textList[1];
        this.modalRunning = true;
        return;

      } else if(link === "/app/room_service" && this.roomInformation.length < 1) {
        const textList = this.langText.home_modal_comment_5[this.langKey].split('/');
        this.modalText1 = textList[0];
        this.modalText2 = textList[1];
        this.modalRunning = true;
        return;

      } else if (link === '/app/smart_concierge'){
        // smart_concierge 링크로 넘어가는 함수 실행
        this.qrLink();
        return;
      }

      if (this.roomInformation.length > 1) {
        document.getElementsByClassName('swiper-pagination')[0].remove();
      }
      setTimeout(() => {
        this.$router.push(link);
      }, 10);
    },

    /* 기초 데이터 호출 함수 */
    getAppData() {
      this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_SERVER}/api/app/get_information`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
        },
        params: {
          CUST_ID: this.$store.state.CUST_ID,
          CUST_NO: this.$store.state.CUST_NO,
          GSTNO: this.$store.state.GSTNO
        }
      }).then(async res => {
        const data = await res.data.result;

        // console.log.log(">> AppDATA");
        // console.log.log(data);
        // console.log.log("<< AppDATA");

        this.$store.state.MILEAGE_DATA = data.MILEAGE[0];
        if (data.ROOM_SERVICE.length > 0) {
          this.$store.state.ROOM_SERVICE_DATA = data.ROOM_SERVICE;
        } else {
          this.$store.state.ROOM_SERVICE_DATA = [];
        }
        if (data.STAY_HISTORY.length > 0) {
          this.$store.state.STAY_HISTORY_DATA = data.STAY_HISTORY;
        } else {
          this.$store.state.STAY_HISTORY_DATA = [];
        }

        // if (data.MEMBERSHIP.length > 0){
        //   let tempList = [];
        //   if(data.MEMBERSHIP[0].MILEAGE_DESC != null){
        //     tempList.push(data.MEMBERSHIP[0].MILEAGE_DESC);
        //   }
        //   if(data.MEMBERSHIP[0].PLATINUM_DESC != null){
        //     tempList.push(data.MEMBERSHIP[0].PLATINUM_DESC);
        //   }
        //   if(data.MEMBERSHIP[0].LEISURE_DESC != null){
        //     tempList.push(data.MEMBERSHIP[0].LEISURE_DESC);
        //   }
        //   this.$store.state.MEMBERSHIP_DATA = [...tempList];
        //   // this.memberShipList = this.$store.state.MEMBERSHIP_DATA;
        // } else {
        //   this.$store.state.MEMBERSHIP_DATA = [];
        // }

      }).catch(error => {
        console.log(error);
      });
    },

    /* 만료쿠폰 존재여부 확인 */
    expCouponCheck() {

      // 쿠키에 하루안보기가 설정되어있다면
      // this.$cookies.set('couponModal', 'hide', '1d');

      const couponCookie = this.$cookies.get('couponModal');

      if (couponCookie) {
        // console.log.log('hv ck');
        return;
      }

      this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_SERVER}/api/app/coupon_expire`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
        },
        params: {
          CUST_NO: this.$store.state.CUST_NO
        }
      }).then(async res => {
        const data = await res.data;

        if (data.status === "SUCCESS") {
          if (data.result[0].EXPIRE === 'Y') {
            this.couponModalRunning = true;
          }
        }

      }).catch(error => {
        console.log(error);
      });
    },

    /* 네이티브 측에 권한 확인 요청 */
    checkPermission() {

      if (!this.startScanRunning) {
        this.startScanRunning = true;
        try {
          window.ReactNativeWebView.postMessage(JSON.stringify({'mode':'checkPermissions'}));
        } catch(e) {
          // console.log.log(e.toString());
        } finally {
          setTimeout(() => {
            this.startScanRunning = false;
          }, 500);
        }
      }
    },

    /* 권한이 이미 다 있다면 그대로 스캔 시작 함수 호출 */
    receiveMessage(event){

      try {
        const data = JSON.parse(event.data);

        if (data['mode'] === 'PERMISSIONCHECK' && data['result'] === 'SUCCESS') {
          this.startScan();
        }

      } catch(e) {
        if(event.data && event.data.length > 0){
          // console.log.log(event.data);
          // console.log.log(e.toString());
        }
      }

    },

    happyTalk() {
      const data = {
        mode: 'openHappyTalk',
        CUST_ID: this.$store.state.CUST_ID,
        CUST_NO: this.$store.state.CUST_NO,
        CUST_NM: this.$store.state.CUST_NM,
        CUST_PHONE: this.$store.state.CUST_PHONE,
        CUST_EMAIL: this.$store.state.CUST_EMAIL
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  },
  async created() {
    //언어 세팅
    this.langSetMessage();
    const queryObject = this.$route.query;

    // console.log.log(queryObject);

    const storage = localStorage.getItem('tempData');
    if (queryObject && queryObject.redirect && queryObject.redirect === 'true' && storage && storage.length > 0) {
      // console.log.log('>> Redirect');
      const storageObj = JSON.parse(storage);

      // console.log.log(storage);

      this.$store.state.ADMIN_TOKEN = storageObj.ADMIN_TOKEN;
      this.$store.state.CUST_TOKEN = storageObj.CUST_TOKEN;
      this.$store.state.CUST_NO = storageObj.CUST_NO;
      this.$store.state.CUST_NM = storageObj.CUST_NM;
      this.$store.state.CUST_PHONE = storageObj.CUST_PHONE;
      this.$store.state.CUST_EMAIL = storageObj.CUST_EMAIL;
      this.$store.state.CUST_ID = storageObj.CUST_ID;
      this.$store.state.GSTNO = storageObj.GSTNO;
      this.$store.state.STAY_HISTORY_DATA = storageObj.STAY_HISTORY_DATA;
      this.$store.state.ROOM_SERVICE_DATA = storageObj.ROOM_SERVICE_DATA;
      this.$store.state.MILEAGE_DATA = storageObj.MILEAGE_DATA;
      this.$store.state.MEMBERSHIP_DATA = storageObj.MEMBERSHIP_DATA;
      this.$store.state.BANNER_IMAGE_DATA = storageObj.BANNER_IMAGE_DATA;
      this.$store.state.USER_AGENT = storageObj.USER_AGENT;
      this.$store.state.SELECT_ROOM = storageObj.SELECT_ROOM;
      this.$store.state.BF_R = storageObj.BF_R;
      this.$store.state.SH_R = storageObj.SH_R;
      this.$store.state.RS_R = storageObj.RS_R;
      this.$store.state.CK_R = storageObj.CK_R;
      this.$store.state.ISSIGNOUT = storageObj.ISSIGNOUT;
      this.$store.state.GUIDESTATE = storageObj.GUIDESTATE;

      // console.log.log('>> Redirect');

      this.redirectFailModal = true;

    } else if (queryObject && queryObject.checkOutFail && queryObject.checkOutFail === 'true') {
      this.redirectFailModal = true;
    }

    localStorage.clear();

    this.expCouponCheck();

    /* 메인 접근시 앱 실행 후 최초 접근이라면 기초 데이터 호출 함수를 호출한다. */
    if (!this.$store.state.MILEAGE_DATA) {
      this.getAppData();
    }

    this.getBannerInfo();

    /* 특정 페이지에서 오류로인해 홈 화면으로 되돌아 왔을 때 안내 모달을 보여준다. */
    if (this.$store.state.BF_R || this.$store.state.SH_R || this.$store.state.RS_R || this.$store.state.CK_R) {
      let textList = [];
      if (this.$store.state.BF_R) {
        textList = this.langText.home_modal_comment_4[this.langKey].split('/');
        this.$store.state.BF_R = false;
        this.modalRunning = true;

      } else if (this.$store.state.RS_R) {
        textList = this.langText.home_modal_comment_5[this.langKey].split('/');
        this.$store.state.RS_R = false;
        this.modalRunning = true;

      } else if (this.$store.state.SH_R) {
        textList = this.langText.home_modal_comment_6[this.langKey].split('/');
        this.$store.state.SH_R = false;
        this.modalRunning = true;

      } else if (this.$store.state.CK_R) {
        textList = this.langText.home_modal_comment_7[this.langKey].split('/');
        this.$store.state.CK_R = false;
        this.modalRunning = true;

      }
      this.modalText1 = textList[0];
      this.modalText2 = textList[1];
    }

    /*
     * 1. 예약번호가 있는 경우 로딩화면을 보여주며 서버에서 해당 예약번호를 통해 카드키를 조회한다.
     * 2. 조회된 카드키 중 이전에 선택된 카드키가 있다면 해당 카드키로 스와이퍼 인덱스를 수정한다.
     *    ex) 두번째 카드키 선택 -> 타 페이지 -> 되돌아 왔을 때 여전히 두번째 카드키가 선택된 상태로 보여 지게끔
     * 3. 예약번호가 체크 아웃된 상태, 예약 중이지만 체크인 되지 않았고 라운지 이용 안할때, 유효한 예약번호가 아닐 때 저장한 예약번호를 삭제한다.
     * 4. 로딩화면을 숨김처리 한다.
     * */
    if (this.$store.state.GSTNO) {

      this.$emit('c-loadingStart');

      try {
        const res = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_SERVER}/api/app/home`,
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
          },
          params: {
            CUST_ID: this.$store.state.CUST_ID,
            CUST_NO: this.$store.state.CUST_NO,
            CUST_NM: this.$store.state.CUST_NM,
            GSTNO: this.$store.state.GSTNO
          }
        });

        const data = await res.data;

        // console.log.log(">> KeyDATA");
        // console.log.log(data);
        // console.log.log("<< KeyDATA");

        if (data.status === "SUCCESS") {

          let newResult = [];

          if (data['result'].length > 0) {
            if (data['result'][0]['SYSTUS'] === 'REG') {

              for (let x=0;x<data['result'].length;x++) {

                /* 객실번호 */
                if (data['result'][x]['RMNO'] !== 'P-LOUNGE' && data['result'][x]['RMNO'] !== 'EXE-LOUNGE' && data['result'][x]['RMNO'] !== 'ENTRANCE') {
                  this.RMNO = data['result'][x]['RMNO'];
                }

                /* 객실 */
                if (data['result'][x]['RMNO'] !== 'P-LOUNGE' && data['result'][x]['RMNO'] !== 'EXE-LOUNGE' && data['result'][x]['RMNO'] !== 'ENTRANCE') {
                  newResult.push(data['result'][x]);
                }

                /* P-LOUNGE */
                if (data['result'][x]['RMNO'] === 'P-LOUNGE' && data['result'][x]['LOUNGE'] === 'YES') {
                  newResult.push(data['result'][x]);
                }

                /* ENTRANCE */
                if (data['result'][x]['RMNO'] === 'ENTRANCE' && data['result'][x]['LOUNGE'] === 'YES') {
                  newResult.push(data['result'][x]);
                }

              }

            } else if (data['result'][0]['SYSTUS'] === 'RES') {

              for (let x=0;x<data['result'].length;x++) {

                /* P-LOUNGE */
                if (data['result'][x]['RMNO'] === 'P-LOUNGE' && data['result'][x]['LOUNGE'] === 'YES') {
                  newResult.push(data['result'][x]);
                }

                /* ENTRANCE */
                if (data['result'][x]['RMNO'] === 'ENTRANCE' && data['result'][x]['LOUNGE'] === 'YES') {
                  newResult.push(data['result'][x]);
                }

              }

            }
          }

          this.GSTNO = this.$store.state.GSTNO;

          if (newResult.length > 0) {

            //// console.log.log("체크인 또는 예약대기, 라운지 출입 가능");

            let isSelectRoom = false;

            this.roomInformation = newResult;
            if (this.$store.state.SELECT_ROOM) {
              for (let x = 0; x < newResult.length ; x++ ) {
                if (newResult[x]['RMNO'] === this.$store.state.SELECT_ROOM['RMNO']) {
                  this.initialSlide = x;
                  isSelectRoom = true;
                }
              }
            }

            if (!isSelectRoom) {
              this.$store.state.SELECT_ROOM = newResult[0];
            }

          }
          else {

            if (data['result'].length < 1) {

              //// console.log.log("유효하지 않은 예약번호");

              this.GSTNO = null;
              this.$store.state.GSTNO = null;
              this.roomInformation = [];
              try {
                window.ReactNativeWebView.postMessage(JSON.stringify({'mode':'deleteReservation'}));
              } catch(e) {
                // console.log.log(e);
              }

            } else {
              // console.log.log("예약 대기, 라운지 출입 불가");
            }

          }

        }

      } catch (e) {
        // console.log.log(e);
        if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
          await this.$router.replace('/app/sign_in');
        } else if (e.response.status === 401) {
          // console.log.log("None data");
        }
      } finally {

        this.refreshRun = false;
        this.$emit('c-loadingFinish');

      }
      this.checkMyRoom();
    }

  },
  mounted() {
    /* 네이티브 측에서 전달받은 데이터 처리 함수 리스너 추가 */
    if (this.$store.state.USER_AGENT) {
      if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
        document.addEventListener("message", this.receiveMessage);
      } else if (this.$store.state.USER_AGENT.indexOf("iphone") > -1||this.$store.state.USER_AGENT.indexOf("ipad") > -1||this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
        window.addEventListener("message", this.receiveMessage);
      }
    }
  },
  unmounted() {

    /* 네이티브 측에서 전달받은 데이터 처리 함수 리스너 삭제 */
    if (this.$store.state.USER_AGENT) {
      if (this.$store.state.USER_AGENT.indexOf('android') > -1) {
        document.removeEventListener("message", this.receiveMessage);
      } else if (this.$store.state.USER_AGENT.indexOf("iphone") > -1||this.$store.state.USER_AGENT.indexOf("ipad") > -1||this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
        window.removeEventListener("message", this.receiveMessage);
      }
    }

  }

}
</script>

<style lang="scss">
.swiper{ padding-top:15px; }
.swiper-pagination-bullet-active{ background-color: #9C836A; transition: width .3s; width: 20px; border-radius: 5px; }
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom, .swiper-pagination-fraction{ top: 0; height: 10px; display: flex; justify-content: end; box-sizing: border-box; padding: 0 7vw; }
</style>

<style scoped>
.swiper-container { display: flex; width: 100%; flex-direction: column; padding-bottom: 35px; background-color: transparent; }
.home-keys-swiper{ display: flex; width: 100%; flex-direction: column; align-items: center; box-shadow: transparent; background-color: transparent; position:relative; z-index:0; margin:8px 0; }
.swiper-slide{ display:flex; justify-content: center; position:relative; width:100%; }
.swiper-slide img{ width:100%; max-width:calc(100vw * 0.85); max-height:calc((100vw * 0.85) / 100 * 61); }
.swiper-slide div{ position:absolute; top:20%; left:50%; transform:translate(-50%, -50%); font-size:14px; color:#FFFFFF; letter-spacing: 0.29em; }
.swiper-slide span{ position:absolute; top:43%; left:50%; transform:translate(-50%, -50%); font-size:20px; color:#EEEBDD; letter-spacing: 0.29em; }
.cardKeyOne{ display:flex; justify-content: center; position: relative; width:100%; }
.cardKeyOne img{ width:100%; max-width:calc(100vw * 0.85); max-height:calc((100vw * 0.85) / 100 * 61); }
.cardKeyOne div{ position:absolute; top:20%; left:50%; transform:translate(-50%, -50%); font-size:14px; color:#FFFFFF; letter-spacing: 0.29em; }
.cardKeyOne span{ position:absolute; top:43%; left:50%; transform:translate(-50%, -50%); font-size:20px; color:#EEEBDD; letter-spacing: 0.29em; }


.refresh-box { width: 100%; display: flex; justify-content: space-between; height:35px; align-items: center; padding: 0 6vw; box-sizing: border-box; }
.refresh-gstno { display: flex; font-size:12px;padding: 0 3px; }
.refresh-gstno-title { margin-right: 1vw; color:#808080; }
.refresh-gstno-value { margin-right: 1vw; color:#666666; }
.refresh-btn { border-radius: 6px; box-sizing: border-box; border: 1px #D9D9D9 solid; background-color:#FFFFFF; display: flex; margin-right:3px; padding: 4px 6px; align-items: center; }
.refresh-btn img { width: 14px; margin-right: 2px; }
.refresh-btn span { font-size: 11px; color: #666666; }

.home-key-select-btn { position: absolute; bottom: -20px; width: 100%; display: flex; justify-content: center; }
.key-add-btn { display: flex; justify-content: center; align-items: center; padding: 11px 20px; height: 22px; box-sizing: content-box; background-color: #9C836A; color: #FFF; border-radius: 20px; font-size: 12px; }
.key-select-btn-content { display: flex; justify-content: center; align-items: center; padding: 11px 15px; height: 22px; box-sizing: content-box; background-color: #9C836A; color: #FFF; border-radius: 20px; font-size: 12px; }
.checkout-btn { height: 22px; line-height:22px; display: flex; justify-content: end; opacity: .6; }
.checkout-active { opacity: 1; }
.center-line { height: 22px; display: flex; justify-content: center; align-items: center; width: 30px; }
.center-line div { width: 1px; background-color: #FFF; height: 20px; }
.key-select-btn { height: 22px; line-height:22px; display: flex; justify-content: start; }







/*.home-key-select-btn{ position: absolute; bottom: -36px; left:50%; transform:translateX(-50%); width:auto; padding: 11px 18px; box-sizing: content-box; letter-spacing: 1.1px; height:22px; display:flex; justify-content: center; align-items: center; background-color: #9C836A; color: #FFF; border-radius: 20px; font-size: 12px; }*/
.reservation-btn{ margin-top:20px; }

.app-title-box{ width:100%; display:flex; justify-content: space-between; align-items: center; padding: 12px 24px; margin:20px 0; }
.app-logo{ max-width:50%; }
.app-title-btn{ display:flex; align-items: center }
.app-title-btn .lang-set-img{ margin-left: 8px; height:24px; width:24px; }

.happy-talk-box{ display: flex; gap: 5px; align-items: center; padding: 4px 10px; background: #9C836A; border-radius: 60px; }
.happy-talk-box > img { width: 18px; object-fit: contain}
.happy-talk-box > span{font-size: 12px; color: white; margin-bottom: 3px}
.home-intro{ width:100%; box-sizing: border-box; padding: 24px 25px 0 25px; display: flex; flex-wrap: wrap; flex-direction: column; }
.home-intro > div{ line-height: 35px; font-size: 24px; }
.home-intro .user-class-box{ margin-top: 5px; line-height: 21px!important; display: flex; flex-direction: row; flex-wrap: wrap; gap: 6px}
.home-intro .user-class-box > span {display: flex; padding: 2px 12px; font-size: 12px; color: white; border-radius: 12px; }
.ruby-style {background:  #C95C55}
.aquamarine-style{background: #434D92;}
.sapphire-style{background: #954AC0;}
.diamond-style{background: #BD9B6B;}
.signature-style{background: #80705F;}
.fitness-style{background: #5F5448;}
.intro-name-text{display: flex; flex-wrap: wrap; color:#9C836A; overflow: hidden; text-overflow:ellipsis; }
.intro-name-text-1{color:black; overflow: hidden; text-overflow:ellipsis; }

.home-key-box{ max-width:1920px; width:100%; padding:0 0 40px 0; box-sizing: border-box; display:flex; flex-direction: column; align-items: center; position:relative; }
.home-key-box-e { max-width:1920px; width:100%; padding:0 0 20px 0; box-sizing: border-box; display:flex; flex-direction: column; align-items: center; position:relative; }
.home-none-key-img{ display:flex; justify-content: center; width: calc(100vw * 0.85); }
.home-none-key-img .home-none-key-img-border{ display:flex; justify-content: center; align-items: center; flex-direction: column; border-radius: 20px; border: 1px dashed #9C836A; height:49.6vw; width:100%; margin:8px 0 6px 0; background-color:#FFF; }
.home-none-key-img .home-none-key-img-border img{ width:15%; }
.home-none-key-img .home-none-key-img-border .check-gstno-guide{ padding: 0 10px; margin-top:12px; color:#333333; font-size:4.3vw; text-align: center  }
.home-none-key-img .home-none-key-img-border .non-gstno-guide{ margin-top:5px; color:#666666; font-size:3vw; }

.home-menu-box{ width:100%; display:flex; flex-direction: row; background-color: #FFFFFF; flex-wrap: wrap; justify-content: center; margin-top:45px; padding: 0 10px; margin-bottom: 40px; }
.home-menu-box-e{ width:100%; display:flex; flex-direction: row; background-color: #FFFFFF; flex-wrap: wrap; justify-content: center; margin-top: 25px; padding: 0 10px; margin-bottom: 40px; }
.home-menu-part{ width:33%; box-sizing: border-box; display:flex; flex-direction: column; align-items: center; margin-top: 15px; }
.home-menu-part span{ font-size:3.7vw; color:#222222; }
.home-menu-part img{ width: 65%; }

.home-banner-box{ padding: 30px 10px; width: 100%; display: flex;  }
.home-banner-box > img {width: 100%; border-radius: 10px; object-fit: cover}

.home-modal-background{ top:0; width:100%; height:100vh; position:fixed; padding: 0 35px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.6); z-index: 10; }
.modal-open{ display:flex; }
.modal-close{ display:none; }
.home-modal{ width:100%; background-color: #FFF; border-radius: 6px; overflow: hidden; margin-bottom:30px; }
.home-modal-comment{ width:100%; margin:35px 0 30px 0; }
.home-modal-comment div{ width:100%; text-align: center; font-size:14px; color:#333333; }
.home-modal-btn{ width:100%; height:50px; background-color: #9C836A; display:flex; justify-content: center; align-items: center; color:#FFF; }

.home-coupon-modal-background{ top:0; width:100%; height:100vh; position:fixed; padding: 0 20px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.6); z-index: 9; }
.home-coupon-modal { width:100%; background-color: #FFF; border-radius: 12px; overflow: hidden; max-width: 280px; }
.home-coupon-modal-comment { width: 100%; padding: 24px 15px 16px 15px; display: flex; justify-content: center; align-items: center; flex-direction: column;  }
.home-coupon-modal-text { font-size: 14px; color: #222222; }

.home-coupon-modal-checkbox { width: 100%; margin-top: 16px; display: flex; justify-content: center; align-items: center; color: #808080; }
.home-coupon-modal-checkbox img { width: 14px; height: 14px; margin-right: 5px; }
.home-coupon-modal-checkbox span { font-size: 12px; height: 16px; display: flex; justify-content: center; align-items: center;  }
.home-coupon-modal-btn-box { width: 100%; display: flex; justify-content: center; align-items: center; }
.home-coupon-modal-btn1 { width:100%; height:50px; background-color: #FFF; display:flex; justify-content: center; align-items: center; color:#9C836A; box-sizing: border-box; border-top: 1px #EDEDED solid; }
.home-coupon-modal-btn2 { width:100%; height:50px; background-color: #9C836A; display:flex; justify-content: center; align-items: center; color:#FFF; box-sizing: border-box; border-top: 1px #9C836A solid; }


.home-card-box { width:100%; box-sizing: border-box; padding: 0 10px 0 10px; }
.home-card-box-content { width: 100%; background-color: #F5F2F0; box-sizing: border-box; border-radius: 16px; position: relative; }
</style>
