<template>
  <div class="admin_layout" style="display: flex; justify-content: center;">
    <div class="login_admin_layout">

      <div class="login_box">
        <div class="login_img_box">
          <img class="login_img_logo" alt="관리자 로고" src="@/assets/images/admin_login_logo.png">
        </div>

        <div class="login_id_box">
            <div class="login_id_box_title">
              관리자 아이디
            </div>
            <div class="login_id_box_border" id="busan_id_border">
              <input class="login_id_box_input" type="text" id="busan_id" name="busan_id" v-model="busan_id_value" @keyup.enter="login" >
            </div>
        </div>
        <div class="login_pw_box">
          <div class="login_pw_box_title" >
            관리자 비밀번호
          </div>
          <div class="login_pw_box_border" id="busan_pw_border">
            <input class="login_pw_box_input" type="password" id="busan_pw" name="busan_pw" v-model="busan_pw_value" @keyup.enter="login" >
          </div>
        </div>

        <div class="login_button" @click="login" >
          관리자 로그인
        </div>
      </div>

    </div>


  </div>
</template>

<script>


  export default {
    components:{

    },
    data(){
      return{
        busan_id_value: '',
        busan_pw_value: '',
      }
    },
    async created(){

      // // 세션 값이 있을 경우 도어락 리스트 페이지로 이동
      // if(this.$session.get('admin_session') !== '' && this.$session.get('admin_session') ){
      //   this.$router.replace('/admin/doorlock_list');
      // }


      // 로그인 세션 검증
      const adminSession = sessionStorage.getItem('admin_session');
      const expirationTime = sessionStorage.getItem('expiration_time');
      const now = new Date().getTime();


      // 세션이 있거나, 만료 시간이 지나지 않은 경우
      // 세션 값이 있을 경우 도어락 리스트 페이지로 이동
      if (adminSession || now < expirationTime) {
        this.$router.replace('/admin/doorlock_list');
      }



    },
    async mounted(){
      //input창 입력 시, 색상 변경
      this.input_border();
    },
    methods:{

      //로그인 버튼 클릭 시
      async login(){

        if(this.busan_id_value === ''){
          // alert('아이디를 입력해주세요');
        }else if(this.busan_pw_value === ''){
          alert('비밀번호를 입력해주세요');
        }else{

          try{

            const res = await this.axios({
              method: "POST",
              url: `${process.env.VUE_APP_API_SERVER}/api/admin/login`,
              data: {
                busan_id: this.busan_id_value,
                busan_pw: this.busan_pw_value}
            });

            const data = await res.data;


            if(data.status === "SUCCESS"){

              if(data.result === 'login_success'){

                // //ADMIN_SESSION 생성, jwt 토큰 저장
                // this.$session.set('admin_session', data.result_token.ADMIN_TOKEN, 30);


                // 세션 저장 및 만료 시간 설정
                const expirationTime = new Date().getTime() + 3600 * 1000; // 180초 후 만료
                sessionStorage.setItem('admin_session', data.result_token.ADMIN_TOKEN);
                sessionStorage.setItem('expiration_time', expirationTime);



                //도어락 리스트 페이지로 이동
                this.$router.replace('/admin/doorlock_list');

              }else{
                alert('아이디 혹은 비밀번호를 올바르게 입력해주세요');
              }

            }else{
              alert('서버가 불안정합니다. 잠시 후에 다시 시도해주세요');
              console.log('fail')
            }

          }catch (e) {
            alert('서버가 불안정합니다. 잠시 후에 다시 시도해주세요');
            console.log(e);
          }

        }

      },


      //input창 입력 시, 색상 변경
      input_border(){

        const busan_id_input = document.getElementById('busan_id');
        const busan_pw_input = document.getElementById('busan_pw');

        busan_id_input.addEventListener('focus', () => {
          document.getElementById('busan_id_border').style.borderBottom='2px solid #9C836A'
        });

        busan_id_input.addEventListener('blur', () => {
          document.getElementById('busan_id_border').style.borderBottom='1px solid #ddd'
        });

        busan_pw_input.addEventListener('focus', () => {
          document.getElementById('busan_pw_border').style.borderBottom='2px solid #9C836A'
        });

        busan_pw_input.addEventListener('blur', () => {
          document.getElementById('busan_pw_border').style.borderBottom='1px solid #ddd'
        });

      }

    }
  }
</script>


<style scoped>

  .login_admin_layout {margin:120px;width: 900px; height: 700px; background-color: white;}
  .login_box {margin:50px 100px; width: 700px; height: 600px; }
  .login_img_box {display: flex; justify-content: center; width: 100%; height: 180px; background-color: white; }
  .login_img_logo {width: 188px; height: 120px; }
  .login_id_box{margin-top:40px; display:inline-block; width: 100%; height: 60px;}
  .login_id_box_title{display:inline-block; width: 100%; height: 20px; font-family: AppleR; color: #808080; font-size: 18px;}
  .login_id_box_border{display:inline-block; width: 100%; height: 50px; border-bottom: 1px solid #ddd;}
  .login_id_box_input{margin-top: 15px; width:100%; height: 30px; border: none; font-family: AppleM; outline:none;font-size: 22px; caret-color: #9C836A;}
  .login_pw_box{margin-top:50px; display:inline-block; width: 100%; height: 60px;}
  .login_pw_box_title{display:inline-block; width: 100%; height: 20px;font-family: AppleR; color: #808080; font-size: 18px;}
  .login_pw_box_border{display:inline-block; width: 100%; height: 50px; border-bottom: 1px solid #ddd;}
  .login_pw_box_input{margin-top: 15px; width:100%; height: 30px; border: none; font-family: AppleM; outline:none;font-size: 22px;caret-color: #9C836A;}
  .login_button{margin-top:100px; display: flex; justify-content: center; width: 100%; height: 70px; background-color: #9C836A; border-radius: 6px; font-family: AppleM; color: white; line-height:70px; font-size: 24px; font-weight: normal; cursor: pointer;}


</style>
