<template>
  <!-- 조식 서비스 컴포넌트 -->
  <div class="common_layout">

    <div class="depth-content-box">

      <!--   상단 뒤로가기   -->
      <div class="header">
        <img alt="뒤로가기" src="../../assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
        <div class="font1-B">{{ this.langText.breakfast_service_title[this.langKey] }}</div>
      </div>

      <!-- 바코드 관련 -->
      <div class="depth-barcode-content">
        <div class="depth-barcode-box" v-if="RMNO">
          <div class="depth-barcode-room font1-L">{{RMNO}} {{ this.langCommonText.room[this.langKey] }}</div>
          <svg class="barcode"></svg>
          <div class="barcode-time font1-B">{{this.currentTime}}</div>
          <div  class="depth-barcode-text font1-L">{{ this.langText.depth_barcode_text[this.langKey] }}</div>
        </div>
      </div>

      <!-- 하단 조식 서비스 안내 -->
      <div class="depth-breakfast-bottom-box">
        <div class="depth-bottom-title font1-R">{{ this.langText.depth_bottom_title[this.langText] }}</div>

        <div class="depth-bottom-sub-container">

          <!--왼쪽-->
          <div class="depth-bottom-sub-box">
            <div class="depth-bottom-sub-section">
              <div class="depth-bottom-sub-section-title font1-R">{{ this.langCommonText.location[this.langKey] }}</div>
              <div class="depth-bottom-sub-section-content font1-L">{{this.langCommonText.main_building[this.langKey]}} 1F ON THE PLATE</div>
            </div>

            <div class="depth-bottom-sub-section-box">
              <div class="depth-bottom-sub-section depth-bottom-sub-section-row">
                <div class="depth-bottom-sub-section-title font1-R">{{this.langCommonText.business_hour[this.langKey]}}</div>
                <div class="depth-bottom-sub-section-content font1-R">06:30 ~ 10:30</div>
              </div>
              <span  class="depth-bottom-sub-section-etc font1-R">{{this.langText.business_hour_comment_1[this.langKey]}}</span>
            </div>


            <div class="depth-bottom-sub-section-box">
              <div class="depth-bottom-sub-section depth-bottom-sub-section-row">
                <div class="depth-bottom-sub-section-title font1-R">{{this.langCommonText.seat[this.langKey]}}</div>
                <div  class="depth-bottom-sub-section-content font1-R">{{this.langText.seat_count_1[this.langKey]}}</div>
              </div>
              <span class="depth-bottom-sub-section-etc font1-R">{{this.langText.seat_count_2[this.langKey]}}</span>
            </div>

            <div class="depth-bottom-sub-section">
              <div class="depth-bottom-sub-section-title font1-R">{{ this.langCommonText.contact[this.langKey] }}</div>
              <div class="depth-bottom-sub-section-content font1-R">051-749-2234/2235</div>
            </div>
          </div>
          <div class="depth-bottom-sub-middle-line"/>

          <!--오른쪽-->
          <div class="depth-bottom-sub-box">
            <div class="depth-bottom-sub-section">
              <div  class="depth-bottom-sub-section-title font1-R">{{ this.langCommonText.location[this.langKey] }}</div>
              <div class="depth-bottom-sub-section-content font1-L">{{this.langCommonText.new_building[this.langKey]}} 1F LOUNGE PARADISE</div>
            </div>

            <div class="depth-bottom-sub-section-box">
              <div class="depth-bottom-sub-section depth-bottom-sub-section-row">
                <div class="depth-bottom-sub-section-title font1-R">{{this.langCommonText.business_hour[this.langKey]}}</div>
                <div class="depth-bottom-sub-section-content font1-R">07:00 ~ 10:00</div>
              </div>
              <span class="depth-bottom-sub-section-etc font1-R">{{this.langText.business_hour_comment_2[this.langKey]}}</span>
            </div>


            <div class="depth-bottom-sub-section-box">
              <div class="depth-bottom-sub-section depth-bottom-sub-section-row">
                <div class="depth-bottom-sub-section-title font1-R">{{this.langCommonText.seat[this.langKey]}}</div>
                <div class="depth-bottom-sub-section-content font1-R">{{this.langText.seat_count_3[this.langKey]}}</div>
              </div>
              <span class="depth-bottom-sub-section-etc font1-R" style="visibility: hidden">-</span>
            </div>

            <div class="depth-bottom-sub-section">
              <div class="depth-bottom-sub-section-title font1-R">{{ this.langCommonText.contact[this.langKey] }}</div>
              <div class="depth-bottom-sub-section-content font1-R">051-749-2253</div>
            </div>
          </div>

        </div>


      </div>

    </div>

  </div>

</template>

<script>

import JsBarcode from 'jsbarcode';
import moment from "moment-timezone";
import languages from '../../modules/languages/breakfast_service'
import common from '../../modules/languages/common'
export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      // langKey: 'ZNTW',
      RMNO: '10101',    // 룸 번호 ( 조식 바코드 번호 )
      currentTime: moment().tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss'),
      intervalVeriable: null
    }
  },
  created() {
    /* 전역 변수에 저장된 룸넘버가 있다면 현재 페이지에서 전역 룸넘버 그대로 사용 */
    if (this.$store.state.STAY_HISTORY_DATA.length > 0) {
      this.RMNO = this.$store.state.STAY_HISTORY_DATA[0].RMNO;
    }
  },
  methods: {
    /* 현재시간 업데이트 */
    updateTime() {
      this.intervalVeriable = setInterval(() => {
        this.currentTime = moment().tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss');
        // console.log(this.currentTime);
      }, 1000);
    }
  },
  unmounted() {
    clearInterval(this.intervalVeriable);
  },
  mounted() {

    /* mounted 후 바코드 노출 */
    JsBarcode(".barcode", this.RMNO, {width: 5.5, height:85, text: '', displayValue: false});
    this.updateTime();
    /*
     * 비동기로 룸넘버 갱신, 바코드 갱신, 전역 룸넘버 갱신
     *  - 만약 룸넘버가 없다면 이전 페이지로 이동
     *  - 서버측에서 400, 402, 403, 500 반환시 이전 페이지로 이동
     * */
    this.axios({
      method: "GET",
      url: `${process.env.VUE_APP_API_SERVER}/api/app/breakfast_service`,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
      },
      params: {
        CUST_ID: this.$store.state.CUST_ID,
        GSTNO: this.$store.state.GSTNO
      }
    }).then(res => {
      const data = res.data;

      if (data.status === "SUCCESS") {

        if (data.result.length === 0) {
          this.$store.state.BF_R = true;
          this.$router.go(-1);
        } else {
          this.RMNO = data.result[0].RMNO;
          this.$store.state.STAY_HISTORY_DATA[0].RMNO = this.RMNO;
          JsBarcode(".barcode", this.RMNO, {width: 5.5, height:85, text: '', displayValue: false});
        }
      }
    }).catch(e => {
      if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
        this.$router.replace('/app/sign_in');
      } else if (e.response.status === 401) {
        // console.log("None data");
      }
    });
  }
}
</script>

<style scoped>
.depth-content-box{ width:100%; min-height:100vh; position:relative; background-color: #F9F9F9; }

.depth-barcode-content{ width:100%; height:calc(100vh - 290px); display:flex; align-items: center; padding:0 12px 40px 12px; }

.depth-barcode-box{ width:100%; display:flex; justify-content: center; align-items: center; flex-direction: column; padding: 35px 0 15px 0; background-color: #FFF; border-radius: 6px; }
.depth-barcode-room{ margin-bottom: 20px; padding: 7px 20px; background-color: #9C836A; border-radius: 4px; color:#FFF; font-size:16px;  }
svg{ width:100%; padding:0 14px; }
.depth-barcode-text{ width:100%; color:#808080; font-size:13px; padding:0 24px; margin-top:10px; text-align: center; }

.depth-breakfast-bottom-box{ position:absolute; width:100%; bottom:0; left:0; background-color:#9C836A; }
.depth-bottom-title{ width:100%; height:50px; border-bottom:1px solid #EDEDED; display:flex; align-items: center; color:#FFF; font-size:12px; padding:0 24px; }
.depth-bottom-sub-container{position: relative; width: 100%; display: flex; padding:20px 0;}
.depth-bottom-sub-middle-line{position: absolute; left: 50%; top:0; width: 1px; height: 100%; background: #C7BBAF; }
.depth-bottom-sub-box{ width:50%; padding: 0 24px}
.depth-bottom-sub-section-box{display: flex; flex-direction: column; margin-bottom: 15px}
.depth-bottom-sub-section{ display:flex; flex-direction: column; margin-bottom: 15px; }
.depth-bottom-sub-section-row{flex-direction: row; margin-bottom: 2px}

.depth-bottom-sub-section-title{ color:#FFF; font-size:12px; }
.depth-bottom-sub-section-content{ color:#FFF; font-size:12px; }
.depth-bottom-sub-section-etc{ color:#FFF; font-size:10px; }
.depth-bottom-sub-section-title{ width:55px; }
.depth-bottom-sub-section-value span{ color:#FFF; font-size:10px; margin-top:5px; }

.barcode-time{ width:100%; margin-top:20px; font-size:16px; color: #9C836A; text-align: center; }

</style>
