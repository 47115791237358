<template>
  <div class="admin_layout" style="display: flex; flex-direction: column;">
    <h1>404 Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>

</template>

<script>


export default {
  components:{

  },
  data(){

  },
  async created(){

  },
  async mounted(){

  },
  methods:{

  }
}
</script>


<style scoped>


h1 {
  margin-top: 60px;
  margin-left: 60px;
  margin-bottom: 20px;
  font-size: 3rem;

}

p {
  margin-left: 60px;
  font-size: 1.5rem;
}

</style>
