const languages = {
    title:{
        KO: '마이페이지',
        EN: 'My Page',
        JA: 'My Page',
        ZNTW: 'My Page',
    },
    logout:{
        KO: '로그아웃',
        EN: 'Log out',
        JA: 'ログアウト',
        ZNTW: '退出',
    },
    benefit_go:{
        KO: '혜택 보러가기',
        EN: 'Check benefits',
        JA: '特典を見る',
        ZNTW: '查看优惠',
    },
    mileage_history:{
        KO: '포인트 적립 내역',
        EN: 'Point earned',
        JA: 'マイレージ獲得履歴',
        ZNTW: '积分累积明细',
    },
    book_history:{
        KO: '예약 내역',//'호텔 예약 현황',
        EN: 'Reservations',
        JA: '予約内容',
        ZNTW: '预订记录',
    },
    customer_number:{
        KO: '고객번호',
        EN: 'Customer Number',
        JA: 'お客様番号',
        ZNTW: '顾客号码',
    },
    barcode_comment:{
        KO: 'QR 인식 시 화면 밝기를 최대로 조절해주세요',
        EN: 'Please adjust the brightness of your device to<br/>maximum intensity illumination for QR scanning.',
        JA: 'QR読み取りのために<br/>画面の明るさを最大に調節してください。',
        ZNTW: '扫描二维码时，请将屏幕亮度调至最大。',
    }

}


module.exports = languages;
