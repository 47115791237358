const languages = {
    breakfast_service_title:{
        KO: '조식서비스',
        EN: 'Complimentary Breakfast',
        JA: '朝食サービス',
        ZNTW: '早餐服务',
    },
    depth_barcode_text:{
        KO: '바코드 인식 시 화면 밝기를 최대로 조절해주세요',
        EN: 'Please adjust the brightness of your device to maximum intensity illumination for barcode scanning.',
        JA: 'バーコード読み取りのために、画面の明るさを最大に調節してください。',
        ZNTW: '扫描条形码时，请将屏幕亮度调至最大。',
    },
    depth_bottom_title: {
        KO: '조식 서비스 안내',
        EN: 'Complimentary Breakfast Info',
        JA: '朝食サービスのご案内',
        ZNTW: '早餐服务指南',
    },
    business_hour_comment_1:{
        KO: '*조식은 객실 투숙 고객만 이용 가능',
        EN: '*Complimentary Breakfast to guests only',
        JA: '*朝食は宿泊のお客様のみ利用可能',
        ZNTW: '*早餐仅供住宿的顾客享用',
    },
    business_hour_comment_2:{
        KO: '*라운지 패키지 고객만 이용 가능',
        EN: '*Available to Lounge Package guests only',
        JA: '*ラウンジパッケージのお客様のみ利用可能',
        ZNTW: '*休息区仅供套餐顾客使用',
    },
    seat_count_1:{
        KO: '240석',
        EN: '240 seats',
        JA: '240席',
        ZNTW: '240个座位',
    },
    seat_count_2:{
        KO: '(별실 52석 / 테라스 44석)',
        EN: '(Annex room 52 seats / Terrace 44 seats)',
        JA: '(別室 52席 / テラス 44 席)',
        ZNTW: '(雅间 52个座位 / 阳台 44 个座位)',
    },
    seat_count_3:{
        KO: '80석',
        EN: '80 seats',
        JA: '80席',
        ZNTW: '80个座位',
    },
}

module.exports = languages;
