const languages = {
    title:{
        KO: '객실서비스',
        EN: 'Room service',
        JA: '客室サービス',
        ZNTW: '客房服务',
    },
    description: {
        KO: '고객님의 서비스 이용 내역입니다.',
        EN: 'Please find below your service usage history.',
        JA: 'お客様のサービス利用履歴です。',
        ZNTW: '这是顾客您的服务使用明细。',
    },

};


module.exports = languages;
