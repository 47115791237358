<template>

  <!-- 쿠폰 내역 화면 컴포넌트 -->
  <div class="common_layout">

      <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="@/assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
      <div class="font1-B">{{ this.langText.check_out_title[this.langKey] }}</div>
    </div>

    <div class="step-contents">
      <div class="top-process-bar-box">
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text">{{ this.langText.minibar[this.langKey] }}</span>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-big">
          <div class="top-process-circle-small">
            <span class="top-process-text-special">{{ this.langCommonText.account_check[this.langKey] }}</span>
          </div>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text">{{this.langCommonText.payment[this.langKey]}}</span>
        </div>
        <div class="top-process-center-line"></div>
        <div class="top-process-circle-small top-process-circle-op">
          <span class="top-process-text-r">{{ this.langCommonText.complete[this.langKey] }}</span>
        </div>
      </div>

      <div class="step-summary">
        <div class="step-summary-box">{{ this.langCommonText.check_in[this.langKey] }}<span>{{ARRIVDATE}}</span></div>
        <div class="step-summary-box">{{ this.langCommonText.check_out[this.langKey] }}<span>{{DEPARTDATE}}</span></div>
        <div class="step-summary-line"></div>
        <div class="step-summary-box">{{this.langCommonText.room_number[this.langKey]}}<span>{{RMNO}} {{this.langCommonText.room[this.langKey] }}</span></div>
        <div class="step-summary-box">{{this.langText.reservation_name[this.langKey] }}<span>{{GSTNAME}}</span></div>
      </div>


      <div class="price-contents">
        <div class="price-contents-title font1-M">CHARGE</div>
        <div class="price-box font1-R"  v-for="(item, index) in chargeList" :key="index">
          <div class="price-box-info">
            {{item.name}}
            <span>{{formatOnlyDate(item.date)}}</span>
          </div>
          <div class="price-box-amt font1-M" v-if="this.langKey === 'KO'">{{String(item.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.langCommonText.currency_unit[this.langKey] }}</div>
          <div class="price-box-amt font1-M" v-else>{{this.langCommonText.currency_unit[this.langKey]}}&nbsp;{{String(item.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
        </div>
      </div>

      <div class="price-contents">
        <div class="price-contents-title-a font1-M">CREDIT</div>
        <div class="price-box font1-R"  v-for="(item, index) in payList" :key="index">
          <div class="price-box-info">
            {{item.name}}
            <span>{{formatOnlyDate(item.date)}}</span>
          </div>
          <div class="price-box-amt-a font1-M" v-if="this.langKey === 'KO'">{{String(item.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.langCommonText.currency_unit[this.langKey] }}</div>
          <div class="price-box-amt-a font1-M" v-else>{{this.langCommonText.currency_unit[this.langKey]}}&nbsp;{{String(item.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
        </div>
      </div>
    </div>

    <div class="footer-box">
      <div class="total-price">
        <div class="total-price-box">
          <div class="total-price-text">Total ({{this.langCommonText.currency_unit_en[this.langKey]}})</div>
          <div class="total-price-value font1-M" v-if="this.langKey === 'KO'">{{String(CHARGE_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.langCommonText.currency_unit[this.langKey]}} / {{String(PAY_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.langCommonText.currency_unit[this.langKey]}}</div>
          <div class="total-price-value font1-M" v-else>{{this.langCommonText.currency_unit[this.langKey]}}&nbsp;{{String(CHARGE_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} / {{this.langCommonText.currency_unit[this.langKey]}}&nbsp;{{String(PAY_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
        </div>
        <div class="total-price-box">
          <div class="total-price-text">Balance ({{this.langCommonText.currency_unit_en[this.langKey]}})</div>
          <div class="total-price-value font1-R" v-if="this.langKey === 'KO'">{{String(CHARGE_AMT - PAY_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.langCommonText.currency_unit[this.langKey]}}</div>
          <div class="total-price-value font1-R" v-else>{{this.langCommonText.currency_unit[this.langKey] + String(CHARGE_AMT - PAY_AMT).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
        </div>
      </div>
      <div class="btn-box">
        <div class="none-use-btn" v-on:click="$router.go(-1);">{{this.langCommonText.cancel[this.langKey]}}</div>
        <div class="use-btn" v-on:click="pgCall">{{useBtnStr}}</div>
      </div>
    </div>

  </div>

</template>
<script>

import languages from "@/modules/languages/check_out_step2";
import common from "@/modules/languages/common";

export default {

  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      GSTNO: this.$store.state.GSTNO,
      CUST_TOKEN: this.$store.state.CUST_TOKEN,
      ARRIVDATE: '',
      DEPARTDATE: '',
      GSTNAME: '',
      RMNO: null,
      PG_PRD_NM: '',
      PG_AMT: 0,
      CHARGE_AMT: 0,
      PAY_AMT: 0,
      chargeList: [],
      payList: [],
      useBtnStr: '결제',
      returnPgData: null
    }
  },
  // mounted() {
  //   const URL = `${process.env.VUE_APP_PG_MODULE_LINK}`;
  //   this.loadScript(URL);
  // },
  created() {

    const queryObject = this.$route.query;
    const storage = localStorage.getItem('tempData');

    if (storage && storage.length > 0) {

      const storageObj = JSON.parse(storage);

      this.$store.state.ADMIN_TOKEN = storageObj.ADMIN_TOKEN;
      this.$store.state.CUST_TOKEN = storageObj.CUST_TOKEN;
      this.$store.state.CUST_NO = storageObj.CUST_NO;
      this.$store.state.CUST_NM = storageObj.CUST_NM;
      this.$store.state.CUST_PHONE = storageObj.CUST_PHONE;
      this.$store.state.CUST_EMAIL = storageObj.CUST_EMAIL;
      this.$store.state.CUST_ID = storageObj.CUST_ID;
      this.$store.state.GSTNO = storageObj.GSTNO;
      this.$store.state.STAY_HISTORY_DATA = storageObj.STAY_HISTORY_DATA;
      this.$store.state.ROOM_SERVICE_DATA = storageObj.ROOM_SERVICE_DATA;
      this.$store.state.MILEAGE_DATA = storageObj.MILEAGE_DATA;
      this.$store.state.MEMBERSHIP_DATA = storageObj.MEMBERSHIP_DATA;
      this.$store.state.BANNER_IMAGE_DATA = storageObj.BANNER_IMAGE_DATA;
      this.$store.state.USER_AGENT = storageObj.USER_AGENT;
      this.$store.state.SELECT_ROOM = storageObj.SELECT_ROOM;
      this.$store.state.BF_R = storageObj.BF_R;
      this.$store.state.SH_R = storageObj.SH_R;
      this.$store.state.RS_R = storageObj.RS_R;
      this.$store.state.CK_R = storageObj.CK_R;
      this.$store.state.ISSIGNOUT = storageObj.ISSIGNOUT;
      this.$store.state.GUIDESTATE = storageObj.GUIDESTATE;

      this.GSTNO = this.$store.state.GSTNO;
      this.CUST_TOKEN = this.$store.state.CUST_TOKEN;
    }


    if (queryObject && queryObject.pgData) {
      this.returnPgData = queryObject.pgData;
    }

    if (!this.$store.state.GSTNO) {
      this.$router.replace('/app/home');
    }

    this.$emit('c-loadingStart');

    if (this.returnPgData) {

      this.axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_SERVER}/api/app/billCheckout`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
        },
        data: {
          PG_DATA: this.returnPgData
        }
      }).then(async res => {
        const data = await res.data;

        // console.log(data);

        this.$emit('c-loadingFinish');

        if (data.status === "SUCCESS") {

          this.$router.replace('/app/check_out_step3');

        } else {
          this.$router.replace({ path: '/app/home', query: { checkOutFail: "true" } });
        }

      }).catch(error => {
        console.error(error);

        setTimeout(() => {
          this.$emit('c-loadingFinish');

          this.$router.replace({ path: '/app/home', query: { checkOutFail: "true" } });

        }, 300);

      });

    } else {

      this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_SERVER}/api/app/folio_list`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
        },
        params: {
          GSTNO: this.$store.state.GSTNO
        }
      }).then(async res => {
        const data = await res.data;

        // console.log(data);

        if (data.status === "SUCCESS") {

          this.ARRIVDATE = this.formatDate(data.result[0].RESERVATION[0].ARRIVDATE);
          this.DEPARTDATE = this.formatDate(data.result[0].RESERVATION[0].DEPARTDATE);
          this.GSTNAME = data.result[0].RESERVATION[0].GSTNAME;
          this.RMNO = data.result[0].RESERVATION[0].RMNO;

          let orderGoodsCount = -1;

          for (const i of data.result[0].FOLIO_LIST) {
            if (i.CHARGE > 0) {
              this.CHARGE_AMT += i.CHARGE;
              this.chargeList.push({
                date: i.FDATE,
                name: i.POSTGRPDESC,
                price: i.CHARGE
              });

              if (orderGoodsCount === -1) {
                this.PG_PRD_NM = i.POSTGRPDESC;
              }
              orderGoodsCount++;

            }
            if (i.CREDIT > 0) {
              this.PAY_AMT += i.CREDIT;
              this.payList.push({
                date: i.FDATE,
                name: i.POSTGRPDESC,
                price: i.CREDIT
              });
            }
          }

          this.PG_AMT = this.CHARGE_AMT - this.PAY_AMT;

          if (this.PG_AMT > 0) {
            this.useBtnStr = '결제';
          } else {
            this.useBtnStr = '체크아웃';
          }

          if (orderGoodsCount > 0) {
            this.PG_PRD_NM += ` 외 ${orderGoodsCount}개`;
          }

          this.$emit('c-loadingFinish');

        } else {
          // alert('사용내역 조회를 실패했습니다.');
          alert(this.langCommonText.alert_comment_28[this.langKey]);
          this.$router.replace('/app/home');
        }

      }).catch(error => {
        console.error(error);

        setTimeout(() => {
          this.$emit('c-loadingFinish');

          // alert('사용내역 조회를 실패했습니다.');
          alert(this.langCommonText.alert_comment_28[this.langKey]);
          this.$router.replace('/app/home');

        }, 300);
      });

    }

  },
  methods:{

    getOrderNo() {
      const now = new Date();
      const year = now.getFullYear().toString().slice(-2).padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${this.GSTNO}${year}${month}${day}${hours}${minutes}${seconds}`;
    },

    formatDate(inputDate) {
      const year = inputDate.substring(0, 4);
      const month = inputDate.substring(4, 6);
      const day = inputDate.substring(6, 8);
      const date = new Date(`${year}-${month}-${day}`);

      const weekdays = this.langCommonText.weekdays[this.langKey];

      const dayOfWeek = date.getDay();
      return `${year}.${month}.${day}(${weekdays[dayOfWeek]})`;
    },

    formatOnlyDate(inputDate) {
      const year = inputDate.substring(0, 4);
      const month = inputDate.substring(4, 6);
      const day = inputDate.substring(6, 8);
      return `${year}.${month}.${day}`;
    },
    easyPayLang(){
      if(this.langKey === 'KO') return 'KOR'
      if(this.langKey === 'EN') return 'ENG'
      if(this.langKey === 'JA') return 'JPN'
      if(this.langKey === 'ZNTW') return 'CHN'
    },

    /* PG 인증 창 호출 */
    async pgCall() {

      localStorage.setItem('tempData', JSON.stringify({
        ADMIN_TOKEN: this.$store.state.ADMIN_TOKEN,
        CUST_TOKEN: this.$store.state.CUST_TOKEN,
        CUST_NO: this.$store.state.CUST_NO,
        CUST_NM: this.$store.state.CUST_NM,
        CUST_PHONE: this.$store.state.CUST_PHONE,
        CUST_EMAIL: this.$store.state.CUST_EMAIL,
        CUST_ID: this.$store.state.CUST_ID,
        GSTNO: this.$store.state.GSTNO,
        STAY_HISTORY_DATA: this.$store.state.STAY_HISTORY_DATA,
        ROOM_SERVICE_DATA: this.$store.state.ROOM_SERVICE_DATA,
        MILEAGE_DATA: this.$store.state.MILEAGE_DATA,
        MEMBERSHIP_DATA: this.$store.state.MEMBERSHIP_DATA,
        BANNER_IMAGE_DATA: this.$store.state.BANNER_IMAGE_DATA,
        USER_AGENT: this.$store.state.USER_AGENT,
        SELECT_ROOM: this.$store.state.SELECT_ROOM,
        BF_R: this.$store.state.BF_R,
        SH_R: this.$store.state.SH_R,
        RS_R: this.$store.state.RS_R,
        CK_R: this.$store.state.CK_R,
        ISSIGNOUT: this.$store.state.ISSIGNOUT,
        GUIDESTATE: this.$store.state.GUIDESTATE,
      }));

      if (this.PG_AMT > 0) {
        try{

          const res = await this.axios({
            method: "POST",
            url: `${process.env.VUE_APP_PG_FORM}`,
            data: {
              mallId: `${process.env.VUE_APP_PG_MALL_ID}`,
              payMethodTypeCode: '11',
              currency: '00',
              amount: this.PG_AMT,
              clientTypeCode: '00',
              returnUrl: `${process.env.VUE_APP_API_SERVER}/api/app/pgReturn`,
              deviceTypeCode: 'mobile',
              shopOrderNo: this.getOrderNo(),
              orderInfo: {
                goodsName: this.PG_PRD_NM
              },
              payMethodInfo: {
                cardMethodInfo: {
                  displayArea: ['CARD']
                }
              },
              shopValueInfo: {
                value1: this.GSTNO
              },
              langFlag: this.easyPayLang(this.langKey),
            }
          });

          // console.log(res);

          if (res.status === 200 && res.data.resCd === '0000') {
            location.href = res.data.authPageUrl;
          } else {
            // alert('결제창 생성에 실패했습니다. 잠시 후에 다시 시도해주세요');
            alert(this.langCommonText.alert_comment_29[this.langKey]);
          }

        }
        catch (e) {
          // alert('서버가 불안정합니다. 잠시 후에 다시 시도해주세요');
          alert(this.langCommonText.alert_comment_30[this.langKey]);
          // console.log(e);
        }
      } else {

        this.$emit('c-loadingStart');

        try{

          const res = await this.axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_SERVER}/api/app/directCheckout`,
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
            },
            data: {
              GSTNO: this.GSTNO
            }
          });

          // console.log(res);

          if (res.data.status === "SUCCESS") {
            this.$emit('c-loadingFinish');
            this.$router.push('/app/check_out_step3');
          } else {

            setTimeout(() => {
              this.$emit('c-loadingFinish');
            }, 300);

            // alert('체크아웃 처리에 실패했습니다.\r다시 시도해주세요');
            alert(this.langCommonText.alert_comment_31[this.langKey]);

          }

        }
        catch (e) {

          setTimeout(() => {
            this.$emit('c-loadingFinish');
          }, 300);

          // alert('서버가 불안정합니다. 잠시 후에 다시 시도해주세요');
          alert(this.langCommonText.alert_comment_30[this.langKey]);
          console.log(e);
        }

      }

    }

  }
}
</script>

<style scoped>

  .step-contents { width: 100%; padding: 0 15px 185px 15px; box-sizing: border-box; }

  .top-process-bar-box { width:100%; display: flex; align-items: center; box-sizing: border-box; padding: 10px 15px 30px 15px; }
  .top-process-circle-big { background-color: #CDC1B4; width: 15px; height: 15px; border-radius: 8px; display: flex; justify-content: center; align-items: center; }
  .top-process-circle-small { background-color: #9C836A; width: 7px; height: 7px; border-radius: 4px; position:relative; }
  .top-process-circle-op {  opacity: .5; }
  .top-process-text { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 100px; color: #808080; }
  .top-process-text-r { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 30px; color: #808080; }
  .top-process-text-special { position: absolute; bottom: -25px; left:-2px; font-size: 12px;  display: flex; justify-content: left; width: 100px; color: #9C836A; }
  .top-process-center-line { border-bottom: 1px #EDEDED dotted; width: calc(33% - 16px); margin: 0 2px; }
  .step-summary { width: 100%; border-radius: 8px; background-color: #F9F9F9; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; font-size: 12px; color: #9C836A; margin: 10px 0; }

  .step-summary-box { width:50%; padding: 10px 0; display: flex; justify-content: center; align-items: center; flex-direction: column; }
  .step-summary-box span { color: #808080; margin-top: 8px; }
  .step-summary-line { width: calc(100% - 40px); height:0; margin-left: 20px; border-bottom: 1px #EDEDED solid; }

  .price-contents { background-color: #FFFFFF; border: 1px #EDEDED solid; border-radius: 16px; padding: 20px 20px 10px 20px; box-sizing: border-box; margin: 12px 0 6px 0; }
  .price-contents-title-a { color: #7E7E86; margin-bottom: 15px; }
  .price-contents-title { color: #9C836A; margin-bottom: 15px; }
  .price-box { width: 100%; display: flex; justify-content: space-between; margin-bottom: 18px; }
  .price-box-info { display: flex; flex-direction: column; color: #222222; font-size: 14px; }
  .price-box-info span { font-size: 12px; color: #808080; margin-top: 4px; }
  .price-box-amt { font-size: 14px; color: #9C836A; min-width: 78px; text-align: right; }
  .price-box-amt-a { font-size: 14px; color: #7E7E86; min-width: 78px; text-align: right; }

  .footer-box { display: flex; position: fixed; bottom:0; left:0; width: 100%; flex-direction: column; }
  .total-price { width: 100%; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; padding: 13px; background-color: #4D4843; }
  .total-price-box { width: 100%; display: flex; justify-content: space-between; align-items: center; margin: 7px 0; }
  .total-price-text { color: #A6A6A6; font-size: 12px; }
  .total-price-value { color: #FFFFFF; font-size: 16px; text-align: right; }
  .btn-box { width: 100%; display: flex; font-size: 14px; }
  .none-use-btn { width: 50%; display: flex; justify-content: center; align-items: center; height: 60px; color: #9C836A; background-color: #FFFFFF; }
  .use-btn { width: 50%; display: flex; justify-content: center; align-items: center; height: 60px; color: #FFFFFF; background-color: #9C836A; }

</style>

