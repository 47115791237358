<template>
    <div class="admin_layout">

        <!--좌측 탭 메뉴-->
        <div class="admin_layout_menu">
            <div class="admin_layout_menu_img_box">
                <img class="admin_layout_menu_img" src="@/assets/images/admin_logo_white.png">
            </div>

            <div @click="doorlock_list" class="admin_layout_menu_item_box" style="cursor: pointer">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/doorlock_list_icon.svg">
                    <span class="admin_layout_menu_item_span" > 도어락 리스트</span>
                </div>
            </div>

            <div class="admin_layout_menu_item_box" style="background-color: #9C836A;">
                <div class="admin_layout_menu_item">
                    <img class="admin_layout_menu_item_img" src="@/assets/images/register_icon.svg">
                    <span class="admin_layout_menu_item_span" > 도어락 등록</span>
                </div>
            </div>

<!--            <div @click="key_issue_list" class="admin_layout_menu_item_box" style="cursor: pointer">-->
<!--                <div class="admin_layout_menu_item">-->
<!--                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_issue_icon.svg">-->
<!--                    <span class="admin_layout_menu_item_span" > 모바일 키 발급이력</span>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div @click="key_usage_list" class="admin_layout_menu_item_box" style="cursor: pointer">-->
<!--                <div class="admin_layout_menu_item">-->
<!--                    <img class="admin_layout_menu_item_img" src="@/assets/images/key_usage_icon.svg">-->
<!--                    <span class="admin_layout_menu_item_span" > 이용현황 리스트</span>-->
<!--                </div>-->
<!--            </div>-->
        </div>



        <!--컨텐츠 박스-->
        <div class="admin_layout_content_box">

                <div class="admin_layout_content">

                    <div class="admin_layout_logout_box">
                        <div @click="logout" class="admin_layout_logout_button">로그아웃</div>
                    </div>

                    <div class="admin_layout_title_box">
                        도어락 등록
                    </div>



                    <div class="admin_layout_register_box">

                        <div class="admin_layout_register_title_box">일반 정보</div>

                        <div class="admin_layout_register_info_box">
                            <div class="admin_layout_register_info_title" style="border: 1px solid #D8D8D8;">
                                객실 번호
                            </div>
                            <div class="admin_layout_register_info_input">

                                <input class="admin_layout_register_input_number" type="text" v-model="rmno_data" placeholder="ex. 501">

                            </div>


                            <div class="admin_layout_register_info_title">
                                도어락 타입
                            </div>

                            <div class="admin_layout_register_info_select">
                                <div class="admin-select" style="width:180px;">
                                    <select id="doorlock_data">
                                        <option value="ROOM">객실</option>
                                        <option value="PUBLIC">공용도어</option>
                                    </select>
                                </div>
                            </div>

                            <div class="admin_layout_register_info_title">
                                빌딩 타입
                            </div>

                            <div class="admin_layout_register_info_select">
                                <div class="admin-select" style="width:180px;">
                                    <select id="building_data">
                                        <option value="ORG">본관</option>
                                        <option value="NEW">신관</option>
                                    </select>
                                </div>
                            </div>

                            <div class="admin_layout_register_info_title">
                                층 수
                            </div>

                            <div class="admin_layout_register_info_select">
                                <div class="admin-select" style="width:180px;">
                                    <select id="floor_data">
                                        <option value="5">5층</option>
                                        <option value="6">6층</option>
                                        <option value="7">7층</option>
                                        <option value="8">8층</option>
                                        <option value="9">9층</option>
                                        <option value="10">10층</option>
                                        <option value="11">11층</option>
                                        <option value="12">12층</option>
                                        <option value="14">14층</option>
                                        <option value="15">15층</option>
                                        <option value="16">16층</option>
                                        <option value="17">17층</option>
                                        <option value="18">18층</option>
                                    </select>
                                </div>
                            </div>




                        </div>

                        <div class="admin_layout_register_title_box" style="margin-top:50px;">블루투스 통신 정보</div>

                        <div class="admin_layout_register_info_box">
                            <div class="admin_layout_register_info_title" style="border: 1px solid #D8D8D8;">
                                기기 이름
                            </div>
                            <div class="admin_layout_register_info_input">
                                <input class="admin_layout_detail_input_number" type="number"  v-model="lockid_data" placeholder="ex. 104399" >
                            </div>
                            <div @click="get_wydata" class="admin_layout_register_info_button">
                                정보 불러오기
                            </div>
                        </div>

                        <div class="admin_layout_register_info_box">
                            <div class="admin_layout_register_info_title" style="width:100px; border: 1px solid #D8D8D8;">
                                agent ID
                            </div>
                            <div class="admin_layout_register_info_input" style="width: 130px;">
                                <input class="admin_layout_detail_input_number" type="text"  v-model="agentid_data" placeholder="자동 입력" readonly>
                            </div>
                            <div class="admin_layout_register_info_title" style="width:100px;">
                                sys ID
                            </div>
                            <div class="admin_layout_register_info_input" style="width: 130px;">
                                <input class="admin_layout_detail_input_number" type="text"  v-model="sysid_data" placeholder="자동 입력" readonly>
                            </div>
                            <div class="admin_layout_register_info_title" style="width:100px;">
                                rgn ID
                            </div>
                            <div class="admin_layout_register_info_input" style="width: 130px;">
                                <input class="admin_layout_detail_input_number" type="text"  v-model="rgnid_data" placeholder="자동 입력" readonly>
                            </div>
                            <div class="admin_layout_register_info_title" style="width:100px;">
                                subrgn ID
                            </div>
                            <div class="admin_layout_register_info_input" style="width: 130px;">
                                <input class="admin_layout_detail_input_number" type="text"  v-model="subrgnid_data" placeholder="자동 입력" readonly>
                            </div>
                        </div>

                        <div class="admin_layout_register_info_box">
                            <div class="admin_layout_register_info_title" style="width:100px; border: 1px solid #D8D8D8;">
                                DEV ID
                            </div>
                            <div class="admin_layout_register_info_input" style="width: 130px;">
                                <input class="admin_layout_detail_input_number" type="text"  v-model="devid_data" placeholder="자동 입력" readonly>
                            </div>
                            <div class="admin_layout_register_info_title" style="width:160px;">
                                BLE LOCALNAME
                            </div>
                            <div class="admin_layout_register_info_input" style="width: 190px;">
                                <input class="admin_layout_detail_input_number" type="text"  v-model="blelocalname_data" placeholder="자동 입력" readonly>
                            </div>
                            <div class="admin_layout_register_info_title" style="width:130px;">
                                Mac Address
                            </div>
                            <div class="admin_layout_register_info_input" style="width: 190px;">
                                <input class="admin_layout_detail_input_number" type="text"  v-model="macaddress_data" placeholder="자동 입력" readonly>
                            </div>

                        </div>


                        <div class="admin_layout_register_complete_button_box">
                            <span @click="doorlock_register" class="admin_layout_register_complete_button"> 등록하기 </span>
                        </div>


                    </div>

                </div>



            </div>




    </div>
</template>

<script>

    import axios from 'axios';

    export default {
        components:{

        },
        data(){
            return{

                //일반 데이터
                rmno_data : '',
                doorlock_data : '',
                building_data : '',
                floor_data : '',

                //블루투스 데이터
                lockid_data : '',
                roomid_data : '',
                roomname_data : '',
                agentid_data : '',
                sysid_data : '',
                rgnid_data : '',
                subrgnid_data : '',
                devid_data : '',
                blelocalname_data : '',
                macaddress_data : '',
            }
        },
        async created(){

            // //로그인 세션 검증
            // if(this.$session.get('admin_session') === '' || !this.$session.get('admin_session') ){
            //     alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요');
            //     this.$router.replace('/admin/login');
            // }

          // 로그인 세션 검증
          const adminSession = sessionStorage.getItem('admin_session');
          const expirationTime = sessionStorage.getItem('expiration_time');
          const now = new Date().getTime();

          // 세션이 없거나, 만료 시간이 지난 경우
          if (!adminSession || now > expirationTime) {
            alert('로그인 세션이 만료되었습니다. 다시 한번 로그인해주세요.');
            sessionStorage.removeItem('admin_session'); // 세션 제거
            sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
            this.$router.replace('/admin/login'); // 로그인 페이지로 리다이렉트
          }

        },
        async mounted(){

            ///selectjs 함수 호출
            this.select_box_js();

        },
        methods:{


            //도어락 등록하기 버튼 클릭 시
            async doorlock_register() {

                var doorlock_data_select = document.getElementById("doorlock_data");
                var building_data_select = document.getElementById("building_data");
                var floor_data_select = document.getElementById("floor_data");

                this.doorlock_data= doorlock_data_select.options[document.getElementById("doorlock_data").selectedIndex].value;
                this.building_data= building_data_select.options[document.getElementById("building_data").selectedIndex].value;
                this.floor_data= floor_data_select.options[document.getElementById("floor_data").selectedIndex].value;

                if(this.rmno_data === ''){
                    alert('객실 번호를 입력해주세요');
                }else if(this.lockid_data === ''){
                    alert('기기 이름을 입력해주세요');
                }else if(this.roomid_data === '' || this.macaddress_data === '') {
                    alert('블루투스 정보를 불러와주세요');
                }else if(this.doorlock_data === 'ROOM' && this.rmno_data.toString() !== this.roomname_data.toString()){
                    alert('객실번호와 일치하지 않는 기기명입니다\n객실 번호와 기기명을 확인해주세요');
                }else{

                    try{

                        const res = await this.axios({
                            method: "POST",
                            url: `${process.env.VUE_APP_API_SERVER}/api/admin/doorlock_register`,
                            headers: {
                                "Content-Type": "application/json",
                                // 'Authorization': `Bearer ${this.$session.get('admin_session')}`
                                'Authorization': `Bearer ${sessionStorage.getItem('admin_session')}`
                            },
                            data: {

                                //일반 데이터
                                rmno : this.rmno_data,
                                doorlock : this.doorlock_data,
                                building : this.building_data,
                                floor : this.floor_data,
                                //도어락 데이터
                                lockid : this.lockid_data,
                                roomid : this.roomid_data,
                                roomname : this.roomname_data,
                                agentid : this.agentid_data,
                                sysid : this.sysid_data,
                                rgnid : this.rgnid_data,
                                subrgnid : this.subrgnid_data,
                                devid : this.devid_data,
                                blelocalname : this.blelocalname_data,
                                macaddress : this.macaddress_data,
                            }

                        });

                        const data = await res.data;

                        if(data.status === "SUCCESS"){

                            if(data.result === 'EXIST'){
                                alert('이미 존재하는 객실입니다');
                            }else{
                                alert('도어락 등록을 완료하였습니다');
                                this.$router.push('/admin/doorlock_info?BM_RMNO='+this.rmno_data);
                            }

                        }else{
                            alert('도어락 등록에 실패하였습니다');
                        }


                    }catch (e) {

                        console.log(e)

                    }
                }
            },

            //도어락 데이터 불러오기 클릭 시, 우연 RESTAPI 접근
            async get_wydata() {

                if(this.lockid_data === ''){
                    alert('기기 이름을 입력해주세요')
                }else{

                    try{

                        const wy_res = await axios({
                            method: "POST",
                            url: 'https://api.kdware.xyz/hapi/v1.1/fetchOpenFormatData',
                            data:{
                                "name": "paradise",
                                "passwd": "033903",
                                "byID" : this.lockid_data
                            }
                        });

                        const wy_data = await wy_res.data;

                        const DL = wy_data['deviceList'][0];

                        if(wy_data['deviceList'].length!==0){ // 데이터가 있을 경우

                            this.roomid_data = DL['roomID'];
                            this.roomname_data = DL['roomName'];
                            this.agentid_data = DL['agentID'];
                            this.sysid_data = DL['sysID'];
                            this.rgnid_data = DL['regionID'];
                            this.subrgnid_data = DL['subrgnID'];
                            this.devid_data = DL['devID'];
                            this.blelocalname_data = DL['bleLocalName'];
                            this.macaddress_data = DL['macID'].toUpperCase()

                        }else{
                            alert('올바른 기기명을 입력해주세요')
                        }

                    }catch (e) {
                        console.log(e);

                    }
                }



            },

            //로그아웃하기
            logout() {
                var logout_confirm = confirm("정말로 로그아웃 하시겠습니까?");
                if(logout_confirm == true){
                    // 토큰, 세션 삭제 후 로그인 페이지로 이동
                    // this.$session.set('admin_session', '');
                    sessionStorage.removeItem('admin_session'); // 세션 제거
                    sessionStorage.removeItem('expiration_time'); // 만료 시간 제거
                    this.$router.replace('/admin/login');
                }

            },

            //페이지 이동함수
            doorlock_list(){
                this.$router.push('/admin/doorlock_list');
            },

            key_issue_list(){
                this.$router.push('/admin/key_issue_list');
            },
            key_usage_list(){
                this.$router.push('/admin/key_usage_list');
            },

            //selectjs 함수
            select_box_js() {
                var x, i, j, l, ll, selElmnt, a, b, c;
                /* Look for any elements with the class "admin-select": */
                x = document.getElementsByClassName("admin-select");
                l = x.length;
                for (i = 0; i < l; i++) {
                    selElmnt = x[i].getElementsByTagName("select")[0];
                    ll = selElmnt.length;
                    /* For each element, create a new DIV that will act as the selected item: */

                    if(document.getElementsByClassName("admin-select-selected")[i]){
                        document.getElementsByClassName("admin-select-selected")[i].remove();
                    }
                    if(document.getElementsByClassName("admin-select-items admin-select-hide")[i]){
                        document.getElementsByClassName("admin-select-items admin-select-hide")[i].remove();
                    }
                    a = document.createElement("DIV");
                    a.setAttribute("class", "admin-select-selected");
                    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                    x[i].appendChild(a);
                    /* For each element, create a new DIV that will contain the option list: */
                    b = document.createElement("DIV");
                    b.setAttribute("class", "admin-select-items admin-select-hide");

                    for (j = 0; j < ll; j++) {
                        /* For each option in the original select element,
                        create a new DIV that will act as an option item: */
                        c = document.createElement("DIV");
                        c.innerHTML = selElmnt.options[j].innerHTML;
                        c.addEventListener("click", function (e) {
                            /* When an item is clicked, update the original select box,
                            and the selected item: */
                            e.stopPropagation();
                            var y, i, k, s, h, sl, yl;
                            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                            sl = s.length;
                            h = this.parentNode.previousSibling;
                            for (i = 0; i < sl; i++) {
                                if (s.options[i].innerHTML == this.innerHTML) {
                                    s.selectedIndex = i;
                                    h.innerHTML = this.innerHTML;
                                    y = this.parentNode.getElementsByClassName("same-as-selected");
                                    yl = y.length;
                                    for (k = 0; k < yl; k++) {
                                        y[k].removeAttribute("class");
                                    }
                                    this.setAttribute("class", "same-as-selected");
                                    break;
                                }
                            }
                            h.click();
                        });
                        b.appendChild(c);
                    }

                    x[i].appendChild(b);

                    a.addEventListener("click", function (e) {
                        /* When the select box is clicked, close any other select boxes,
                        and open/close the current select box: */
                        e.stopPropagation();
                        closeAllSelect(this);
                        this.nextSibling.classList.toggle("admin-select-hide");
                        this.classList.toggle("select-arrow-active");
                    });
                }

                function closeAllSelect(elmnt) {
                    /* A function that will close all select boxes in the document,
                    except the current select box: */
                    var x, y, i, xl, yl, arrNo = [];
                    x = document.getElementsByClassName("admin-select-items");
                    y = document.getElementsByClassName("admin-select-selected");
                    xl = x.length;
                    yl = y.length;
                    for (i = 0; i < yl; i++) {
                        if (elmnt == y[i]) {
                            arrNo.push(i)
                        } else {
                            y[i].classList.remove("select-arrow-active");
                        }
                    }
                    for (i = 0; i < xl; i++) {
                        if (arrNo.indexOf(i)) {
                            x[i].classList.add("admin-select-hide");
                        }
                    }
                }

                /* If the user clicks anywhere outside the select box,
                then close all select boxes: */
                document.addEventListener("click", closeAllSelect);
            }
        }
    }
</script>


<style>
    .admin-select-selected {background-color: white;border: none;}
    .admin_layout_register_input_number{width: 100%; height: 50px; line-height:54px; padding: 5px 15px; font-size: 16px; font-family: AppleM; border: none;outline:none;border-radius: 0px 6px 6px 0px;}
    .admin_layout_register_box{display:flex; flex-direction:column; margin-top:40px; padding: 32px 65px; width: 100%;background-color:white;border-radius: 6px;border: 1px solid #EDEDED;}
    .admin_layout_register_title_box{font-size: 24px; font-family: AppleB}
    .admin_layout_register_info_box{display:flex; flex-direction:row; margin-top: 24px; width: 100%; height: 58px;}
    .admin_layout_register_info_title{display:flex; justify-content:center;width: 130px; height: 52px; line-height: 52px;background-color: #EDEDED; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8; color: #9C836A; font-family: AppleM; font-size: 16px;}
    .admin_layout_register_info_input{display:flex; justify-content:center;width: 180px; height: 52px; line-height: 52px;background-color: white; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8;  font-family: AppleM; font-size: 16px;}
    .admin_layout_register_info_select{display:flex; justify-content:center;width: 180px; height: 52px; background-color: white; border-top: 1px solid #D8D8D8; border-right: 1px solid #D8D8D8; border-bottom: 1px solid #D8D8D8;  font-family: AppleM; font-size: 16px;}
    .admin_layout_register_info_button{display:flex; justify-content:center;margin-left:30px; width: 120px; height: 50px; line-height: 50px; color: white; font-family: AppleM; font-size: 14px; background-color: #4D4843; cursor: pointer;}
    .admin_layout_register_complete_button_box{display:flex; justify-content:center;margin-top:100px;width: 100%; }
    .admin_layout_register_complete_button{display:flex; justify-content:center;width: 180px; height: 50px; line-height:50px; background-color: #9C836A; color: white; font-size: 16px; font-family: AppleM;border-radius: 6px; cursor: pointer }

</style>
