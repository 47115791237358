<template>
  <div class="common_layout">

    <div class="language-content-box">

      <!--   상단 뒤로가기   -->
      <div class="header">
        <img alt="뒤로가기" src="../../assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
        <div class="font1-B">{{ this.langText.languages_title[this.langKey] }}</div>
      </div>

      <div class="language-set-list">
        <div class="language-set-item" :key="index" v-for="(item, index) in this.langList" v-on:click="langSetBtn(index)">
          <div :class="selectIndex !== index ? 'language-set-select-box' : 'language-set-select-box language-set-selected-box'">
            <img src="@/assets/icon/white_check.svg" alt="">
          </div>
          <span>{{item}}</span>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import languages from '../../modules/languages/languages_set'

export default {

  data() {
    return {
      langText: languages,
      langKey: this.$store.state.lang,
      langList: ['한국어', 'English', '日本語', '汉文'],
      selectIndex: 0,
    }
  },
  created() {

    if(this.langKey === 'KO'){
      this.selectIndex = 0;
    } else if (this.langKey === 'EN'){
      this.selectIndex = 1;
    } else if (this.langKey === 'JA'){
      this.selectIndex = 2;
    } else if (this.langKey === 'ZNTW'){
      this.selectIndex = 3;
    }

  },
  mounted() {

  },
  methods:{
    /**
     * 언어 세팅을 할경우
     * 1. 글로벌 변수인 store.state.lang 값을 변경해준다.
     * 2. seesion 값을 변경해준다. -> 재접속시 store.state.lang 값 재 설정을 위해서
     */
    langSetBtn(index){
      this.selectIndex = index;
      if(index === 0){
        this.$store.state.lang = 'KO'
      } else if (index === 1){
        this.$store.state.lang = 'EN'
      } else if (index === 2){
        this.$store.state.lang = 'JA'
      } else if (index === 3){
        this.$store.state.lang = 'ZNTW'
      }
      this.langKey = this.$store.state.lang;

      // axios default 언어 세팅
      this.axios.defaults.headers.common['Accept-Language'] = this.langKey;

      // RN으로 언어세팅 데이터 전달
      try{
        if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
            this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
          window.ReactNativeWebView.postMessage(JSON.stringify({mode: "langSet", lang: this.$store.state.lang}));
        }
      }catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>
.language-content-box{ width:100%; min-height:100vh; position:relative; background-color: #F9F9F9; }
.language-set-list{ width: calc(100%); padding: 25px 0; display: flex; flex-direction: column; }
.language-set-item{padding: 25px; display: flex; flex-direction: row; align-items: center; gap: 16px; border-bottom: 1px solid #C7BBAF;}
.language-set-select-box{width:16px; height: 16px; border-radius: 2px;border: 1px solid #939393; display: flex; align-items: center; justify-content: center}
.language-set-selected-box{background: #9C836A}
</style>
