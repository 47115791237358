const languages = {
    weekdays:{
        KO: ['일', '월', '화', '수', '목', '금', '토'],
        EN: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
        JA: ['日', '月', '火', '水', '木', '金', '土'],
        ZNTW: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],

    },
    main_building: {
        KO: '본관',
        EN: 'Main Building',
        JA: '本館',
        ZNTW: '主楼',
    },
    new_building: {
        KO: '신관',
        EN: 'Annex Building',
        JA: '新館',
        ZNTW: '附楼',
    },
    refresh: {
        KO: '새로고침',
        EN: 'Refresh',
        JA: 'リロード',
        ZNTW: '刷新',
    },
    reservation:{
        KO: '예약',
        EN: 'Reservation',
        JA: '予約',
        ZNTW: '预订',
    },
    reservation_number:{
        KO: '예약번호',
        EN: 'Reservation Number',
        JA: '予約番号',
        ZNTW: '预订号码',
    },
    check_in:{
        KO: '체크인',
        EN: 'Check-in',
        JA: 'チェックイン',
        ZNTW: '報到',
    },
    check_out:{
        KO: '체크아웃',
        EN: 'Check-out',
        JA: 'チェックアウト',
        ZNTW: '退房',
    },
    confirm:{
        KO: '확인',
        EN: 'OK',
        JA: '確認',
        ZNTW: '确定',
    },
    cancel:{
        KO: '취소',
        EN: 'Cancelled',
        JA: 'キャンセル',
        ZNTW: '取消',
    },
    room_number:{
        KO: '객실번호',
        EN: 'Room number',
        JA: '部屋番号',
        ZNTW: '客房号码',
    },
    room:{
        KO: '호',
        EN: 'Room',
        JA: '号',
        ZNTW: '号',
    },
    business_hour:{
        KO: '이용시간',
        EN: 'Operation hours',
        JA: '利用時間',
        ZNTW: '使用时间',
    },
    location:{
        KO: '위치',
        EN: 'Location',
        JA: '場所',
        ZNTW: '位置',
    },
    seat:{
        KO: '좌석수',
        EN: 'Number of seats',
        JA: '座席数',
        ZNTW: '座位数',
    },
    contact:{
        KO: '연락처',
        EN: 'Contact',
        JA: '連絡先',
        ZNTW: '联系方式',
    },
    account_check:{
        KO: '금액조회',
        EN: 'Check the price.',
        JA: '後払い決済の照会',
        ZNTW: '查询后付金额',
    },
    payment: {
        KO: '결제',
        EN: 'Payment',
        JA: '決済',
        ZNTW: '付款',
    },
    complete: {
        KO: '완료',
        EN: 'Used',
        JA: '完了',
        ZNTW: '已使用',
    },
    currency_unit:{
        KO: '원',
        EN: 'KRW',
        JA: 'ウォン',
        ZNTW: '韩元',
    },
    currency_unit_en:{
        KO: 'KRW',
        EN: 'KRW',
        JA: 'ウォン',
        ZNTW: '韩元',
    },
    use:{
        KO: '사용',
        EN: 'Used',
        JA: '加算',
        ZNTW: '使用',
    },
    not_use:{
        KO: '미사용',
        EN: 'Available',
        JA: '未使用',
        ZNTW: '未使用',
    },
    select_complete:{
        KO: '선택완료',
        EN: 'Selection finished',
        JA: '選択完了',
        ZNTW: '选择完成',
    },
    able_use:{
        KO: '사용가능',
        EN: 'Available',
        JA: '使用可能',
        ZNTW: '可用',
    },
    expiration_use:{
        KO: '사용만료',
        EN: 'Expired',
        JA: '使用期間満了',
        ZNTW: '使用期满',
    },
    notice:{
        KO: '안내사항', //'유의사항',
        EN: 'Information',
        JA: ' 案内事項',
        ZNTW: '敬請留意',
    },
    re_open:{
        KO: '다시 열기',
        EN: 'Open again',
        JA: 'もう一度開く',
        ZNTW: '重新打开',
    },
    paradian_point:{
        KO: '사용가능',
        EN: 'Valid Points',
        JA: '使用可能',
        ZNTW: '可用积分',
    },
    total_collect_point:{
        KO: '적립', //'총 적립 포인트',
        EN: 'Accrued',
        JA: '積立',
        ZNTW: '加算',
    },
    will_disappear_point: {
        KO: '다음달 소멸 예정', //'소멸 예정 포인트',
        EN: 'Points Expiring Next Month',
        JA: '来月消滅予定',
        ZNTW: '次月到期积分',
    },
    can_use_point:{
        KO: '사용', //'사용 포인트',
        EN: 'Used',
        JA: '加算',
        ZNTW: '使用',
    },
    register:{
        KO: '등록하기',
        EN: 'Register',
        JA: '登録',
        ZNTW: '登记',
    },
    service_name: {
        KO: '서비스명',
        EN: 'Name of service',
        JA: 'サービス名',
        ZNTW: '服务名称',
    },
    provide:{
        KO: '제공',
        EN: 'Available',
        JA: '提供',
        ZNTW: '提供',
    },
    gun:{
        KO: '건',
        EN: 'counts',
        JA: '件',
        ZNTW: '条',
    },
    old:{
        KO: '성인',
        EN: 'Adult',
        JA: '大人',
        ZNTW: '成人',
    },
    child:{
        KO: '어린이',
        EN: 'Child',
        JA: '子供',
        ZNTW: '儿童',
    },
    baby:{
        KO: '유아',
        EN: 'Infant',
        JA: '乳幼児',
        ZNTW: '幼儿',
    },
    student:{
        KO: '초등학생',
        EN: 'Elementary School students',
        JA: '小学生',
        ZNTW: '小学生',
    },
    name:{
        KO: '이름',
        EN: 'Name(in English)',
        JA: '氏名(英語)',
        ZNTW: '姓名(英文)',
    },
    cellphone_number:{
        KO: '휴대폰 번호',
        EN: 'Mobile Phone',
        JA: '携帯電話番号',
        ZNTW: '手机号码',
    },
    email:{
        KO: '이메일',
        EN: 'Email',
        JA: 'メール(Email)',
        ZNTW: 'Email',
    },
    order_req:{
        KO: '요청사항',
        EN: 'Requests',
        JA: 'リクエスト',
        ZNTW: '其它要求',
    },



    // alert 공용
    alert_comment_27:{
        KO: '미니바 사용처리에 실패했습니다.',
        EN: 'Mini-bar payment was not processed.',
        JA: 'ミニバーの使用処理に失敗しました。',
        ZNTW: '迷你吧使用处理失败',
    },
    alert_comment_28:{
        KO: '사용내역 조회를 실패했습니다.',
        EN: 'We cannot show your details.',
        JA: '使用履歴の照会に失敗しました。',
        ZNTW: '使用明细查询失败',
    },
    alert_comment_29:{
        KO: '결제창 생성에 실패했습니다. 잠시 후에 다시 시도해주세요',
        EN: 'Payment popup is not created. Please try again later.',
        JA: '決済ウインドウの作成に失敗しました。 しばらくしてからもう一度お試しください',
        ZNTW: '付款窗口创建失败。请稍后重试。',
    },
    alert_comment_30:{
        KO: '서버가 불안정합니다. 잠시 후에 다시 시도해주세요',
        EN: 'Server is unstable. Please try again later.',
        JA: 'サーバーが不安定です。しばらくしてからもう一度お試しください。',
        ZNTW: '服务器不稳定。请稍后重试。',
    },
    alert_comment_31:{
        KO: '체크아웃 처리에 실패했습니다.\r다시 시도해주세요',
        EN: 'Checkout failed.\rPlease try again.',
        JA: 'チェックアウト処理に失敗しました。\rもう一度お試しください。',
        ZNTW: '退房处理失败。请重试。',
    },




};

module.exports = languages;
