const languages = {
    lang_set:{
        KO: '한국어',
        EN: 'English',
        JA: '日本語',
        ZNTW: '日本語',
    },
    id:{
        KO: '아이디',
        EN: 'ID(Email)',
        JA: 'ID(メール)',
        ZNTW: 'ID(Email)',
    },
    pw:{
        KO: '비밀번호',
        EN: 'Password',
        JA: 'パスワード(Password)',
        ZNTW: 'Password',
    },
    login_stay:{
        KO: '로그인 상태 유지',
        EN: 'Stay Signed in',
        JA: 'ログイン状態を維持',
        ZNTW: '保持登录状态',
    },
    id_pw_find:{
        KO: '아이디/비밀번호 찾기',
        EN: 'Forgot ID or Password?',
        JA: 'ID /パスワードをお忘れの場合',
        ZNTW: '找回账号/密码',
    },
    login:{
        KO: '로그인',
        EN: 'Login',
        JA: 'ログイン',
        ZNTW: '登录',
    },
    login_comment:{
        KO: '파라디안 리워드 회원에 한하여 이용 가능합니다.',
        EN: 'Limited access to Paradian Reward Members only',
        JA: 'パラディアンリワード(Paradian Reward)会員に限りご利用できます。',
        ZNTW: '仅限PARADIAN REWARD会员可用。',
    },
    sign_comment_0:{
        KO: '비회원 고객은 신규 회원가입 완료 후',
        EN: 'You can enjoy benefits',
        JA: '非会員の方は、新規会員の登録',
        ZNTW: '非会员顾客注册新会',
    },
    sign_comment_1:{ // sign_comment_0 + sign_comment_1 이랑 문장 연결
        KO: '서비스 이용이 가능 합니다.',
        EN: 'after becoming a member.',
        JA: 'を完了した後にご利用できます。',
        ZNTW: '员后即可使用服务',
    },
    sign_in:{
        KO: '회원가입',
        EN: 'Create Account',
        JA: '会員登録',
        ZNTW: '注册会员',
    },

    modal_comment_1_1:{
        KO: '잠시 후 다시 시도해주세요.',
        EN: 'Please try again later.',
        JA: 'しばらくしてからもう一度お試しください。',
        ZNTW: '请稍后重试',
    },


    modal_comment_1_0:{
        KO: '아이디 혹은 비밀번호가',
        EN: 'The username or password',
        JA: 'IDまたはパスワードが',
        ZNTW: '用户名或密',
    },
    modal_comment_2_0:{ // modal_comment_1_0 + modal_comment_2_0 이랑 문장 연결
        KO: '올바르지 않습니다.',
        EN: 'is incorrect. ',
        JA: '正しくありません',
        ZNTW: '码不正确',
    },
    modal_comment_1_2:{
        KO: '아이디 혹은 비밀번호를',
        EN: 'Please enter',
        JA: 'IDまたはパスワードを',
        ZNTW: '请输入用户',
    },
    modal_comment_2_1:{ // modal_comment_1_2 + modal_comment_2_1 이랑 문장 연결
        KO: '입력해주세요',
        EN: 'your username or password.',
        JA: '入力してください',
        ZNTW: '名或密码',
    },
    modal_comment_1_3:{
        KO: '고객님의 계정은',
        EN: 'Your account',
        JA: 'あなたのアカウントは',
        ZNTW: '您的账号处',
    },
    modal_comment_2_2:{ // modal_comment_1_3 + modal_comment_2_2 이랑 문장 연결
        KO: '휴면 계정입니다',
        EN: 'has been deactivated.',
        JA: '休眠アカウントです',
        ZNTW: '于休眠状态',
    },
    modal_comment_1_4:{
        KO: '홈페이지에서 정보를 갱신 후',
        EN: 'Please try again after updating',
        JA: 'ホームページで情報を更新してから、',
        ZNTW: '请在网站上更新信息后重试',
    },
    modal_comment_2_3:{ // modal_comment_1_4 + modal_comment_2_3 이랑 문장 연결
        KO: '다시 시도해주세요',
        EN: 'the information on the website',
        JA: 'もう一度お試しください',
        ZNTW: '', // 짧아서 추가안함
    },
}


module.exports = languages;
