const languages = {
    title: {
        KO: '예약번호 등록',
        EN: 'Register your reservation number.',
        JA: '予約番号登録',
        ZNTW: '登记预订号码',
    },
    current_reservation_number:{
        KO: '현재 예약번호',
        EN: 'Current Reservation number',
        JA: '現在の予約番号',
        ZNTW: '当前预订号码',
    },
    phone_number_4:{
        KO: '휴대전화번호 뒷 4자리',
        EN: 'Last 4 digits of your mobile number',
        JA: '携帯電話番号の下4桁',
        ZNTW: '手机尾号后四位',
    },
    register:{
        KO: '등록하기',
        EN: 'Register',
        JA: '登録',
        ZNTW: '登记',
    },
    reservation_change_info:{
        KO: '예약번호 변경 안내',
        EN: 'How to change reservation number ',
        JA: '予約番号変更のお知らせ',
        ZNTW: '预订号码变更通知',
    },
    reservation_info_0:{
        KO: '카드 키 사용을 위한 예약번호를 입력해주세요.',
        EN: 'Please enter the reservation number to use the key card.',
        JA: 'カードキー使用のために予約番号を入力してください。',
        ZNTW: '请输入使用房卡的预订号码。',
    },
    reservation_info_1:{
        KO: '사전 구매처에서 예약번호를 확인 후 등록해주세요.',
        EN: 'Please register after verifying the reservation number with your agent.',
        JA: '事前の購入先で予約番号を確認して登録してください。',
        ZNTW: '请在预订处确认预订号码后登记。',
    },
    reservation_info_2:{
        KO: '<span>예약번호 조회 불가시&nbsp;</span><span>호텔 파라다이스 부산 고객센터 (051-749-2234/2235)&nbsp;</span><span>로 문의해주세요.</span>',
        EN: '<span>If the reservation number is not found,</span><span>Please contact Customer Center of Hotel Paradise Busan</span><span>on 051-749-2234/2235.</span>',
        JA: '<span>予約番号の照会ができない時は、</span><span>パラダイスホテル釜山のカスタマーセンター</span><span>(電話051-749-2234/2235)までお問い合わせください。</span>',
        ZNTW: '<span>查询不到预订号码时，</span><span>请咨询百乐达斯釜山酒店的客服中心</span><span（051-749-2234/2235）。></span>',
    },
    modal_test_1_0:{
        KO:'예약번호 혹은 휴대전화번호',
        EN: 'Please enter the reservation number or',
        JA: '予約番号または携帯電話番号',
        ZNTW: '请重新输入预订号码或手机',
    },
    modal_test_1_1:{
        KO:'휴대전화번호가 입력되어있지 않습니다.',
        EN: 'The mobile number cannot be found.',
        JA: '携帯電話番号が入力されていません。',
        ZNTW: '未输入手机号码。',
    },
    modal_test_1_2:{
        KO:'잠시 후에 다시 시도해주세요',
        EN: 'Please try again later.',
        JA: 'しばらくしてからもう一度お試しください。',
        ZNTW: '请稍后重试',
    },
    modal_test_2_0:{
        KO:'4자리를 다시 입력해주세요',
        EN: '4-digit mobile number again. ',
        JA: 'の下4桁をもう一度入力してください。',
        ZNTW: '尾号后四位',
    },
    modal_test_2_1:{
        KO:'카운터에서 문의해주세요.',
        EN: 'Please visit the front desk.',
        JA: 'カウンターでお問い合わせください。',
        ZNTW: '请咨询前台。',
    },



}


module.exports = languages;
