<template>

  <!-- 쿠폰 내역 화면 컴포넌트 -->
  <div class="common_layout" @scroll="infiniteScroll">

    <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="@/assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
      <div class="font1-B">{{ this.langText.coupon_history_title[this.langKey] }}</div>
    </div>

    <!--   상단 메뉴   -->
    <div class="coupon-menu-box">
      <div :class="tab_idx === 0 ? 'menu-tab menu-tab-active' : 'menu-tab'" v-on:click="tabChange(0)">{{ this.langText.able_coupon[this.langKey] }}</div>
      <div :class="tab_idx === 1 ? 'menu-tab menu-tab-active' : 'menu-tab'" v-on:click="tabChange(1)">{{ this.langText.expiration_coupon[this.langKey] }}</div>
    </div>

    <div class="coupon-contents-box">

      <!--  쿠폰 종류  -->
      <div class="coupon-category-box">
        <div class="coupon-category-box-row">
          <div :class="category_idx === 0 ? 'coupon-category coupon-category-active' : 'coupon-category'" v-on:click="categoryChange(0)">
            <img src="@/assets/icon/oln_white.svg" v-if="category_idx === 0" />
            <img src="@/assets/icon/oln_grey.svg" v-else />
            <span v-html="this.langText.online[this.langKey]"/>
          </div>
          <div :class="category_idx === 1 ? 'coupon-category coupon-category-active' : 'coupon-category'" v-on:click="categoryChange(1)">
            <img src="@/assets/icon/rwd_white.svg" v-if="category_idx === 1" />
            <img src="@/assets/icon/rwd_grey.svg" v-else />
            <span v-html="this.langText.reward[this.langKey]"/>
          </div>
        </div>
        <div class="coupon-category-box-row">
          <div :class="category_idx === 2 ? 'coupon-category coupon-category-active' : 'coupon-category'" v-on:click="categoryChange(2)">
            <img src="@/assets/icon/sig_white.svg" v-if="category_idx === 2" />
            <img src="@/assets/icon/sig_grey.svg" v-else />
            <span v-html="this.langText.signature[this.langKey]"/>
          </div>
          <div :class="category_idx === 3 ? 'coupon-category coupon-category-active' : 'coupon-category'" v-on:click="categoryChange(3)">
            <img src="@/assets/icon/fit_white.svg" v-if="category_idx === 3" />
            <img src="@/assets/icon/fit_grey.svg" v-else />
            <span v-html="this.langText.fitness[this.langKey]"/>
          </div>
        </div>
      </div>

      <div class="coupon"  v-for="(item, index) in coupon_list" :key="index">
        <img src="@/assets/images/coupon_enable.png" v-if="tab_idx === 0" />
        <img src="@/assets/images/coupon_disable.png" v-else />
        <div class="coupon-info-box">
          <div class="coupon-info-label">
            <div class="font1-L" :class="tab_idx === 0 ? 'coupon-enable-back' : 'coupon-disable-back'" v-if="category_idx === 0">{{this.langText.coupon_label_0[this.langKey]}}</div>
            <div class="font1-L" :class="tab_idx === 0 ? 'coupon-enable-back' : 'coupon-disable-back'" v-if="category_idx === 1">{{this.langText.coupon_label_1[this.langKey]}}</div>
            <div class="font1-L" :class="tab_idx === 0 ? 'coupon-enable-back' : 'coupon-disable-back'" v-if="category_idx === 2">{{this.langText.coupon_label_2[this.langKey]}}</div>
            <div class="font1-L" :class="tab_idx === 0 ? 'coupon-enable-back' : 'coupon-disable-back'" v-if="category_idx === 3">{{this.langText.coupon_label_3[this.langKey]}}</div>
          </div>
          <div class="coupon-info-name font1-B" :class="tab_idx === 0 ? 'coupon-enable-color' : 'coupon-disable-color'">{{item.TKTNAME}}</div>
          <div class="coupon-info-date font1-L" :class="tab_idx === 0 ? 'coupon-enable-color' : 'coupon-disable-color'">{{formatDate(item.SALE_DATE) + '~' + formatDate(item.VALDATE)}}</div>
          <div :class="tab_idx === 0 ? 'coupon-enable-line' : 'coupon-disable-line'"></div>
          <div class="coupon-info-state font1-L" :class="tab_idx === 0 ? 'coupon-enable-color' : 'coupon-disable-color'">{{tab_idx === 0 ? this.langCommonText.able_use[this.langKey] : this.langCommonText.expiration_use[this.langKey] }}</div>
        </div>
      </div>

    </div>


    <!--  하단 고객센터 정보  -->
    <div class="coupon-footer">
      <div class="coupon-footer-title">{{this.langCommonText.notice[this.langKey] }}</div>
      <ul>
        <li v-html="this.langText.notice_comment[this.langKey]" />
      </ul>
    </div>


  </div>

</template>

<script>
import languages from '../../modules/languages/coupon_history'
import common from '../../modules/languages/common'

export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      isRunning: false,
      page: 1,
      tab_idx: 0,
      category_idx: 0,
      allCount: 0,
      coupon_list: []
    }
  },
  created() {

    this.$emit('c-loadingStart');
    this.getCouponList();

  },
  methods:{

    tabChange(idx) {
      if (idx !== this.tab_idx) {
        this.$emit('c-loadingStart');
        this.tab_idx = idx;
        this.category_idx = 0;
        this.page = 1;
        this.coupon_list = [];
        this.getCouponList();
      }
    },

    categoryChange(idx) {
      if (idx !== this.category_idx) {
        this.$emit('c-loadingStart');
        this.category_idx = idx;
        this.page = 1;
        this.coupon_list = [];
        this.getCouponList();
      }
    },

    async getCouponList() {

      if (!this.isRunning) {
        this.isRunning = true;

        let ABLE = '';
        let TYPE = '';

        if (this.tab_idx === 0) ABLE = 'USE'
        else if (this.tab_idx === 1) ABLE = 'OVER';

        if (this.category_idx === 0) TYPE = 'ONLINE';
        else if (this.category_idx === 1) TYPE = 'REWARD';
        else if (this.category_idx === 2) TYPE = 'SIGNATURE';
        else if (this.category_idx === 3) TYPE = 'FITNESS';

        this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_SERVER}/api/app/coupon_list`,
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
          },
          params: {
            CUST_NO: this.$store.state.CUST_NO,
            ABLE: ABLE,
            TYPE: TYPE,
            PAGE: this.page
          }
        }).then(res => {
          const data = res.data;
          // console.log(data);
          if (data.status === "SUCCESS") {

            this.allCount = data.result[0].CNT;

            let LIST = data.result[0].COUPON_LIST;

            this.coupon_list = [...this.coupon_list, ...LIST];

            this.page++;

            setTimeout(() => {
              this.isRunning = false;
              this.$emit('c-loadingFinish');
            }, 300);
          }
        }).catch(e => {
          // console.log(e);

          setTimeout(() => {
            this.$emit('c-loadingFinish');
            this.isRunning = false;
          }, 300);

          if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
            this.$router.replace('/app/sign_in');
          } else if (e.response.status === 401) {
            // console.log("None data");
          }

        });
      }

    },

    formatDate(inputDate) {

      if (inputDate && inputDate.length === 8) {
        // return inputDate;
        const year = inputDate.substring(0, 4);
        const month = inputDate.substring(4, 6);
        const day = inputDate.substring(6, 8);

        const date = new Date(`${year}-${month}-${day}`);

        const weekdays = this.langCommonText.weekdays[this.langKey];

        const dayOfWeek = date.getDay();

        return `${year}.${month}.${day}(${weekdays[dayOfWeek]})`;
      } else {
        return '';
      }


    },

    infiniteScroll(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      if ((scrollHeight / 2 < scrollTop + clientHeight)) {

        if (this.coupon_list.length >= this.allCount) {
          return;
        }

        if (!this.isRunning) {
          this.getCouponList();
        }
      }
    },
  }
}
</script>

<style scoped>


  .coupon-menu-box { width: 100%; height: 50px; box-sizing: border-box; display: flex; justify-content: center; align-items: center; }
  .menu-tab { width: 100%; height: 50px; display: flex; justify-content: center; align-items: center; font-size: 13px; border-bottom: 1px #FFFFFF solid; }
  .menu-tab-active { color: #9C836A; border-bottom: 1px #9C836A solid; }

  .coupon-contents-box { width: 100%; box-sizing: border-box; padding: 20px 15px 150px 15px; display: flex; flex-direction: column; }
  .coupon-category-box { width: 100%; display: flex; margin-bottom: 16px; flex-direction: column; }
  .coupon-category-box-row{width: 100%; display: flex; flex-direction: row; gap: 5px}
  .coupon-category { width: calc(50%); margin: 5px 2px; border-radius: 8px;  padding: 12px; box-sizing: border-box; display: flex; flex-direction: row; align-items: center; font-size: 12px; gap: 12px; color: #9C836A; border: 1px #F5F2F0 solid; }
  .coupon-category-active { background-color: #9C836A; color: #FFFFFF; border: none; }
  .coupon-category img{ width: 16px; height: 16px; }

  .coupon { width: 100%; height: auto; padding: 0 15px 0 5px; margin: 8px 0; position:relative; box-sizing: border-box; }
  .coupon img { width: calc(100% - 10px); height: 100%; position: absolute; z-index: -1 }
  .coupon-info-box { width: 100%; box-sizing: border-box; padding: 15px 24px; display: flex; flex-direction: column; }
  .coupon-info-label { display: flex; }
  .coupon-info-label div { padding: 6px 10px; height: 28px;  color: #FFF; display: flex; justify-content: center; align-items: center; font-size: 12px; border-radius: 14px; }
  .coupon-enable-back { background-color: #9C836A; }
  .coupon-disable-back { background-color: #BBBBBB; }
  .coupon-info-name { width: 100%; font-size: 17px; margin: 10px 0 5px; letter-spacing: 0.4px; }
  .coupon-enable-color { color: #4D4843; }
  .coupon-disable-color { color: #BBBBBB; }
  .coupon-info-date { width: 100%; font-size: 12px; margin-bottom: 12px; }
  .coupon-enable-line { width: 100%; height: 0; border: 1px dashed rgba(156, 131, 106, 0.3); }
  .coupon-disable-line { width: 100%; height: 0; border: 1px dashed rgba(156, 131, 106, 0); }
  .coupon-info-line .coupon-disable { opacity: 0; }
  .coupon-info-state { width: 100%; margin-top: 10px; font-size: 14px; }

  .coupon-footer{ position:fixed; display:flex; bottom:0; width:100%; flex-direction: column; background-color:#EDEDED; padding: 24px; }
  .coupon-footer .coupon-footer-title { font-size: 14px; color: #666666; }
  .coupon-footer ul { text-align: left; padding-left: 20px; margin: 15px 0 0 0; }
  .coupon-footer ul li{ font-size: 12px; color: #808080;  }

</style>

