const languages = {
    title:{
        KO: '예약 내역',//'호텔 예약 현황',
        EN: 'Reservations',
        JA: '予約内容',
        ZNTW: '预订记录',
    },
    total:{
        KO: '전체',
        EN: 'All',
        JA: '全体',
        ZNTW: '全部',
    },
    expected_use:{
        KO: '예정',// '이용 예정',
        EN: 'Confirmed',
        JA: '予定',
        ZNTW: '待使用',
    },
    complete_use:{
        KO: '완료',// '이용 완료',
        EN: 'Used',
        JA: '完了',
        ZNTW: '已使用',
    },
    cancel_reservation:{
        KO: '취소', //'예약 취소',
        EN: 'Cancelled',
        JA: 'キャンセル',
        ZNTW: '取消',
    },
    using_day:{
        KO: '이용일',
        EN: 'Stays',
        JA: '宿泊日',
        ZNTW: '入住日期',
    },
    total_reservation_account:{
        KO: '총 금액', //'총 예약금액',
        EN: 'Total Amount',
        JA: '総額',
        ZNTW: '总额',
    },
    notice:{
        KO: '안내상황', //'유의사항',
        EN: 'Information',
        JA: '案内事項',
        ZNTW: '敬請留意',
    },
    notice_comment:{
        KO: '취소에 따른 환불 정책 및 수수료는 상품마다 상이합니다.<br/>자세한 내용은 예약 상세 내역에서 확인해주세요',
        EN: 'Cancellation policy and fees vary based on the product type.<br/> For further information, please refer to the reservation detail. ',
        JA: 'キャンセルによる返金ポリシーと手数料はプランごとに異なります。<br/>詳細は予約履歴でご確認ください。',
        ZNTW: '不同产品的取消退款政策和手续费各不相同。<br/>详细内容请参考预订明细。',
    }

}


module.exports = languages;
