const languages = {
    title:{
        KO: '적립내역',
        EN: 'Points balance',
        JA: '獲得履歴',
        ZNTW: '积分明细',

    }

}


module.exports = languages;
