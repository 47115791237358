const languages = {
    check_out_title: {
        KO: '비대면 체크아웃',
        EN: 'Contactless checkout',
        JA: '非対面チェックアウト',
        ZNTW: '自助退房',
    },
    minibar: {
        KO: '미니바',
        EN: 'Mini-bar',
        JA: 'ミニバー',
        ZNTW: '迷你吧',
    },
    thank_comment:{
        KO: '이용해주셔서 감사합니다',
        EN: 'Thank you for staying with us.',
        JA: 'ご利用ありがとうございました。',
        ZNTW: '感谢您的入住。',
    },
    thank_comment_1:{
        KO: '결제 및 체크아웃이 완료되었습니다',
        EN: 'Payment and checkout were successful.',
        JA: 'お支払いとチェックアウトが完了しました。',
        ZNTW: '付款及退房成功。',
    },
    success_comment:{
        KO: '객실키는 객실에 두시고 <br/>퇴실해주세요',
        EN: 'Please leave your room key <br/>inside the room. ',
        JA: 'カードキーは室内に置いて<br/>退室してください。',
        ZNTW: '请将房卡留在客房中后退房。',
    },
    success_comment_1:{
        KO: '*키택 또는 테이블',
        EN: '*Key tag or table',
        JA: '*キータグまたはテーブル',
        ZNTW: '*卡槽或桌子',
    },
    go_home:{
        KO: '홈으로 가기',
        EN: 'Home',
        JA: 'ホームに戻る',
        ZNTW: '返回首页',
    }

}


module.exports = languages;
