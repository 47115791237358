const languages = {
    check_out_title: {
        KO: '비대면 체크아웃',
        EN: 'Contactless checkout',
        JA: '非対面チェックアウト',
        ZNTW: '自助退房',
    },
    minibar: {
        KO: '미니바',
        EN: 'Mini-bar',
        JA: 'ミニバー',
        ZNTW: '迷你吧',
    },
    minibar_comment:{
        KO: '이용하신 미니바 제품을 선택해주세요',
        EN: 'Select the Mini-bar items used.',
        JA: 'ご利用されたミニバーの商品を選択してください。',
        ZNTW: '请选择您使用的迷你吧产品',
    },
    co_step1_modal_comment:{
        KO: '이용하신 미니바 제품을/선택해주세요.',
        EN: 'Select the Mini-bar/items used.',
        JA: 'ご利用されたミニバーの商品を/選択してください。',
        ZNTW: '请选择您使用的/迷你吧产品',
    },
    total_price_text:{
        KO: '후불금액 조회',
        EN: 'Check the outstanding amount.',
        JA: '後払い料金の照会',
        ZNTW: '查询后付金额',
    }
}


module.exports = languages;
