<template>

  <!-- 예약번호 등록, 수정 화면 컴포넌트 -->
  <div class="common_layout" >

    <div class="depth-content-box">

      <!--   상단 뒤로가기   -->
      <div class="header">
        <img alt="뒤로가기" src="../../assets/icon/back_btn.svg" v-on:click="$router.go(-1);">
        <div class="font1-B">{{ this.langText.title [this.langKey] }}</div>
      </div>

      <!--  예약번호 등록, 수정 컨텐츠  -->
      <div class="reservation-content">
        <div class="reservation-box">
          <div class="reservation-input-content">
            <div class="reservation-title font1-R">{{this.langCommonText.reservation_number[this.langKey]}}</div>
            <input class="reservation-value" :class="numActive ? 'reservation-value-active' : ''" v-model="num" type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"  inputmode="decimal" pattern="\d*" v-on:focusin="numActive = true" v-on:focusout="numActive = false" />
            <div class="reservation-now font1-R" v-if="this.$store.state.GSTNO">{{this.langText.current_reservation_number[this.langKey]}} : {{this.$store.state.GSTNO}}</div>
          </div>
          <div class="reservation-input-content">
            <div class="reservation-title font1-R">{{ this.langText.phone_number_4[this.langKey] }}</div>
            <input class="reservation-value" :class="phoneActive ? 'reservation-value-active' : ''" v-model="phone" type="text"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')" inputmode="decimal" pattern="\d*" v-on:focusin="phoneActive = true" v-on:focusout="phoneActive = false" />
          </div>
        </div>
        <div class="reservation-btn font1-M" v-on:click="saveNewReservation">{{ this.langText.register[this.langKey] }}</div>
      </div>
      <div class="reservation-none-space"></div>
    </div>

    <!--  하단 예약번호 변경안내 가이드 텍스트  -->
    <div class="reservation-bottom-content font1-B">
      <div class="reservation-bottom-title">{{this.langText.reservation_change_info[this.langKey]}}</div>
      <div class="reservation-bottom-text font1-R">
        <div class="dot">&bull;</div>
        <span>{{ this.langText.reservation_info_0[this.langKey] }}</span>
      </div>
      <div class="reservation-bottom-text font1-R">
        <div class="dot">&bull;</div>
        <span>{{ this.langText.reservation_info_1[this.langKey] }}</span>
      </div>
      <div class="reservation-bottom-text font1-R">
        <div class="dot">&bull;</div>
        <div class="txt" v-html="this.langText.reservation_info_2[this.langKey]"/>
      </div>
      <div class="reservation-bottom-final"></div>
    </div>

    <!--  예약번호 등록 실패시 안내 모달  -->
    <div class="reservation-modal-background" :class="modalRunning ? 'modal-open':'modal-close'">
      <div class="reservation-modal">
        <div class="reservation-modal-comment">
          <div>{{modalText1}}</div>
          <div>{{modalText2}}</div>
        </div>
        <div class="reservation-modal-btn font1-M" v-on:click="modalRunning = false;">{{this.langCommonText.confirm[this.langKey]}}</div>
      </div>
    </div>

    <!--  예약번호 등록, 수정시 로딩  -->
    <div class="reservation-loading-background" v-if="reservationCheckRun">
      <img class="reservation-loading-icon" src="@/assets/icon/loading_icon.svg" alt="새로고침 로딩" />
    </div>


  </div>

</template>

<script>
import languages from '../../modules/languages/reservation'
import common from '../../modules/languages/common'

  export default {
    data() {
      return {
        langText: languages,
        langCommonText: common,
        langKey: this.$store.state.lang,
        num: "",                            // 예약번호
        phone: "",                          // 핸드폰번호 뒷 4자리
        modalText1: languages.modal_test_1_0[this.$store.state.lang],    // 안내 모달 상단 텍스트
        modalText2: languages.modal_test_2_0[this.$store.state.lang],    // 안내 모달 하단 텍스트
        modalRunning: false,                  // 안내 모달 동작 여부
        reservationCheckRun: false,           // 예약번호 검증 동작 여부
        numActive: false,                     // 예약번호 입력창 포커스 여부
        phoneActive: false                    // 핸드폰번호 뒷 4자리 입력창 포커스 여부
      }
    },
    created() {

      /* 전역변수에 예약번호가 등록되있다면 현재 페이지 에약번호에 바로 지정 */
      if (this.$store.state.GSTNO) {
        this.num = this.$store.state.GSTNO;
      }
    },
    methods: {

      /*
       * 예약번호 등록 수정 함수
       * 1. 예약번호가 6자리 미만 또는 11자리 이상일때, 핸드폰번호 뒷 4자리가 4자가 아닐 때 안내 예약번호 등록 실패 안내 모달을 동작시키고 종료
       * 2. 이미 예약번호 검증이 동작중이라면 종료
       * 3. 예약번호가 유효하지 않다면 등록 실패 안내 모달을 동작시키고 종료
       * 4. 정상적으로 등록했다면 네이티브에 저장되있던 예약번호를 현재 예약번호로 셋팅 후 기초데이터 호출 함수를 비동기로 호출한뒤 홈화면으로 이동한다.
       * */
      async saveNewReservation() {


        if (this.num.length === "" || this.phone.length === "") {

          this.modalText1 = this.langText.modal_test_1_0[this.langKey];// "예약번호 혹은 휴대전화번호";
          this.modalText2 = this.langText.modal_test_2_0[this.langKey]; //"4자리를 다시 입력해주세요";
          this.modalRunning = true;
          return;

        }else if (this.num.length < 6 || this.num.length > 10 || this.phone.length!==4) {

          this.modalText1 = this.langText.modal_test_1_0[this.langKey]; // "예약번호 혹은 휴대전화번호";
          this.modalText2 = this.langText.modal_test_2_0[this.langKey]; //"4자리를 다시 입력해주세요";
          this.modalRunning = true;
          return;

        }


        if (!this.reservationCheckRun) {

          this.reservationCheckRun = true;



          try {
            const res = await this.axios({
              method: "GET",
              url: `${process.env.VUE_APP_API_SERVER}/api/app/reservation_check`,
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
              },
              params: {
                CUST_ID: this.$store.state.CUST_ID,
                CUST_NO: this.$store.state.CUST_NO,
                GSTNO: this.num,
                MOBILE_LAST: this.phone
              }
            });

            const data = await res.data;

            if (data.status === "SUCCESS") {

              if (data.result.length < 1) {
                this.modalText1 = this.langText.modal_test_1_0[this.langKey];// "예약번호 혹은 휴대전화번호";
                this.modalText2 = this.langText.modal_test_2_0[this.langKey];// "4자리를 다시 입력해주세요";
                this.reservationCheckRun = false;
                this.modalRunning = true;
              } else {

                if (data.result[0].SYSTUS === 'COUNTER') {

                  this.modalText1 = this.langText.modal_test_1_1[this.langKey];//"휴대전화번호가 입력되어있지 않습니다.";
                  this.modalText2 = this.langText.modal_test_2_1[this.langKey]; // "카운터에서 문의해주세요";
                  this.reservationCheckRun = false;
                  this.modalRunning = true;

                } else {

                  this.$store.state.GSTNO = this.num;

                  this.getAppData();

                  if (this.$store.state.USER_AGENT) {
                    if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
                            this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
                      const data = {
                        'mode':'saveReservation',
                        'GSTNO':this.num
                      }
                      window.ReactNativeWebView.postMessage(JSON.stringify(data));
                    }
                  }

                  this.$router.go(-1);

                }

              }

            }
            else {
              this.modalText1 =  this.langText.modal_test_1_2[this.langKey]; //"잠시 후에 다시 시도해주세요";
              this.modalText2 = "";
              this.reservationCheckRun = false;
              this.modalRunning = true;
            }

          } catch(e) {
            this.reservationCheckRun = false;
            // console.log.log(e);
            if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
              await this.$router.replace('/app/sign_in');
            } else if (e.response.status === 401) {
              // console.log.log("None data");
            }
          }

        }

      },

      /* 기초데이터 셋팅 함수 */
      getAppData() {
        this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_SERVER}/api/app/get_information`,
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
          },
          params: {
            CUST_ID: this.$store.state.CUST_ID,
            CUST_NO: this.$store.state.CUST_NO,
            GSTNO: this.$store.state.GSTNO
          }
        }).then(res => {
          const data = res.data.result;
          // console.log.log(">> AppDATA");
          // console.log.log(data);
          // console.log.log("<< AppDATA");

          this.$store.state.MILEAGE_DATA = data.MILEAGE[0];
          if (data.ROOM_SERVICE.length > 0) {
            this.$store.state.ROOM_SERVICE_DATA = data.ROOM_SERVICE;
          } else {
            this.$store.state.ROOM_SERVICE_DATA = [];
          }

          if (data.STAY_HISTORY.length > 0) {
            this.$store.state.STAY_HISTORY_DATA = data.STAY_HISTORY;
          } else {
            this.$store.state.STAY_HISTORY_DATA = [];
          }

        }).catch(error => {
          console.log(error);
        });
      }
    }
  }
</script>

<style scoped>
  .common_layout{ background-color: #F9F9F9; }
  .depth-content-box{ width:100%; background-color: #FFF; }

  .reservation-content{ width:100%; display:flex; flex-direction: column; justify-content: space-between; padding:24px 24px 0 24px;  }

  .reservation-box{ width:100%; }
  .reservation-input-content{ width:100%; margin-bottom:30px; }
  .reservation-title{ color:#666666; font-size:12px; }
  .reservation-now{ color:#9C836A; font-size:12px; }
  .reservation-value{ width:100%; height: 35px; margin:8px 0; border: 0; border-bottom:1px solid #EDEDED; outline: none; padding:0; font-size:14px; }
  .reservation-value-active{ border-bottom:1px solid #9C836A; }
  .reservation-btn{ width: 100%; height:50px; background-color: #9C836A; border-radius: 6px; color:#FFF; display:flex; justify-content: center; align-items: center; font-size:14px; }

  .reservation-modal-background{ top:0; width:100%; height:100vh; position:fixed; padding: 0 35px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.6); z-index: 10; }
  .modal-open{ display:flex; }
  .modal-close{ display:none; }
  .reservation-modal{ width:100%; background-color: #FFF; border-radius: 6px; overflow: hidden; margin-bottom:30px; }
  .reservation-modal-comment{ width:100%; margin:35px 0 30px 0; }
  .reservation-modal-comment div{ width:100%; text-align: center; font-size:14px; color:#333333; }
  .reservation-modal-btn{ width:100%; height:50px; background-color: #9C836A; display:flex; justify-content: center; align-items: center; color:#FFF; }

  .reservation-loading-background{ background: rgba(0, 0, 0, 0.8); position:fixed; top:0; left:0; right:0; bottom:0; display:flex; justify-content: center; align-items: center; z-index:10; }
  .reservation-loading-icon{ width:45px; }

  .reservation-bottom-content{ width:100%; background-color:#F9F9F9; color: #808080; font-size:12px;  }
  .reservation-bottom-title{ height:50px; width:100%; display:flex; justify-content: flex-start; align-items: center; border-bottom: 1px solid #EDEDED; margin-bottom: 16px; padding:0 25px; }
  .reservation-bottom-text{ width:100%; margin-bottom:8px; display:flex; padding:0 25px; line-height: 18px; }
  .reservation-bottom-text .dot{ display:flex; height: 18px; width:15px; align-items: center; justify-content: flex-start; }
  .reservation-bottom-text .txt{ display:flex; flex-wrap: wrap; }
  .reservation-bottom-final{ height:40px; width:100%; }

  .reservation-none-space{ width:100%; height:130px; }

</style>
