<template>

  <!-- 예약내역 화면 컴포넌트 -->
  <div class="common_layout" @scroll="infiniteScroll">

    <!--   상단 뒤로가기   -->
    <div class="header">
      <img alt="뒤로가기" src="../../assets/icon/back_btn.svg" v-on:click="goBack">
      <div class="font1-B">{{this.langText.title[this.langKey]}}</div>
    </div>

    <!--   상단 메뉴   -->
    <div class="stay-history-menu-box">
      <div :class="tab_idx === 0 ? 'menu-tab menu-tab-active' : 'menu-tab'" v-on:click="tabChange(0)">OFFERS</div>
      <div :class="tab_idx === 1 ? 'menu-tab menu-tab-active' : 'menu-tab'" v-on:click="tabChange(1)">TO GO BOX</div>
      <div :class="tab_idx === 2 ? 'menu-tab menu-tab-active' : 'menu-tab'" v-on:click="tabChange(2)">DINE & DRINK</div>
    </div>

    <div class="stay-history-contents-box">

      <div class="stay-history-category-box">
        <div class="stay-history-category" :class="category_idx === 0 ? 'stay-history-category-active' : ''"  v-on:click="categoryChange(0)">
          <div class="stay-history-category-title">{{this.langText.total[this.langKey]}}</div>
          <div class="stay-history-category-value"><span>{{allCount}}</span>{{this.langCommonText.gun[this.langKey]}}</div>
        </div>
        <div class="stay-history-category" :class="category_idx === 1 ? 'stay-history-category-active' : ''"  v-on:click="categoryChange(1)">
          <div class="stay-history-category-title">{{this.langText.expected_use[this.langKey]}}</div>
          <div class="stay-history-category-value"><span>{{resCount}}</span>{{this.langCommonText.gun[this.langKey]}}</div>
        </div>
        <div class="stay-history-category" :class="category_idx === 2 ? 'stay-history-category-active' : ''"  v-on:click="categoryChange(2)">
          <div class="stay-history-category-title">{{this.langText.complete_use[this.langKey]}}</div>
          <div class="stay-history-category-value"><span>{{completeCount}}</span>{{this.langCommonText.gun[this.langKey]}}</div>
        </div>
        <div class="stay-history-category" :class="category_idx === 3 ? 'stay-history-category-active' : ''"  v-on:click="categoryChange(3)">
          <div class="stay-history-category-title">{{this.langText.cancel_reservation[this.langKey]}}</div>
          <div class="stay-history-category-value"><span>{{cancelCount}}</span>{{this.langCommonText.gun[this.langKey]}}</div>
        </div>
      </div>

      <div class="stay-box"  v-for="(item, index) in stay_list" :key="index">
        <div class="stay-info-top"  v-on:click="goDetail(item.GSTNO ? item.GSTNO : item.RESNO)">
          <div class="stay-info-order">{{this.langCommonText.reservation_number[this.langKey]}}&nbsp;&nbsp;{{item.GSTNO ? item.GSTNO : item.RESNO}}</div>
          <img src="@/assets/icon/right_arrow.svg" alt="상세화면 이동" />
        </div>
        <div class="stay-info-prd">
          <div class="stay-info-name">{{item.SALES_DESC}}{{item.PACKAGE_NM}}{{item.DEPT_DESC}}</div>
          <div class="stay-info-state stay-info-used" v-if="item.STATUS ? ['OUT', 'DONE'].includes(item.STATUS) : ['OUT', 'DONE'].includes(item.SYSTUS)">{{this.langCommonText.complete[this.langKey]}}</div>
          <div class="stay-info-state stay-info-reservation" v-else-if="item.STATUS ? item.STATUS === 'RES' : item.SYSTUS === 'RES'">{{this.langCommonText.reservation[this.langKey]}}</div>
          <div class="stay-info-state stay-info-cancel" v-else>{{this.langCommonText.cancel[this.langKey]}}</div>
        </div>
        <div class="stay-info-check-date">{{this.langText.using_day[this.langKey]}}&nbsp;&nbsp;{{item.RDATE ? formatDate(item.RDATE) : formatDate(item.ARRIVDATE)+' ~ '+formatDate(item.DEPARTDATE)}}</div>
<!--        <div class="stay-info-price">-->
<!--          <div class="stay-info-price-title">{{this.langText.total_reservation_account[this.langKey]}}</div>-->
<!--          <div class="stay-info-price-value font1-M" v-if="this.langKey === 'KO'">{{String(100000).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}원</div>-->
<!--          <div class="stay-info-price-value font1-M" v-else>{{this.langCommonText.currency_unit[this.langKey]}}&nbsp;{{String(100000).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>-->
<!--        </div>-->
      </div>


    </div>





    <!--  하단 고객센터 정보  -->
    <div class="stay-history-footer">
      <div class="stay-history-footer-title">{{this.langText.notice[this.langKey]}}</div>
      <ul>
        <li v-html="this.langText.notice_comment[this.langKey]"/>
      </ul>
    </div>



  </div>

</template>

<script>
import languages from '../../modules/languages/stay_history'
import common from '../../modules/languages/common'

export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      isRunning: false,
      page: 1,
      tab_idx: 0,
      category_idx: 0,
      allCount: 0,
      resCount: 0,
      completeCount: 0,
      cancelCount: 0,
      stay_list: []
    }
  },
  created() {

    this.$emit('c-loadingStart');
    this.getOrderList();


  },
  methods: {

    /* 이전 화면 이동 */
    goBack() {
      this.$router.go(-1);
    },

    tabChange(idx) {
      if (idx !== this.tab_idx) {
        this.$emit('c-loadingStart');
        this.tab_idx = idx;
        this.category_idx = 0;
        this.page = 1;
        this.stay_list = [];
        this.getOrderList();
      }
    },

    categoryChange(idx) {
      if (idx !== this.category_idx) {
        this.$emit('c-loadingStart');
        this.category_idx = idx;
        this.page = 1;
        this.stay_list = [];
        this.getOrderList();
      }
    },

    goDetail(orderNo) {
      this.$router.push(`/app/stay_history_detail?orderNo=${orderNo}&tab_idx=${this.tab_idx}`);
    },

    async getOrderList() {

      if (!this.isRunning) {
        this.isRunning = true;


        let URL = `${process.env.VUE_APP_API_SERVER}/api/app/`;
        let TYPE = '';

        if (this.tab_idx === 0) URL += 'offer_history'
        else if (this.tab_idx === 1) URL += 'togo_history';
        else if (this.tab_idx === 2) URL += 'fnb_history';

        if (this.category_idx === 0) TYPE = 'ALL';
        else if (this.category_idx === 1) TYPE = 'RES';
        else if (this.category_idx === 2) TYPE = 'COMPLETE';
        else if (this.category_idx === 3) TYPE = 'CANCEL';

        this.axios({
          method: "GET",
          url: URL,
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
          },
          params: {
            CUST_NO: this.$store.state.CUST_NO,
            TYPE: TYPE,
            PAGE: this.page
          }
        }).then(res => {
          const data = res.data;
          // console.log.log(data);
          if (data.status === "SUCCESS") {

            let LIST = [];

            if (this.tab_idx === 0) LIST = data.result[0].OFFER_LIST;
            else if (this.tab_idx === 1) LIST = data.result[0].TOGO_LIST;
            else if (this.tab_idx === 2) LIST = data.result[0].FNB_LIST;

            if (data.result[0].CNT) {
              this.allCount = data.result[0].CNT[0].ALL;
              this.resCount = data.result[0].CNT[0].RES;
              this.completeCount = data.result[0].CNT[0].COMPLETE;
              this.cancelCount = data.result[0].CNT[0].CANCEL;
            }

            this.stay_list = [...this.stay_list, ...LIST];

            this.page++;

            setTimeout(() => {
              this.isRunning = false;
              this.$emit('c-loadingFinish');
            }, 300);
          }
        }).catch(e => {
          // console.log.log(e);

          setTimeout(() => {
            this.$emit('c-loadingFinish');
            this.isRunning = false;
          }, 300);

          if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
            this.$router.replace('/app/sign_in');
          } else if (e.response.status === 401) {
            // console.log.log("None data");
          }

        });
      }



    },

    infiniteScroll(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      if ((scrollHeight / 2 < scrollTop + clientHeight)) {

        if (this.category_idx === 0 && this.stay_list.length >= this.allCount) {
          return;
        }
        if (this.category_idx === 1 && this.stay_list.length >= this.resCount) {
          return;
        }
        if (this.category_idx === 2 && this.stay_list.length >= this.completeCount) {
          return;
        }
        if (this.category_idx === 3 && this.stay_list.length >= this.cancelCount) {
          return;
        }

        if (!this.isRunning) {
          this.getOrderList();
        }
      }
    },

    formatDate(inputDate) {

      // return inputDate;
      const year = inputDate.substring(0, 4);
      const month = inputDate.substring(4, 6);
      const day = inputDate.substring(6, 8);

      const date = new Date(`${year}-${month}-${day}`);

      const weekdays = this.langCommonText.weekdays[this.langKey];

      const dayOfWeek = date.getDay();

      return `${year}.${month}.${day}(${weekdays[dayOfWeek]})`;
    }

  }
}
</script>

<style scoped>
  .common_layout{ background-color:#F9F9F9; }

  .stay-history-menu-box { width: 100%; height: 50px; box-sizing: border-box; display: flex; justify-content: center; align-items: center; }
  .menu-tab { width: 100%; height: 50px; display: flex; justify-content: center; align-items: center; font-size: 13px; border-bottom: 1px #FFFFFF solid; }
  .menu-tab-active { color: #9C836A; border-bottom: 1px #9C836A solid; }

  .stay-history-contents-box { width: 100%; box-sizing: border-box; padding: 20px 15px 150px 15px; }
  .stay-history-category-box { width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 10px; }
  .stay-history-category { width: calc(50% - 2px); height: 45px; border-radius: 8px; margin-bottom: 4px; display: flex; justify-content: space-between; align-items: center; font-size: 12px; box-sizing: border-box; padding: 0 15px; background-color: #F8F6F5; color: #808080; }
  .stay-history-category-value { display: flex; align-items: center; }
  .stay-history-category .stay-history-category-value span { font-size: 14px; margin-right: 1px; }
  .stay-history-category-active { color: #FFFFFF; background-color: #9C836A; }

  .stay-box { width: 100%; border: 1px #EDEDED solid; box-sizing: border-box; overflow: hidden; border-radius: 16px; margin: 5px 0; }
  .stay-info-top { width:100%; display: flex; align-items: center; justify-content: space-between; box-sizing: border-box; padding: 10px 15px; background-color: #F9F9F9; }
  .stay-info-top img { width: 16px; }
  .stay-info-order { color: #9C836A; font-size: 12px; }
  .stay-info-prd { width: 100%; display: flex; align-items: center; justify-content: space-between; box-sizing: border-box; padding: 5px 15px 5px 15px; }
  .stay-info-name { color: #9C836A; font-size: 14px; width: calc(100% - 90px); overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
  .stay-info-state { border-radius: 20px; padding: 4px 10px; font-size: 12px; color: #FFFFFF; }
  .stay-info-cancel { background-color: #F15656; }
  .stay-info-reservation { background-color: #9C836A; }
  .stay-info-used { background-color: #D8D8D8; }
  .stay-info-check-date { width: 100%; box-sizing: border-box; padding: 0 15px 10px 15px; font-size: 12px; color: #808080; }
  .stay-info-price { width: 100%; box-sizing: border-box; padding: 22px 15px 15px 15px; display: flex; justify-content: space-between; align-items: center; }
  .stay-info-price-title { font-size: 12px; color:#808080; }
  .stay-info-price-value { font-size: 14px; color:#222222; }



  .stay-history-footer{ position:fixed; display:flex; bottom:0; width:100%; flex-direction: column; background-color:#EDEDED; padding: 24px; }
  .stay-history-footer .stay-history-footer-title { font-size: 14px; color: #666666; }
  .stay-history-footer ul { text-align: left; padding-left: 20px; margin: 15px 0 0 0; }
  .stay-history-footer ul li{ font-size: 12px; color: #808080;  }

</style>

