const languages = {
    languages_title: {
        KO: '언어설정',
        EN: 'Language Settings',
        JA: '設定',
        ZNTW: '語言設置',
    },

}


module.exports = languages;
