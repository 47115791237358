const languages = {
    coupon_history_title: {
        KO: '쿠폰',// '쿠폰 내역',
        EN: 'Coupons',
        JA: 'クーポン',
        ZNTW: '优惠券',
    },
    able_coupon: {
        KO: '사용가능 쿠폰',
        EN: 'Available Coupon',
        JA: '使用可能 クーポン',
        ZNTW: '可用 优惠券',
    },
    expiration_coupon:{
        KO: '사용만료 쿠폰',
        EN: 'Expired Coupon',
        JA: '使用期間満了 クーポン',
        ZNTW: '使用期满 优惠券',
    },
    online:{
        KO: '온라인',
        EN: 'Online',
        JA: 'オンライン',
        ZNTW: '网上',
    },
    reward:{
        KO: '파라디안 리워드',
        EN: 'Paradian Reward',
        JA: 'PARADIAN Reward',
        ZNTW: 'Paradian REWARD',
    },
    signature:{
        KO: '파라디안 시그니처',
        EN: 'Paradian Signature',
        JA: 'Paradian シグネチャー',
        ZNTW: 'PARADIAN SIGNATURE',
    },
    fitness:{
        KO: '파라디안 피트니스',
        EN: 'Paradian Fitness',
        JA: 'Paradian フィットネス ',
        ZNTW: 'PARADIAN FITNESS',
    },
    coupon_label_0:{
        KO: '온라인 쿠폰',
        EN: 'Online Coupon',
        JA: 'オンライン クーポン',
        ZNTW: '网上 优惠券',
    },
    coupon_label_1:{
        KO: '파라디안 리워드 쿠폰',
        EN: 'Paradian Reward Coupon',
        JA: 'PARADIAN Reward クーポン',
        ZNTW: 'Paradian REWARD 优惠券',
    },
    coupon_label_2:{
        KO: '파라디안 시그니처 쿠폰',
        EN: 'Paradian Signature Coupon',
        JA: 'Paradian シグネチャー クーポン',
        ZNTW: 'PARADIAN SIGNATURE 优惠券',
    },
    coupon_label_3:{
        KO: '파라디안 피트니스',
        EN: 'Paradian Fitness Coupon',
        JA: 'Paradian フィットネス クーポン',
        ZNTW: 'PARADIAN FITNESS 优惠券',
    },
    notice_comment:{
        KO: '취소에 따른 환불 정책 및 수수료는 상품마다 상이합니다.<br/>자세한 내용은 예약 상세 내역에서 확인해주세요',
        EN: 'Cancellation policy and fees vary based on the product type.<br/> For further information, please refer to the reservation detail. ',
        JA: 'キャンセルによる返金ポリシーと手数料はプランごとに異なります。<br/>詳細は予約履歴でご確認ください。',
        ZNTW: '不同产品的取消退款政策和手续费各不相同。<br/>详细内容请参考预订明细。',
    },
    success_comment_1:{
        KO: '*키택 또는 테이블',
        EN: '*Key tag or table',
        JA: '*キータグまたはテーブル',
        ZNTW: '*卡槽或桌子',
    },
    go_home:{
        KO: '홈으로 가기',
        EN: 'Home',
        JA: 'ホームに戻る',
        ZNTW: '返回首页',
    }

}


module.exports = languages;
