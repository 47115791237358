<template>

  <!-- 마일리지 내역 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="depth-content-box">
      <div class="header">
        <img alt="뒤로가기" src="../../assets/icon/back_btn_white.svg" v-on:click="$router.go(-1);">
        <div class="header-f-white font1-B">{{this.langText.title[this.langKey]}}</div>
      </div>


      <!--   상단 내 이름, 아이디, 로그아웃 버튼   -->
      <div class="my-data-box">
        <div class="my-data-top">
          <div class="my-data-name font1-R">{{this.$store.state.CUST_NM}} <span v-if="this.langKey === 'KO'">님</span></div>
          <div class="sign-out-box" v-on:click="signOut">
            <div class="font1-R">{{this.langText.logout[this.langKey]}}</div>
            <img class="sign-out-icon" alt="Sign Out" src="@/assets/icon/sign_out.svg">
          </div>
        </div>

        <div class="my-data-email font1-R">{{this.$store.state.CUST_ID}}</div>
      </div>

      <!--   보유한 포인트, 마일리지 적립내역과 마일리지 안내 페이지 이동 링크   -->
      <div class="my-data-box-sub">

        <div class="my-data-box-sub-top font1-R">
          <span>{{this.langCommonText.paradian_point[this.langKey]}}</span>
          <div class="my-data-box-link" v-on:click="$router.push('/app/point_history')">
            <span>{{this.langText.mileage_history[this.langKey]}}</span>
            <img class="my-data-box-sub-img" alt="arrow" src="@/assets/icon/back_btn_white.svg">
          </div>
        </div>

        <div class="my-data-box-sub-top font1-R">
          <div class="my-data-box-sub-P font2-M">{{String(AVAIL_ML).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}<span class="font2-R">p</span></div>
          <div class="my-data-box-link" v-on:click="$router.push('/app/mileage_history')">
            <span>{{this.langText.benefit_go[this.langKey]}}</span>
            <img class="my-data-box-sub-img" alt="arrow" src="@/assets/icon/back_btn_white.svg">
          </div>
        </div>


        <div class="my-data-box-sub-top font1-R">
          <span></span>
          <div class="my-data-box-link" v-on:click="$router.push('/app/stay_history')">
            <span>{{this.langText.book_history[this.langKey]}}</span>
            <img class="my-data-box-sub-img" alt="arrow" src="@/assets/icon/back_btn_white.svg">
          </div>
        </div>
      </div>

    </div>


    <!--  수정 후 고객번호 QR  -->

    <div class="barcode-box">
      <div class="barcode-title font1-R">{{this.langText.customer_number[this.langKey]}} QR</div>
      <qrcode-vue class="qr" :value="qr_value" :size="qrCodeSize" level="H" />
      <div class="barcode-time font1-B">{{this.currentTime}}</div>
      <div class="barcode-text font1-R" v-html="this.langText.barcode_comment[this.langKey]"></div>
    </div>


  </div>

</template>

<script>

  import QrcodeVue from 'qrcode.vue'
  import CryptoJS from "crypto-js";
  import moment from 'moment-timezone';
  import languages from '../../modules/languages/mypage'
  import common from '../../modules/languages/common'

  export default {
    data() {
      return {
        langText: languages,
        langCommonText: common,
        langKey: this.$store.state.lang,
        secretKey : '12345678901234567890123456789012',
        qr_value: '',

        currentTime: moment().tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss'),

        AVAIL_ML: this.$store.state.MILEAGE_DATA.AVAIL_ML, // 보유 포인트

        intervalVeriable: null
      }
    },
    components: {
      QrcodeVue,
    },
    computed: {
      qrCodeSize() {
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return Math.floor(screenWidth * 0.45);
      },
    },

    /*********************/

    created() {

      /*
       * 비동기로 현재 페이지에서 사용될 데이터를 받아와서 적용한다.
       * 초기값은 이미 홈에서 받아온 기초데이터가 적용해져 있다.
       *  */
      this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_SERVER}/api/app/mileage_history`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
        },
        params: {
          CUST_ID: this.$store.state.CUST_ID,
          CUST_NO: this.$store.state.CUST_NO
        }
      }).then(res => {
        const data = res.data;
        if (data.status === "SUCCESS") {
          this.AVAIL_ML = data.result[0].AVAIL_ML;
          this.$store.state.MILEAGE_DATA.AVAIL_ML = data.result[0].AVAIL_ML;
        }
      }).catch(e => {
        // console.log.log(e);
        if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
          this.$router.replace('/app/sign_in');
        }else if (e.response.status === 401) {
          // console.log.log("None data");
        }
      });

      // console.log.log(this.currentTime);

    },
    methods: {

      /* AES 암호화 */
      encryptQR(CUST_NO) {
        const cipher = CryptoJS.AES.encrypt(CUST_NO, CryptoJS.enc.Utf8.parse(this.secretKey), {
          iv: CryptoJS.enc.Utf8.parse(""),
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC,
        });
        return cipher.toString();
      },

      /* 로그아웃 버튼 클릭 이벤트 */
      signOut() {

        const data2 = {
          'mode':'deleteReservation'
        }

        const data = {
          'mode':'signOut'
        };

        try {
          if (this.$store.state.USER_AGENT.indexOf('android') > -1 || this.$store.state.USER_AGENT.indexOf("iphone") > -1 ||
                  this.$store.state.USER_AGENT.indexOf("ipad") > -1 || this.$store.state.USER_AGENT.indexOf("ipod") > -1) {
            window.ReactNativeWebView.postMessage(JSON.stringify(data2));
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
          }
          this.$store.state.ISSIGNOUT = true;
          this.$router.replace('/app/sign_in');
        } catch(e) {
          // console.log.log(e);
          alert(e);
        }

      },

      /* 현재시간 업데이트 */
      updateTime() {
        this.intervalVeriable = setInterval(() => {
          this.currentTime = moment().tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss');
        }, 1000);
      }
    },
    mounted() {
      this.qr_value=this.encryptQR(this.$store.state.CUST_NO);
      this.updateTime();
    },
    unmounted() {
      clearInterval(this.intervalVeriable);
    }
  }
</script>

<style scoped>
  .common_layout{ background-color:#4D4843; }
  .header-f-white{ color:#FFFFFF; }

  .depth-content-box{ width:100%; height:276px; position:relative; }
  .my-data-box{ width:100%; display:flex; flex-direction: column; align-items: center; padding:20px 24px 35px 24px; color:#FFFFFF; border-bottom:1px #FFF solid; }
  .my-data-top{ width:100%; display:flex; justify-content: space-between; height:32px; align-items: center; }
  .my-data-name{ display:flex; height:32px; align-items: flex-end; font-size:25px; }
  .my-data-name span{ font-size:16px; margin-left:5px; margin-bottom:2px; }
  .sign-out-box{ height:32px; display:flex; align-items: center; font-size:12px; }
  .sign-out-icon{ height:22px; margin-left:5px; }
  .my-data-email{ width:100%; justify-content: flex-start; margin-top:8px; font-size:14px; }
  .my-data-box-sub{ width:100%; padding:25px 27px; display:flex; flex-direction: column; }
  .my-data-box-sub-top{ width:100%; justify-content: space-between; display:flex; font-size:12px; color:white; margin-bottom:5px; }
  .my-data-box-sub-bottom{ width:100%; justify-content: space-between; display:flex; font-size:12px; color:white; }
  .my-data-box-sub-P{ color:#9C836A; font-size:20px; }
  .my-data-box-link{ display:flex; align-items: center; }
  .my-data-box-sub-img{ height:10px; width:10px; transform: rotate(180deg); margin-left:2px; }


  .barcode-box{ width:100%; display:flex; align-items: center; justify-content: center; flex-direction: column; min-height:calc(100vh - 276px); background-color:#FFF; }
  svg{ width:100%; padding:0 14px; }
  .barcode-title{ color:#666666; }
  .barcode-text{ color:#808080; }
  .barcode-time{ width:100%; margin-top:30px; font-size:16px; color: #9C836A; text-align: center; }
  .barcode-title{ width:100%; padding:20px 0; font-size:15px; text-align: center; }
  .barcode-text { width:100%; padding:12px 20px; font-size:15px; text-align: center; }


</style>

