<template>

  <!-- 예약내역 화면 컴포넌트 -->
  <div class="common_layout">

    <!--   상단 뒤로가기   -->
    <div class="depth-content-box">
      <div class="header header-white">
        <img alt="뒤로가기" src="../../assets/icon/back_btn_white.svg" v-on:click="goBack">
        <div class="header-f-white font1-B">{{this.langText.title[this.langKey]}}</div>
      </div>

      <!--   예약 기본정보   -->
      <div class="stay-info-top font1-L" v-show="isLoad">
        <div class="stay-info-box">
          <div class="stay-info-box-title">{{this.langCommonText.reservation_number[this.langKey]}}</div>
          <div class="stay-info-box-value">{{this.$route.query.orderNo}}</div>
        </div>
        <div class="stay-info-top-line"></div>
        <div class="stay-info-box">
          <div class="stay-info-box-title">{{this.langText.reservation_date[this.langKey]}}</div>
          <div class="stay-info-box-value">{{this.resDate}}</div>
        </div>
      </div>
    </div>

    <!--  예약 상세정보  -->
    <div class="stay-info-detail-background" v-show="isLoad">
      <div class="stay-info-detail-contents">

        <!-- OFFER DETAIL -->
        <div class="stay-info-default-box font1-R" v-if="offers">
          <div class="stay-info-default-title">{{this.langText.reservation_detail_info[this.langKey]}}</div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.offer_name[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{offers.name}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.offer_room_type[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{offers.roomType}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.offer_date[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{offers.date}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.offer_room_count[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{offers.roomCount}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.people_count[this.langKey]}}</div>
            <div class="stay-info-lr-default-value" v-html="formattedPeopleCount"></div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.reservation_status[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{offers.state}}</div>
          </div>
        </div>

        <!-- TO GO BOX DETAIL -->
        <div class="stay-info-default-box font1-R" v-if="toGoBox">
          <div class="stay-info-default-title">{{this.langText.reservation_info[this.langKey]}}</div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.offer_name[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{toGoBox.name}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.get_time[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{toGoBox.date}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.reservation_status[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{toGoBox.state}}</div>
          </div>
        </div>

        <!-- DINE & DRINK DETAIL -->
        <div class="stay-info-default-box font1-R" v-if="dineAndDrink">
          <div class="stay-info-default-title">{{this.langText.reservation_info[this.langKey]}}</div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.offer_name[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{dineAndDrink.name}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.hope_visit_day[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{dineAndDrink.date}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.mill[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{dineAndDrink.type}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.reservation_time[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{dineAndDrink.time}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.people_count[this.langKey]}}</div>
            <div class="stay-info-lr-default-value" v-html="formattedPeopleCount"></div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.extra_offer[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{dineAndDrink.req ? dineAndDrink.req : '-'}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.reservation_status[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{dineAndDrink.state}}</div>
          </div>
        </div>

        <!-- OFFER BENEFIT -->
        <div class="stay-info-default-box font1-R" v-if="benefit">
          <div class="stay-info-default-title">{{this.langText.product_benefits[this.langKey]}}</div>
          <div class="stay-info-lr-contents"  v-for="(item, index) in benefit" :key="index">
<!--            <div class="stay-info-lr-bord-title">{{item.KOR_DESC}}</div>-->
            <div class="stay-info-lr-bord-title">{{item.DESC}}</div>
            <div class="stay-info-lr-bord-value">{{item.SVC_CNT_DESC}}</div>
          </div>
        </div>

        <!-- COMMON CANCEL -->
        <div class="stay-info-default-box font1-R" v-if="cancel">
          <div class="stay-info-default-title">{{this.langText.cancel_policy[this.langKey]}}</div>
          <div class="stay-info-cancel-contents">{{cancel}}</div>
        </div>

        <!-- PAYMENT -->
        <div class="stay-info-default-box font1-R" v-if="paymentInfo">
          <div class="stay-info-default-title">{{this.langText.payment_info[this.langKey]}}</div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.payment_type[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{paymentInfo.type}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langText.total_account[this.langKey]}}</div>
            <div class="stay-info-lr-default-value"  v-if="this.langKey === 'KO'">{{String(paymentInfo.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}원</div>
            <div class="stay-info-lr-default-value"  v-else>{{this.langCommonText.currency_unit[this.langKey] + String(paymentInfo.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>


          </div>
        </div>

        <!-- ORDER INFO -->
        <div class="stay-info-default-box font1-R" v-if="orderInfo">
          <div class="stay-info-default-title">{{this.langText.reservation_info[this.langKey]}}</div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langCommonText.name[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{orderInfo.name}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langCommonText.cellphone_number[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{orderInfo.phone}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langCommonText.email[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{orderInfo.mail}}</div>
          </div>
          <div class="stay-info-lr-contents">
            <div class="stay-info-lr-default-title">{{this.langCommonText.order_req[this.langKey]}}</div>
            <div class="stay-info-lr-default-value">{{orderInfo.req ? orderInfo.req : '-'}}</div>
          </div>
        </div>

      </div>
    </div>






    <!--  하단 고객센터 정보  -->
    <div class="stay-history-footer" v-show="isLoad">
      <div class="stay-history-footer-title">{{this.langCommonText.notice[this.langKey]}}</div>
      <ul>
        <li v-html="this.langText.notice_comment[this.langKey]"/>
      </ul>
    </div>



  </div>

</template>

<script>
import languages from '../../modules/languages/stay_history_detail'
import common from '../../modules/languages/common'

export default {
  data() {
    return {
      langText: languages,
      langCommonText: common,
      langKey: this.$store.state.lang,
      resDate: '',
      offers: null,
      toGoBox: null,
      dineAndDrink: null,
      benefit: null,
      cancel: null,
      paymentInfo: null,
      orderInfo: null,
      isLoad: false
    }
  },
  created() {

    this.$emit('c-loadingStart');



    let URL = `${process.env.VUE_APP_API_SERVER}/api/app/`;

    // console.log.log(this.$route.query.tab_idx);

    if (`${this.$route.query.tab_idx}` === `0`) URL += 'offer_detail'
    else if (`${this.$route.query.tab_idx}` === `1`) URL += 'togo_detail';
    else if (`${this.$route.query.tab_idx}` === `2`) URL += 'fnb_detail';

    this.axios({
      method: "GET",
      url: URL,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${this.$store.state.CUST_TOKEN}`
      },
      params: {
        CUST_NO: this.$store.state.CUST_NO,
        GSTNO: this.$route.query.orderNo
      }
    }).then(res => {
      const data = res.data;
      if (data.status === "SUCCESS") {

        // console.log.log(data);

        this.orderInfo = {
          name: `${data.result[0].GSTNAME}`,
          phone: `${data.result[0].MOBILE}`,
          mail: `${data.result[0].EMAIL}`,
          req: data.result[0].REMARK
        }

        this.cancel = `${data.result[0].CANCELTYPE}`;
        this.benefit = data.result[0].PRODUCT_DATA ? data.result[0].PRODUCT_DATA : null;

        if (`${this.$route.query.tab_idx}` === `0`) {      // offer

          let stateString = '';
          if (data.result[0].SYSTUS === 'RES') {
            // stateString = '예약';
            stateString = this.langCommonText.reservation[this.langKey];
          }
          else if (data.result[0].SYSTUS === 'OUT') {
            // stateString = '완료'
            stateString = this.langCommonText.complete[this.langKey];
          }
          else if (['CXL', 'NOSH'].includes(data.result[0].SYSTUS)) {
            // stateString = '취소';
            stateString = this.langCommonText.cancel[this.langKey];
          }
          this.offers = {
            name: data.result[0].SALES_DESC,
            roomType: data.result[0].RM_DESC,
            date: `${this.formatDate(data.result[0].ARRIVDATE)} ~ ${this.formatDate(data.result[0].DEPARTDATE)}`,
            roomCount: data.result[0].RESROOMS,
            people: [
              {type: 'old', count: data.result[0].ADULTGSTS},
              {type: 'student', count: data.result[0].BABYGSTS},
              {type: 'child', count: data.result[0].BABYGSTS2},
              {type: 'baby', count: data.result[0].BABYGSTS3},
            ],
            state: stateString
          }
          this.resDate = this.formatDate(data.result[0].RES_DATE);

        }
        else if (`${this.$route.query.tab_idx}` === `1`) { // togo

          let stateString = '';
          if (data.result[0].STATUS === 'RES') {
            // stateString = '예약';
            stateString = this.langCommonText.reservation[this.langKey];
          }
          else if (data.result[0].STATUS === 'DONE') {
            // stateString = '완료'
            stateString = this.langCommonText.complete[this.langKey];
          }
          else if (['CXL', 'REL', 'WCXL'].includes(data.result[0].STATUS)) {
            // stateString = '취소';
            stateString = this.langCommonText.cancel[this.langKey];
          }
          this.toGoBox = {
            name: data.result[0].PACKAGE_NM,
            date: `${data.result[0].RDATE ? this.formatDate(data.result[0].RDATE): ''} ${data.result[0].EVENTTIME ? data.result[0].EVENTTIME: ''}`,
            state: stateString
          }
          this.resDate = this.formatDate(data.result[0].RDATE);

          this.paymentInfo = {
            type: `${data.result[0].PAY_DIVISION}`,
            price: `${data.result[0].RP_AMOUNT}`
          }

        }
        else if (`${this.$route.query.tab_idx}` === `2`) { // fnb

          let stateString = '';
          if (data.result[0].STATUS === 'RES') {
            // stateString = '예약';
            stateString = this.langCommonText.reservation[this.langKey];
          }
          else if (data.result[0].STATUS === 'DONE') {
            // stateString = '완료'
            stateString = this.langCommonText.complete[this.langKey];
          }
          else if (['CXL', 'REL', 'WCXL'].includes(data.result[0].STATUS)) {
            // stateString = '취소';
            stateString = this.langCommonText.cancel[this.langKey];
          }
          this.dineAndDrink = {
            name: `${data.result[0].DEPT_DESC}`,
            date: this.formatDate(`${data.result[0].RDATE}`),
            type: `${data.result[0].MEAL_STR}`,
            time: data.result[0].MEALTIME1 ? `${data.result[0].MEALTIME1.substring(0, 2)}:${data.result[0].MEALTIME1.substring(2, 4)}` : '-',
            people: [
              {type: 'old', count: data.result[0].RESGSTS},
              {type: 'child', count: data.result[0].RESCHILDGSTS},
              {type: 'baby', count: data.result[0].RESBABYGSTS},
            ],
            req: data.result[0].REMARK,
            state: stateString
          }

          this.resDate = this.formatDate(data.result[0].RDATE);

          this.paymentInfo = {
            type: `${data.result[0].PAY_DIVISION}`,
            price: `${data.result[0].RP_AMOUNT}`
          }

        }

      }
      setTimeout(() => {
        this.isLoad = true;
        this.$emit('c-loadingFinish');
      }, 300);

    }).catch(e => {
      setTimeout(() => {
        this.isLoad = true;
        this.$emit('c-loadingFinish');
      }, 300);
      // console.log(1111);
      // console.log(e);
      if (e.response.status === 402 || e.response.status === 400 || e.response.status === 500 || e.response.state === 403) {
        this.$router.replace('/app/sign_in');
      } else if (e.response.status === 401) {
        // console.log.log("None data");
      }
    });
  },
  methods: {

    /* 이전 화면 이동 */
    goBack() {
      this.$router.go(-1);
    },

    splitHtmlPeopleCount(){
      const target = this.dineAndDrink?.people ? this.dineAndDrink.people : this.offers.people;

      // console.log.log(target);

      let str = '';
      let idx = 0;
      target.map((v) => {
        if (str !== '' && idx%2 === 0) {
          str += `<br />`;
        }
        if (str !== '' && idx%2 !== 0) {
          str += `&nbsp;`
        }
        // TODO: 명 단어 수정
        if(v.type === 'old') {
          str += `${this.langCommonText.old[this.langKey]} ${v.count}명`;
        } else if (v.type === 'child') {
          str += `${this.langCommonText.child[this.langKey]} ${v.count}명`;
        } else if (v.type === 'baby') {
          str += `${this.langCommonText.baby[this.langKey]} ${v.count}명`;
        } else if (v.type === 'student') {
          str += `${this.langCommonText.studen[this.langKey]} ${v.count}명`;
        }
        idx++;
      });

      return str;

    },

    formatPhoneNumber(value) {
      // Check if the value is present and has the expected length
      if (value && value.length === 11) {
        // Use string slicing to format the phone number
        return `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
      }
      // If the value doesn't match the expected format, return the original value
      return value;
    },

    formatDate(inputDate) {

      // return inputDate;
      const year = inputDate.substring(0, 4);
      const month = inputDate.substring(4, 6);
      const day = inputDate.substring(6, 8);

      const date = new Date(`${year}-${month}-${day}`);

      // const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
      const weekdays = this.langCommonText.weekdays[this.langKey];

      const dayOfWeek = date.getDay();

      return `${year}.${month}.${day}(${weekdays[dayOfWeek]})`;
    }

  },
  computed: {
    formattedPeopleCount() {
      return this.splitHtmlPeopleCount();
    },
  }
}
</script>

<style scoped>
  .common_layout{ background-color:#4D4843; }
  .header-f-white{ color:#FFFFFF; }

  .depth-content-box{ width:100%; height:200px; position:relative; }
  .stay-info-top { width: 100%; display: flex; color: #FFF; margin-top:15px; }
  .stay-info-box { width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 14px; }
  .stay-info-box-title { opacity: .7; }
  .stay-info-box-value { margin-top:5px; }
  .stay-info-top-line { width: 1px; height: 40px; background-color: #FFFFFF; transform: rotate(15deg); opacity: .2; }
  .stay-info-detail-background { width: 100%; background-color: #FFF; box-sizing: border-box; padding: 0 16px 100px 16px;  }
  .stay-info-detail-contents { width: 100%; position: relative; top: -60px; }


  .stay-info-default-box { width: 100%; background-color: #FFFFFF; border: 1px #EDEDED solid; border-radius: 16px; box-sizing: border-box; padding: 24px 20px; margin-bottom: 16px; }
  .stay-info-default-title { width: 100%; color: #9C836A; font-size: 16px; }
  .stay-info-lr-contents { width: 100%; display: flex; justify-content: space-between; margin-top: 15px; }
  .stay-info-lr-default-title { font-size: 12px; color: #808080; }
  .stay-info-lr-default-value { max-width: calc(100% - 85px); font-size: 12px; color: #222222; text-align: right; word-break: break-all;  }
  .stay-info-lr-bord-title { font-size: 12px; color: #222222; }
  .stay-info-lr-bord-value { font-size: 12px; color: #222222; }
  .stay-info-cancel-contents { margin-top:15px; font-size: 12px; color: #9C836A; }




  .stay-history-footer{ position:fixed; display:flex; bottom:0; width:100%; flex-direction: column; background-color:#EDEDED; padding: 24px; }
  .stay-history-footer .stay-history-footer-title { font-size: 14px; color: #666666; }
  .stay-history-footer ul { text-align: left; padding-left: 20px; margin: 15px 0 0 0; }
  .stay-history-footer ul li{ font-size: 12px; color: #808080;  }

</style>

